import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import Loader from "../components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import { Container } from "reactstrap";

const AdminRoute = ({ isLoggedInAdmin }) => {
  return isLoggedInAdmin ? (
    <>
      <Suspense
        fallback={
          <div
            className="loader-container"
            style={{ width: "75vw", height: "75vh" }}
          >
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-pulse-rise" />
              </div>
            </div>
          </div>
        }
      >
        <AdminLayout>
          <Container className="pt-4" fluid>
            <Outlet />
          </Container>
          <ToastContainer />
        </AdminLayout>
      </Suspense>
    </>
  ) : (
    <Navigate to="/admin/signin" replace />
  );
};

export default AdminRoute;
