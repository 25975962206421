import React, {useState} from 'react'
import UpdateBtn from '../../../components/Buttons/UpdateBtn';
import { timeTable } from './AvailabilityData';
import DayCheckbox from '../../../components/Buttons/DayCheckbox';
import TimePickerCustom from '../../../components/Buttons/TimePickerCustom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import ButtonComponent from '../../../components/Buttons/ButtonSubmit';
import { Col } from 'reactstrap';


const formatTime = (time) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes}${ampm}`;
};


const MyAvailability = () => {
    const [update, setUpdate] = useState(false);
    const [checkedDays, setCheckedDays] = useState({});
    const [formData, setFormData] = useState({
        timeFrom: '',
        timeTo: '',
    });
    const [timeData, setTimeData] = useState(timeTable);

    const handleCheckboxChange = (day) => {
        setCheckedDays(prev => ({
            ...prev,
            [day]: !prev[day]
        }));
    };

    const handleTimeChange = (day, timeType, value) => {
        setFormData(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                [timeType]: value
            }
        }));
    };

    const handleSaveTimes  = (day) => {
        const newTimeData = timeData.map(item => {
            if (checkedDays[item.day]) {
                const times = formData[item.day];
                if (times && times.timeFrom && times.timeTo) {
                    const formattedTimeFrom = formatTime(times.timeFrom);
                    const formattedTimeTo = formatTime(times.timeTo);
                    return { ...item, workingTime: `${formattedTimeFrom} to ${formattedTimeTo}` };
                }
            }
            return item;
        });

        setTimeData(newTimeData);
        setCheckedDays({});
        setFormData({});
        setUpdate(prev => !prev);
    };

    const handleOpen = () => {
        setUpdate(prev => !prev);
    }

    const handleClose = () => {
        setUpdate(prev => !prev);
    }

  return (
    <div className='Availability'>
        <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
                <p className='mb-0 title'>My Availability</p>
            </div>
            <UpdateBtn onClick={handleOpen} />
        </div>
        <div className='time-table'>
            { update && 
                <div className='sub-text'>
                    <p className=''>Select Day*</p>
                    <ul className='list-unstyled d-flex justify-content-start'>
                        {
                            timeData.map( (item,ind) => {
                                return(
                                    <li key={ind} className='mr-2 overflow-hidden'>
                                        <DayCheckbox
                                            label={item.day.slice(0, 3)}
                                            checked={checkedDays[item.day] || false}
                                            onChange={() => {handleCheckboxChange(item.day)}}
                                            className='checkbox-day'
                                        />
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            }
            <table className="table table-bordered-bottom">
                <thead>
                <tr>
                    <th>Day</th>
                    <th colSpan='3'>Working Time</th>
                </tr>
                </thead>
                <tbody className='table-data'>
                    {timeData.map(item => {
                            const { day, workingTime } = item;
                            const isWorking = workingTime && workingTime !== "Closed";
                            const cellClass = isWorking ? 'green-color' : 'red-color';

                            return (
                                <tr key={day}>
                                    <td className='day'>{day}</td>
                                    <td colSpan='3' className={`${cellClass} time`}>
                                        {update && checkedDays[day] ? (
                                            <div className='form'>
                                                <div className='d-flex justify-space-between'>
                                                    <TimePickerCustom 
                                                        name="timeFrom"
                                                        placeholderText="Select time"
                                                        value={formData[day]?.timeFrom || ''}
                                                        Icon={MdKeyboardArrowDown}
                                                        onChange={(time) => handleTimeChange(day, 'timeFrom', time)}
                                                    />
                                                    <div className='to'>To</div>
                                                    <TimePickerCustom 
                                                        name="timeTo"
                                                        placeholderText="Select time"
                                                        value={formData[day]?.timeTo || ''}
                                                        Icon={MdKeyboardArrowDown}
                                                        onChange={(time) => handleTimeChange(day, 'timeTo', time)}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            workingTime
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {update && 
                <div className='d-flex justify-content-end align-items-center mb-3'>
                    <Col md={3} className='mt-2 d-flex justify-content-center'>
                        <ButtonComponent className='w-100' onClick={handleClose} btnType=''>
                            Cancel
                        </ButtonComponent>
                    </Col>
                    <Col md={3} className='mt-2 d-flex justify-content-center'>
                        <ButtonComponent className='w-100' onClick={handleSaveTimes}>
                            Save
                        </ButtonComponent>
                    </Col>
                </div>
            }
        </div>
    </div>
  )
}

export default MyAvailability