import axios from "axios";
import { getStorage } from "../helpers/apihelper";
import { baseURL } from "../config/filepath";

let axiosInstanceClient = axios.create({
  baseURL,
});

axiosInstanceClient.interceptors.request.use(
  function (config) {
    const token = getStorage("__ct");
    if (token !== null) {
      config.headers["Authorization"] = `Bearer ${atob(token)}`;
      config.headers["lang"] = JSON.parse(localStorage.getItem('lang'))?.code  || "en";
    }
    return config;
  },
  function (err) {
    console.log("Request error: ", err);
    return Promise.reject(err);
  },
);

axiosInstanceClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error.response);
    if (
      error.response &&
      (error.response.status === 403 || error.response.status === 401)
    ) {
      window.location.replace("/client/signin");
    }
    return Promise.reject(error);
  },
);

export default axiosInstanceClient;
