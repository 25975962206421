export const inviteData = [
    {profileImage: '../assets/user-pic-invite.png', Name: 'Sebastian Cedric', RecipientType: 'Normal user', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Accepted'},
    {profileImage: '../assets/user-pic-invite.png', Name: 'Swift Transfer', RecipientType: 'Agency', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Pending'},
    {profileImage: '../assets/user-pic-invite.png', Name: 'Harper Davis', RecipientType: 'Normal user', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Pending'},
    {profileImage: '../assets/user-pic-invite.png', Name: 'Global Remit', RecipientType: 'Agency', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Accepted'},
    {profileImage: '../assets/user-pic-invite.png', Name: 'Christina Alison', RecipientType: 'Agency', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Accepted'},
    {profileImage: '../assets/user-pic-invite.png', Name: 'Sebastian Cedric', RecipientType: 'Normal user', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Accepted'}, 
    {profileImage: '../assets/user-pic-invite.png', Name: 'Sebastian Cedric', RecipientType: 'Normal user', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Accepted'}, 
    {profileImage: '../assets/user-pic-invite.png', Name: 'Sebastian Cedric', RecipientType: 'Normal user', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Accepted'}, 
    {profileImage: '../assets/user-pic-invite.png', Name: 'Sebastian Cedric', RecipientType: 'Normal user', Date: '02-08-24', MobileNumber: '+1-202-555-0173', Email: 'sebastian@gmail.com', status: 'Accepted'}, 

]