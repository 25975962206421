import React from 'react'
import RegisteredUser from './RegisteredUser'
import { useClientType } from '../../../ContextApi/ClientTypeContext'
import AssociateUser from './AssociateUser/AssociateUser';

const SendMoney = () => {
  const {clientType} = useClientType();

  return (
    <div>
        {
          (clientType === 'option2' || clientType === 'option3') ? <AssociateUser /> : <RegisteredUser />
        }
    </div>
  )
}

export default SendMoney