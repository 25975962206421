import React from 'react'

const NextArrow = ({color, className}) => {
  return (
    <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M17 6.5L17.5623 5.93099C17.7144 6.08128 17.8 6.28619 17.8 6.5C17.8 6.71381 17.7144 6.91872 17.5623 7.06901L17 6.5ZM1 7.3C0.558172 7.3 0.200001 6.94183 0.200001 6.5C0.200001 6.05817 0.558172 5.7 1 5.7V7.3ZM11.9971 0.43099L17.5623 5.93099L16.4377 7.06901L10.8724 1.56901L11.9971 0.43099ZM17.5623 7.06901L11.9971 12.569L10.8724 11.431L16.4377 5.93099L17.5623 7.06901ZM17 7.3L1 7.3V5.7L17 5.7V7.3Z" fill={color} />
    </svg>

  )
}

export default NextArrow