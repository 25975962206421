// Recharge.js
import React, {useState} from 'react';
import RechargeBar from './RechargeBar';
import InputField from '../../../components/Buttons/InputField';
import RadioButton from '../../../components/Buttons/RadioButton';
import CardInputNumber from '../../../components/Buttons/CardInputNumber';
import DatePickerMMYY from './DatePickerMMYY';
import { Container, Row, Col } from 'reactstrap';
import ButtonComponent from '../../../components/Buttons/ButtonSubmit';


const Recharge = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [formData, setFormData] = useState({
        ammount: '',
        paymeentMode: '',
        address: '',
        cvv: '',
    })

    const handleRadioChange = (event) => {
        const { value } = event.target;
        setSelectedOption(event.target.id);
        setFormData((prevData) => ({
            ...prevData,
            paymentMode: value,
        }));
    };

    const handleInputChange = (field) => (e) => {
        const { value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleClick = () => {
        /// handle click
    }


    return (
        <Container className='mx-0 px-0'>
            <div className='py-4'>
                <RechargeBar />
                <div className='recharge-form'>
                    <p className='px-0 mb-0 title'>Recharge</p>
                    <div className='form-inputs mt-3'>
                        <InputField
                            name="ammount"
                            type="text"
                            label="Amount"
                            placeholder="Enter Amount"
                            required
                            value={formData.ammount}
                            onChange={handleInputChange('ammount')}
                        />
                    </div>
                    <div className='methods'>
                        <p>Choose Method<span className='text-danger'>*</span></p>
                        <div className='radio-btn-wrap recharge-radio d-flex align-items-center flex-wrap'>
                            <div className='mb-0 d-flex align-items-center flex-wrap'>
                                <div className='mr-2 mr-10 mb-2'>
                                    <RadioButton
                                        id="option1"
                                        name="custom-radio"
                                        checked={selectedOption === 'option1'}
                                        onChange={handleRadioChange}
                                        label="Offline (company agency)"
                                        value="Offline (company agency)"
                                    />
                                </div>
                                <div className='mr-2 mb-2'>
                                    <RadioButton
                                        id="option2"
                                        name="custom-radio"
                                        checked={selectedOption === 'option2'}
                                        onChange={handleRadioChange}
                                        label="Online (credit card)"
                                        value="Online (credit card)"
                                    />
                                </div>
                                <div className='mr-0 mb-2'>
                                    <RadioButton
                                        id="option3"
                                        name="custom-radio"
                                        checked={selectedOption === 'option3'}
                                        onChange={handleRadioChange}
                                        label="Mobile Money"
                                        value="Mobile Money"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='green-box'>
                            <p className='sub-title pb-0'>Card Details</p>
                            <div className=' d-flex justify-content-between'>
                                <div md={7} className='left pl-0'>
                                    <CardInputNumber label="Card Number" isRequired={true} />
                                    <div className='form-inputs mt-3'>
                                        <InputField
                                            name="address"
                                            type="text"
                                            label="Address"
                                            placeholder="Enter Amount"
                                            required
                                            value={formData.address}
                                            onChange={handleInputChange('address')}
                                        />
                                    </div>                                    
                                </div>
                                <div md={5} className='right pr-0'>
                                    <DatePickerMMYY label="Expiry Date" isRequired={true} />
                                    <div className='form-inputs mt-3'>
                                        <InputField
                                            name="cvv"
                                            type="text"
                                            label="Security Code(CVV)"
                                            placeholder="Enter 4 Digit CVV"
                                            required
                                            value={formData.cvv}
                                            onChange={handleInputChange('cvv')}
                                        />
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='buttons'>
                        <div className='buttons d-flex justify-content-end'>
                            <ButtonComponent
                                onClick={handleClick}
                                disabled={false}
                                className = 'orange-border thick-border'
                                btnType = 'border'
                            >
                                Cancel
                            </ButtonComponent>
                            
                            <ButtonComponent
                                onClick={handleClick}
                                disabled={false}
                                className = 'orange-border thick-border'
                                btnType = 'no-border'
                            >
                                Add
                            </ButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Recharge;
