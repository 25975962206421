import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col md={12} className="">
          <p className="copyright-footer d-flex align-items-center">
            {t("copyright")} © {new Date().getFullYear()}.{" "}
            {t("allRightsReserved")} |
            <Link to="/" className="ml-2 mr-2">
              {t("termsAndConditions")}
            </Link>{" "}
            |
            <Link to="/" className="ml-2 mr-2">
              {t("privacyPolicy")}
            </Link>{" "}
            |
            <Link to="/" className="ml-2 mr-2">
              {t("aboutUs")}
            </Link>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
