import "./App.css";
import { lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// ROUTES
import AdminRoute from "./routes/AdminRoutes";
import PublicRoute from "./routes/publicRoutes";

// Normal User UI Components
import Login from "./html/Login/Login";
import ForgotLoginPassword from "./html/Login/ForgotLoginPassword";
import CreateNewPassword from "./html/Login/CreateNewPassword";
import Signup from "./html/Signup/Signup";
import Dashboard from "./html/Dashboard/Dashboard";
import ClientRoute from "./routes/ClientRoutes";
import { getStorage } from "./helpers/apihelper";
import AgencyRoute from "./routes/AgencyRoutes";

// ADMIN PUBLIC
const Signin = lazy(() => import("./features/admin/authentication/Signin"));
const ForgotPassword = lazy(() =>
  import("./features/admin/authentication/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./features/admin/authentication/ResetPassword")
);
// ADMIN PRIVATE
const DashBoard = lazy(() => import("./components/dashboard/dashBoard"));
const Profile = lazy(() => import("./features/admin/authentication/profile"));
const ClientCreate = lazy(() =>
  import("./features/admin/client-management/Client-create-update")
);
const ClientList = lazy(() =>
  import("./features/admin/client-management/Client-List")
);
const PermissionList = lazy(() =>
  import("./features/admin/permission-management/permission-list")
);
const RoleList = lazy(() =>
  import("./features/admin/role_management/RoleList")
);
const AdminList = lazy(() =>
  import("./features/admin/admin-management/Admin-List")
);
const AdminCreate = lazy(() =>
  import("./features/admin/admin-management/Admin-create-update")
);
const Commission = lazy(() =>
  import("./features/admin/commission-management/default-commissionManagement")
);
const AgencyCommission = lazy(() =>
  import("./features/admin/commission-management/agency-commissionManagement")
);
const RechargeList = lazy(() =>
  import("./features/admin/recharge-management/Recharge-List")
);
const GeoLocation = lazy(() =>
  import("./features/admin/geolocation-management/MapView")
);
const TransactionHistory = lazy(() =>
  import("./features/admin/user-management/TransactionHistory")
);
const UserActivity = lazy(() =>
  import("./features/admin/user-management/UserActivity")
);
const ManageUser = lazy(() =>
  import("./features/admin/user-management/ManageUser")
);

const ClientLogin = lazy(() =>
  import("./features/client/authentication/Login/Login")
);
const ClientSignUp = lazy(() =>
  import("./features/client/authentication/Signup/Signup")
);
const ClientForgetPassword = lazy(() =>
  import("./features/client/authentication/Password/ForgotLoginPassword")
);
const ClientRestPassword = lazy(() =>
  import("./features/client/authentication/Password/CreateNewPassword")
);

const ClientDashboard = lazy(() =>
  import("./features/client/Dashboard/Dashboard")
);
const ClientReacharge = lazy(() =>
  import("./features/client/Recharge/RechargeAccount")
);
const ClientProfile = lazy(() => import("./features/client/Profile/Profile"));
const ClientGeoLocation = lazy(() => import("./features/client/GeoLocation"));
const ClientInvite = lazy(() => import("./features/client/Invite/InviteUser"));
const ClientTrasaction = lazy(() =>
  import("./features/client/Transaction/Transaction")
);
const Message = lazy(() => import("./features/client/Message/Message"));

// AGENCY
const AgencyDashborad = lazy(() =>
  import("./features/agency/Dashboard/Dashboard")
);
const AgencyProfile = lazy(() => import("./features/agency/Profile/Profile"));
const AgencyTeller = lazy(() =>
  import("./features/agency/TellerAccount/TellerAccountMain")
);
const AgencyReacharge = lazy(() =>
  import("./features/agency/Recharge/RechargeAccount")
);
// const AgencyMoney = lazy(() => import('./features/agency/Invite/InviteUser'))
const AgencyTransaction = lazy(() =>
  import("./features/agency/Transaction/Transaction")
);

export default function App() {
  const isLoggedInAdmin = getStorage("token") !== undefined ? true : false;
  const isLoggedInClient = getStorage("__ct") !== undefined ? true : false;
  const isLoggedInAgency = getStorage("__at") !== undefined ? true : false;

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoute />}>
            {/* ADMIN PUBLIC ROUTE */}
            <Route
              path="/"
              element={<Navigate to="/client/signin" replace />}
            />
            <Route path="/admin/signin" key="signin" element={<Signin />} />
            <Route
              path="/admin/forgotpassword"
              key={"forgetpassword"}
              element={<ForgotPassword />}
            />
            <Route
              path="/admin/resetpassword/:token"
              key="restpassword"
              element={<ResetPassword />}
            />
            {/* CLIENT PUBLIC ROUTES */}
            <Route
              path="/client/signin"
              key="clientsignin"
              element={<ClientLogin />}
            />
            <Route
              path="/client/signup"
              key="clientsignin1"
              element={<ClientSignUp />}
            />
            <Route
              path="/client/forget-password"
              key="client-forget-password"
              element={<ClientForgetPassword />}
            />
            <Route
              path="/client/reset-password/:token"
              key="client-reset-password"
              element={<ClientRestPassword />}
            />
          </Route>

          {/* ADMIN ROUTES */}
          <Route element={<AdminRoute isLoggedInAdmin={isLoggedInAdmin} />}>
            <Route path="admin">
              <Route
                index
                path="dashboard"
                key="dashboard"
                element={<DashBoard />}
              />
              <Route path="client">
                <Route
                  index
                  path="create"
                  key="client-create"
                  element={<ClientCreate />}
                />
                <Route
                  index
                  path="list"
                  key="client-list"
                  element={<ClientList />}
                />
                <Route
                  index
                  path="edit/:userId"
                  key="client-id"
                  element={<ClientCreate />}
                />
              </Route>
              <Route path="admins">
                <Route
                  index
                  path="create"
                  key="client-create"
                  element={<AdminCreate />}
                />
                <Route
                  index
                  path="list"
                  key="client-list"
                  element={<AdminList />}
                />
                <Route
                  index
                  path="edit/:userId"
                  key="client-id"
                  element={<AdminCreate />}
                />
              </Route>
              <Route
                index
                path="permission-management"
                key="permission-list"
                element={<PermissionList />}
              />
              <Route
                index
                path="role-management"
                key="role-list"
                element={<RoleList />}
              />
              <Route path="profile" element={<Profile />} />
              <Route path="commission">
                <Route
                  path="default-management"
                  element={<Commission />}
                ></Route>
                <Route
                  path="agency-management"
                  element={<AgencyCommission />}
                ></Route>
              </Route>

              <Route
                path="recharge-management"
                element={<RechargeList />}
              ></Route>

              <Route
                path="geolocation-management"
                element={<GeoLocation />}
              ></Route>
              <Route path="user-management">
                <Route path="manage-user" element={<ManageUser />}></Route>
                <Route
                  path="transaction-history"
                  element={<TransactionHistory />}
                ></Route>
                <Route path="user-activity" element={<UserActivity />}></Route>
              </Route>
            </Route>
          </Route>

          {/* NORMAL AND ASSOCIATE ROUTES */}
          <Route element={<ClientRoute isLoggedInClient={isLoggedInClient} />}>
            <Route path="client">
              <Route
                index
                path="dashboard"
                key="client-Dashbaord"
                element={<ClientDashboard />}
              />
              <Route
                index
                path="recharge"
                key="client-Recharge"
                element={<ClientReacharge />}
              />
              <Route
                index
                path="profile"
                key="client-Recharge"
                element={<ClientProfile />}
              />
              <Route
                index
                path="money-transfer"
                key="money-trasnfer"
                element={
                  <ClientTrasaction
                    activeTab={"transfer"}
                    setActiveTab={"transfer"}
                  />
                }
              />
              <Route
                index
                path="transaction-history"
                key="client-history"
                element={
                  <ClientTrasaction
                    activeTab={"history"}
                    setActiveTab={"history"}
                  />
                }
              />
              <Route
                index
                path="geolocation"
                key="client-Geolocation"
                element={<ClientGeoLocation />}
              />
              <Route
                index
                path="invite"
                key="client-Invite"
                element={<ClientInvite />}
              />
              <Route index path="message" key="message" element={<Message />} />
            </Route>
          </Route>

          {/* AGENCY ROUTE */}
          <Route element={<AgencyRoute isLoggedInAgency={isLoggedInAgency} />}>
            <Route path="agency">
              <Route
                index
                path="dashboard"
                key="agency-Dashbaord"
                element={<AgencyDashborad />}
              />
              <Route
                index
                path="profile"
                key="agency-Profile"
                element={<AgencyProfile />}
              />
              <Route
                index
                path="teller"
                key="agency-Teller"
                element={<AgencyTeller />}
              />

              <Route
                index
                path="recharge"
                key="agency-Recharge"
                element={<AgencyReacharge />}
              />
              <Route
                index
                path="money-transfer"
                key="money-trasnfer"
                element={
                  <AgencyTransaction
                    activeTab={"transfer"}
                    setActiveTab={"transfer"}
                  />
                }
              />
              <Route
                index
                path="transaction-history"
                key="client-history"
                element={
                  <AgencyTransaction
                    activeTab={"history"}
                    setActiveTab={"history"}
                  />
                }
              />

              <Route index path="message" key="message" element={<Message />} />
            </Route>
          </Route>

          {/* 404 ROUTE */}
          <Route
            path="*"
            element={<h1 className="text-center mt-5">404 Not Found</h1>}
          />

          {/* These are HTML routes */}
          <Route index path="/html/login/" element={<Login />} />
          <Route
            index
            path="/html/forgot-password"
            element={<ForgotLoginPassword />}
          />
          <Route
            index
            path="/html/create-new-password"
            element={<CreateNewPassword />}
          />
          <Route index path="/html/signup" element={<Signup />} />
          <Route index path="/html/dashboard" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
