import React from 'react'

const ChatIcon = ({color, fill}) => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_264_1202)">
        <circle cx="40" cy="36" r="33" fill={fill} />
        <path d="M35.666 35.5017H35.6781M40.4993 35.5017H40.5114M45.3327 35.5017H45.3448M35.5452 45.1684C37.8514 46.3514 40.5043 46.6718 43.0258 46.0719C45.5474 45.472 47.7718 43.9912 49.2981 41.8964C50.8245 39.8016 51.5525 37.2305 51.3509 34.6464C51.1493 32.0623 50.0314 29.6352 48.1986 27.8024C46.3658 25.9696 43.9387 24.8517 41.3547 24.6501C38.7706 24.4486 36.1995 25.1765 34.1046 26.7029C32.0098 28.2293 30.529 30.4536 29.9291 32.9752C29.3292 35.4967 29.6497 38.1497 30.8327 40.4559L28.416 47.585L35.5452 45.1684Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <filter id="filter0_d_264_1202" x="0" y="0" width="80" height="80" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="3.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_264_1202"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_264_1202" result="shape"/>
        </filter>
        </defs>
    </svg>

  )
}

export default ChatIcon