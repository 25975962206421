import React, {useState} from 'react';
import ButtonStatus from '../ButtonStatus';
import Pagination from '../../Dashboard/Recharge/Pagination';
import { CiSearch } from 'react-icons/ci';
import { Col, Row } from 'reactstrap';
import DropdownCustom from '../../../components/Buttons/DropdownCustom';
import { inviteData } from './InviteUserTableData';
import ButtonComponent from '../../../components/Buttons/ButtonSubmit';
import InputField from '../../../components/Buttons/InputField';


const InviteUser = () => {
  const [search, setSearch] = useState();
  const [data, setData] = useState(inviteData);
  const [optionData, setOptionData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    mobile: '',
  })

  const handleChange = (e) => {
    setSearch(e.target.value);
  }

  const handleInputChange = (field) => (e) => {
      const { value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [field]: value,
      }));
  };

  const handleClick = () => {
    /// handle click
  }

  const options = ['Latest', 'Low to High', 'High to Low'];
  const filterOption = ['Status-all', 'Status'];

  const handleSelect = (e) => {
      setOptionData(e.target.value)
  };

  const itemsPerPage = 6;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


  
  return (
    <div className="container mt-4 px-0 dashboard-table invite-table">
      <Row className='mx-0 mr-0 ml-0 mb-3'>
          <Col md={12} className='form-bar'>
            <p className='mb-0 title'>Send Invitation</p>
            <Row>
              <Col md={5}>
                <div className='form-inputs mt-3'>
                  <InputField
                      name="ammount"
                      type="email"
                      label="User Email"
                      placeholder="Enter Email"
                      required
                      value={formData.email}
                      onChange={handleInputChange('email')}
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className='form-inputs mt-3'>
                  <InputField
                      name="ammount"
                      type="number"
                      label="User mobile*"
                      placeholder="Enter mobile"
                      required
                      value={formData.mobile}
                      onChange={handleInputChange('mobile')}
                  />
                </div>
              </Col>
              <Col md={2} className='px-0 d-flex align-items-end justify-content-end'> 
                <ButtonComponent
                      onClick={handleClick}
                      disabled={false}
                      className = 'orange-border invite-btn'
                      btnType = 'no-border'
                >
                    Send Invitation
                    <i className="ml-2 fa fa-regular fa-angle-right"></i>
                </ButtonComponent>
              </Col>
            </Row>
          </Col>
          <Col md={6} className='mt-3 px-0'>
              <div className='search-group d-flex align-items-center'>
                  <CiSearch />
                  <input className='p-2' placeholder='Search' type='text' name='search' value={search} onChange={handleChange}/>
              </div>
          </Col>
          <Col md={3} className='px-0 d-flex justify-content-end'>
                    <div className='sort d-flex align-items-center'>
                        <img src='../assets/sort.svg' />
                        <label htmlFor='sort' className='mb-0 pl-2'>Sort By:</label>
                        <DropdownCustom 
                            className='dashboard-drop dark-text pl-2'
                            name="sort"
                            title=""
                            isRequired = {false}
                            options={options}
                            onSelect={handleSelect}
                            defaultOption='Latest'
                            value={optionData}
                        />
                    </div>
          </Col>
          <Col md={3} className='px-0 d-flex justify-content-end'>
              <div className='sort filter d-flex align-items-center'>
                  <img src='../assets/filter.svg' />
                  <label htmlFor='sort' className='mb-0 pl-2'>Filter By:</label>
                  <DropdownCustom 
                      className='dashboard-drop dark-text pl-2'
                      name="sort"
                      title=""
                      isRequired = {false}
                      options={filterOption}
                      onSelect={handleSelect}
                      defaultOption='Status-all'
                      value={optionData}
                  />
              </div>
          </Col>
      </Row>
      <table className="table table-bordered-bottom">
        <thead>
          <tr>
            <th>Name</th>
            <th>Recipient Type</th>
            <th>Date</th>
            <th>Mobile Number</th>
            <th>Email</th>
            <th className='text-center'>Status</th>
          </tr>
        </thead>
        <tbody className='table-data'>
          {
            currentItems.map( (Item, index) => {
              const {profileImage, Name, RecipientType, Date, MobileNumber, Email, status} = Item;
              return(
                <tr key={index}>
                    <td className=''><img src={profileImage} alt='' className='mr-2 pic' />{Name}</td>
                    <td className=''>{RecipientType}</td>
                    <td className=''>{Date}</td>
                    <td className=''>{MobileNumber}</td>
                    <td className='orange'>{Email}</td>
                    <td className='text-center' align='left'>
                      {
                        status === 'Accepted' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Accepted'
                            className=''
                            status='Accepted'
                          />
                        ) : status === 'Pending' ? (
                            <ButtonStatus
                              img='../assets/circle-red.svg'
                              content='Pending'
                              className=''
                              status='Pending'
                            />
                          ) : null
                      }
                    </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className='pagination'>
        {
          totalPages > 1 && <Pagination 
              totalPages={totalPages} 
              currentPage={currentPage} 
              onPageChange={handlePageChange} 
          />
        }
      </div>
    </div>
  );
};

export default InviteUser;
