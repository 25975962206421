import React, { useEffect, useState } from "react";
import { LuWallet2 } from "react-icons/lu";
import { GoBell } from "react-icons/go";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { clearLocalStorage, clearSessionStorageAll } from "../../../helpers/apihelper";
import { useDispatch, useSelector } from "react-redux";
import { useClientType } from "../../../ContextApi/ClientTypeContext";
import { GetAgencyDashboard, GetDashboard, GetNotification } from "../../../redux/actions/client/dashboard";
import { getFormattedDate } from "../../../helpers/utils";
import { useTranslation } from 'react-i18next';
import { lngs } from "../../../i18n/i18n";
import { BsChevronDown } from "react-icons/bs";

const TopBar = ({ title, ...args }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { clientType } = useClientType();
  const [isNotification, setIsNotification] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useDispatch();
  const handleLogout = () => {
    // persistStore.purge();
    navigate("/client/signin");
    clearSessionStorageAll();
    clearLocalStorage('persist:root')
  };

  const [selectedLang, setSelectedLang] = useState(JSON.parse(localStorage.getItem('lang')) || 'en');
  const [isOpen, setIsOpen] = useState(false);


  const handleChange = (lang) => {
   const div =  document.getElementsByTagName('html')[0];
   if(lang.code === 'ar'){
    //  div.setAttribute('dir', 'rtl')
   }
   console.log()
    setSelectedLang(lang);
    i18n.changeLanguage(lang.code);
    localStorage.setItem('lang', JSON.stringify(lang));
    setIsOpen(false);

  };

  const loadLanguage = () => {
    const savedLang = selectedLang || 'en';
    i18n.changeLanguage(savedLang.code);
  };

  useEffect(() => {
    if (clientType !== "agency") {
      dispatch(GetDashboard("past-12"));
    } else {
      dispatch(GetAgencyDashboard("past-12"));
    }
    dispatch(GetNotification(clientType))
    loadLanguage()
  }, []);
  const rows = useSelector((state) => state?.dashboard?.notification)

  // console.log(rows)

  const data = useSelector((state) => state?.dashboard?.dashboard?.data?.user);
  const {t} = useTranslation();
  return (
    <div className="topbar d-flex justify-content-between align-items-center pt-4">
      <p className="left mb-0">{title}</p>
      <div className="right d-flex justify-content-end align-items-center">
        <div className="relative inline-block text-left mx-3">
          {/* Dropdown Button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-4 py-2 border rounded-lg shadow-md bg-white hover:bg-gray-100"
          >
            <span className="mr-2">{selectedLang.flag}</span>
            <span>{selectedLang.native}</span>
            <BsChevronDown className="ml-2 w-4 h-4" />
          </button>

          {/* Dropdown Menu */}
          {isOpen && (
            <ul className="position-absolute custom_dropdown mt-2 w-44 bg-white border rounded-lg shadow-lg p-0 z-50">
              {lngs.map((lang) => (
                <li
                  key={lang.code}
                  className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-left list-style-none"
                  onClick={() => handleChange(lang)}
                >
                  <span className="mr-2">{lang.flag}</span>
                  <span>{lang.native}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="wallet">
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <LuWallet2 />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" style={{ width: '400px' }} end>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Notifications</h6>
                </DropdownItem>

                {
                  rows && rows?.length > 0 ? (
                    rows?.rows?.map((items) => {
                      if (items.isDeleted === '0' && items.type === "SYSTEM") {
                        return (
                          <>
                            <DropdownItem key={items.id}>
                              <div className="d-flex align-items-center">
                                <div>
                                  <h3 className="m-0 text-primary">{items?.title}</h3>
                                  <small className="mb-0">{items?.message}</small>
                                  <small className="text-muted">{getFormattedDate(items?.updatedAt)}</small>
                                </div>
                              </div>
                            </DropdownItem>
                            <DropdownItem divider />
                          </>
                        );
                      }
                      return null; // Return null if conditions aren't met.
                    })
                  ) : (
                    <h3 className="text-center text-primary">No Transaction Record!</h3>
                  )
                }


                {/* <DropdownItem className="text-center text-primary" onClick={() => navigate("/notifications")}>
                  View All Notifications
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </div>
        <div className={`${isNotification ? "notified" : "no-notified"} ml-3`}>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <GoBell />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" style={{ height: '400px', overflow: 'auto' }} end>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Notifications</h6>
                </DropdownItem>
                {
                  rows?.rows && rows?.rows?.length > 0 ? (
                    rows?.rows?.map((items) => {
                      if (items.isDeleted === '0' && items.type === "WALLET") {
                        return (
                          <>
                            <DropdownItem key={items.id} onClick={(e) => { e.preventDefault() }}>
                              <div className="d-flex align-items-center">
                                <div>
                                  <div className="d-flex justify-content-between">
                                    <h3 className="m-0 text-primary">{items?.title}</h3>
                                    {/* <LuView /> */}
                                  </div>
                                  <small className="mb-0">{items?.message}</small>
                                  <small className="text-muted">recived on {getFormattedDate(items?.updatedAt)}</small>
                                </div>
                              </div>
                            </DropdownItem>
                            <DropdownItem divider />
                          </>
                        );
                      }
                      return null; // Return null if conditions aren't met.
                    })
                  ) : (
                    <h3 className="text-center text-primary">No Transaction Record!</h3>
                  )
                }

                {/* <DropdownItem className="text-center text-primary" onClick={() => navigate("/notifications")}>
                  View All Notifications
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>

        </div>

        <div className="user d-flex align-items-center">
          <Dropdown
            direction="down"
            style={{ boxShadow: "none" }}
            isOpen={dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle
              style={{
                boxShadow: "none",
                border: "none",
                background: "transparent",
              }}
              className="d-flex align-items-baseline"
              caret
            >
              {" "}
              <div className="user-pic ">
                <img src={data?.profileImage} alt="profileImage" />
              </div>{" "}
              <p className="mb-0 pl-2">
                {data?.firstName === undefined ? data?.name : data?.firstName}{" "}
              </p>
            </DropdownToggle>
            <DropdownMenu {...args}>
              <DropdownItem
                onClick={() =>
                  navigate(
                    clientType === "agency"
                      ? "/agency/profile"
                      : "/client/profile"
                  )
                }
              >
                {t('profile')}
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}> {t('logout')}</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
