import React from 'react'

const DeleteIcon = ({color, className}) => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M0.998047 4.55469H16.998" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.2198 4.55469V16.9991C15.2198 17.888 14.3309 18.7769 13.4421 18.7769H4.55317C3.66428 18.7769 2.77539 17.888 2.77539 16.9991V4.55469" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.44336 4.55556V2.77778C5.44336 1.88889 6.33225 1 7.22114 1H10.7767C11.6656 1 12.5545 1.88889 12.5545 2.77778V4.55556" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default DeleteIcon