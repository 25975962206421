import React, {useState} from 'react'
import { Col, Container, Row } from 'reactstrap'
import RadioButton from '../../../components/Buttons/RadioButton'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import ProgressBar from '../../../components/Progressbar/ProgressBar';
import Popup from '../../../components/Popup/Popup'
import RegisteredUserForm from './RegisteredUserForm'
import UnregisteredForm from './UnregisteredForm'
import { IoCloseOutline, IoCheckmark } from 'react-icons/io5';
import { RiUserSearchLine } from "react-icons/ri";
import { GrTransaction } from "react-icons/gr";
import PreviewFocus from '../../../components/svg/PreviewFocus';
import BookPerson from '../../../components/svg/BookPerson';



const RegisteredUser = () => {
    const [selectedOption, setSelectedOption] = useState('option1');
    const [step, setStep] = useState(1);  
    const [isOpen, setIsOpen] = useState(false);
    const [activeComponent, setActiveComponent] = useState('Registered');
    const [formData, setFormData] = useState({
        clientType: '',
        checked: '',
        option: '',
    })
  
    const handleRegistered = (event) => {
        const value = event.target.value;
        setSelectedOption(event.target.id);
        setFormData(prevData => ({ ...prevData, clientType: value }));
        setActiveComponent('Registered');
    };

    const handleUnregistered = (event) => {
        const value = event.target.value;
        setSelectedOption(event.target.id);
        setFormData(prevData => ({ ...prevData, clientType: value }));
        setActiveComponent('Unregistered');
    };

    const handleClose = () => {
        setIsOpen(!isOpen);
    }


  return (
    <Container fluid className='normal-user signup-user register px-0 mx-0'>
        <Row className='row px-0 mx-0'>
            <Col md={12} className='mt-0'>
            {
                activeComponent === 'Registered' ? (
                    <ProgressBar 
                        currentStep={step}
                        firstText='1. Recipient Details'
                        secondText='2. Transaction Details'
                        thirdText='3. Preview'
                        stepOneIcon={<RiUserSearchLine />}
                        stepTwoIcon={<GrTransaction />}
                        stepThreeIcon={step < 3  ? <PreviewFocus color='#393939' /> : <PreviewFocus color='#fff' />}
                        stepOne={step > 1 ? true : false}
                        stepTwo={step > 2 ? true : false}
                        stepThree={step > 3 ? true : false}
                        className='moneytransfer-progress'
                    />
                ) : activeComponent === 'Unregistered' ? (
                    <ProgressBar 
                        currentStep={step}
                        firstText='1. Recipient Details'
                        secondText='2. Agent Details'
                        thirdText='3. Transaction Details'
                        FourthText='4. Preview'
                        stepOneIcon={<RiUserSearchLine />}
                        stepTwoIcon={step < 2  ? <BookPerson color='#393939' /> : <BookPerson color='#fff' />}
                        stepThreeIcon={step < 3  ? <GrTransaction color='#393939' /> : <GrTransaction color='#fff' />}
                        stepFourIcon={step < 4  ? <PreviewFocus color='#393939' /> : <PreviewFocus color='#fff' />}
                        stepOne={step > 1 ? true : false}
                        stepTwo={step > 2 ? true : false}
                        stepThree={step > 3 ? true : false}
                        stepFour={step > 4 ? true : false}
                        className='moneytransfer-progress'
                    />
                ) : null
            }
            </Col>
            <Col md={12} className='d-flex justify-content-start register-wrap'>
                <div className='m-0 left-inner w-100'>
                <Row className='row px-0 mx-0'>
                    {
                        activeComponent === 'Unregistered' && step === 2 ? 
                            ( <Col md={12} className='recipient-details px-0'><p className='mb-2'>Agent Details</p></Col> ) 
                            : activeComponent === 'Unregistered' && step === 3 ? (
                                <Col md={12} className='recipient-details px-0'><p className='mb-0'>Transaction Details</p></Col>
                            ) : step < 3 ? ( <Col md={12} className='recipient-details px-0'>
                            <p className='mb-0'>Recipient Details</p>
                        </Col>) : null
                    }
                    {
                        step === 1 && (
                        <>
                            <Col md={12} className='mx-0 mt-2 px-0'>
                                <div className='radio-btn-wrap'>
                                    <div className='mr-18 mb-0 d-flex align-items-center'>
                                        <p className='mb-2'>Recipient Type<span className='text-danger'>*</span></p>
                                    </div>
                                    <div className='mb-0 d-flex align-items-center flex-wrap mt-2'>
                                        <div className='mr-2 mr-10 mb-2'>
                                            <RadioButton
                                                id="option1"
                                                name="clientType"
                                                checked={selectedOption === 'option1'}
                                                onChange={handleRegistered}
                                                label="Registered"
                                                value="option1"
                                            />
                                        </div>
                                        <div className='mr-10 mb-2'>
                                            <RadioButton
                                                id="option2"
                                                name="clientType"
                                                checked={selectedOption === 'option2'}
                                                onChange={handleUnregistered}
                                                label="Unregistered"
                                                value="option2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </>
                    )}   
                    { activeComponent === 'Registered' && <RegisteredUserForm  step={step} setStep={setStep} isOpen={isOpen} setIsOpen={setIsOpen}  /> }
                    { activeComponent === 'Unregistered' && <UnregisteredForm step={step} setStep={setStep} isOpen={isOpen} setIsOpen={setIsOpen} /> }
                    {/* {renderContent()} */}
                </Row>  
                </div>
            </Col>
        </Row>
        { isOpen && 
            <Row className='row mx-0 popup-container d-flex justify-content-center align-items-center px-3 px-md-0'>
                <Col md={12} className='popupWrapper'>
                    <Popup onClick={handleClose} className='justify-content-start mt-popup'>
                        <div className='close'>
                            <IoCloseOutline onClick={handleClose} />
                        </div>
                        <div className='icon d-flex justify-content-center align-items-center p-2'>
                            <img src='../assets/check-mark.svg' alt='' />
                        </div>
                        <h2 className='mt-2'>Payment Successful !</h2>
                        <p className='light-text'>Thank you for your payment. An automated receipt will be sent to your registered email.</p>
                        <div className='d-flex justify-content-center btn-wrap'>
                            <ButtonComponent className='mt-2 btn-mt' onClick>
                                Continue
                            </ButtonComponent>
                        </div>
                    </Popup>
                </Col>
            </Row>
        }
    </Container>
  )
}

export default RegisteredUser