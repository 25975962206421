import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LuWallet2 } from "react-icons/lu";
import { GoBell } from "react-icons/go";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import team4 from "../../assets/img/theme/team-4-800x800.jpg";
import { deleteStorage, getStorage } from "../../helpers/apihelper";

const AdminNavbar = ({ name }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const adminname = JSON.parse(getStorage("admindetails"))?.username || "";
  const signOut = async () => {
    await deleteStorage();
    navigate("/admin/signin");
  };

  // Path to title mapping
  const pathToTitle = {
    "/admin/dashboard": "Dashboard",
    "/admin/client/list": "Client Management",
    "/admin/client/create": "Client Management",
    "/admin/admins/list": "Admin Management",
    "/admin/admins/create": "Admin Management",
    "/admin/permission-management": "Permission Management",
    "/admin/role-management": "Role Management",
    "/admin/commission/default-management": "Commission Management",
    "/admin/commission/agency-management": "Agency Management",
    "/admin/recharge-management": "Recharge Management",
    "/admin/geolocation-management": "Geolocation Management",
  };

  const activeTitle = pathToTitle[location.pathname] || "";

  return (
    <>
      <Navbar
        className="navbar-top navbar-light position-sticky"
        id="navbar-main"
      >
        <Container fluid>
          <span className="page-title">{activeTitle}</span>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <div className="d-flex align-items-center">
            <div className="d-flex mr-3">
              <div className="wallet">
                <LuWallet2 />
                
              </div>
              <div className="notified ml-3">
                <GoBell />
              </div>
            </div>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={team4} />
                    </span>
                    <Media className="ml- 2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {adminname}
                      </span>
                      <p className="mb-0 pl-1">Admin</p>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" end>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/admin/profile")}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem divider />

                  <DropdownItem onClick={signOut}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
