import React from 'react'
import { IoCheckmark } from "react-icons/io5";


const ProgressBar = ({
    currentStep, 
    firstText, 
    secondText, 
    thirdText, 
    FourthText, 
    FiveText, 
    stepOne, 
    stepTwo, 
    stepThree, 
    stepFour, 
    stepFive,
    stepOneIcon,
    stepTwoIcon,
    stepThreeIcon,
    stepFourIcon,
    stepFiveIcon,
    className
}) => {
    const steps = [];
    if (firstText) {
        steps.push({ text: firstText});
    }
    if (secondText) {
        steps.push({ text: secondText});
    }
    if (thirdText) {
        steps.push({ text: thirdText});
    }
    if (FourthText) {
        steps.push({ text: FourthText});
    }
    if (FiveText) {
        steps.push({ text: FiveText});
    }

    const totalSteps = steps.length - 1;
    const incrementPercentage = 100 / totalSteps;
    let progressPercentage;

    if (currentStep === totalSteps) {
        progressPercentage = incrementPercentage * totalSteps;
    }
    else if(currentStep < totalSteps){
        progressPercentage = currentStep * incrementPercentage; 
    }

  return (
    <div className={`${className} progress-container d-block align-items-center justify-content-center gap-3 g-3`}>
        <div className='step-wrapper p-0'>
            <ul className='d-flex justify-content-center'>
                { firstText && <li className={`${currentStep === 1 || currentStep > 1  ? 'active' : ''}`}>
                    <div className='icon'><span>{ stepOne ? <IoCheckmark /> : stepOneIcon}</span></div>
                    <div className='text mt-3'>{firstText}</div>
                </li>}
                { secondText && <li className={`${currentStep === 2 || currentStep >= 2 ? 'active' : ''}`}>
                    <div className='icon'><span>{ stepTwo ? <IoCheckmark />  : stepTwoIcon}</span></div>
                    <div className='text mt-3'>{secondText}</div>
                </li>}
                { thirdText && <li className={`${currentStep === 3 || currentStep >= 3 ? 'active' : ''}`}>
                    <div className='icon'><span>{ stepThree ? <IoCheckmark /> : stepThreeIcon}</span></div>
                    <div className='text mt-3'>{thirdText}</div>
                </li>}
                { FourthText && <li className={`${currentStep === 4 || currentStep >= 4 ? 'active' : ''}`}>
                    <div className='icon'><span>{ stepFour ? <IoCheckmark />  : stepFourIcon}</span></div>
                    <div className='text mt-3'>{FourthText}</div>
                </li>}
                { FiveText && <li className={`${currentStep === 5 || currentStep >= 5 ? 'active' : ''}`}>
                    <div className='icon'><span>{ stepFive ? <IoCheckmark />  : stepFiveIcon}</span></div>
                    <div className='text mt-3'>{FiveText}</div>
                </li>}
            </ul>
        </div>
    </div>
  )
}

export default ProgressBar