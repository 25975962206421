import React, {useState} from 'react'
import { Col, Row } from 'reactstrap'
import InputField from '../../../components/Buttons/InputField'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
// import { CiSearch } from 'react-icons/ci';
import DropdownCustom from '../../../components/Buttons/DropdownCustom';
import NumberDropdown from '../../../components/Buttons/NumberDropdown';
import RadioButton from '../../../components/Buttons/RadioButton';

const UnregisteredForm = ({step, setStep, isOpen, setIsOpen}) => {
    const [selectedOption, setSelectedOption] = useState('option1');
    const [formData, setFormData] = useState({
      fullname: '',
      currency: '',
      amount: '', 
      reason: '',
      gender: '',
      mobile: '',
      email: '',
      address: '',
      city: '',
      country: '',
      countryCode: '+1',
      zip: '',
      clientType: '',
    })

    const currency = ['INR', 'USD', 'ASD', 'YEN'];
    const gender = ['Male', 'Female', 'Other'];
    const city = ['Delhi', 'USA', 'Mumbai'];
    const country = ['India', 'USA', 'UAE'];

    const handleNumberInput = (code) => {
      setFormData((prevData) => ({
        ...prevData,
        countryCode: code,
      }));
    };
    
    const handleFormChange = (e) => {
      const { name, value } = e.target;
      console.log(`Changing ${name} to ${value}`);
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
    };

    const handleSelect = (name, value) => {
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
    };

    const nextStep = () => {
        setStep(step + 1);
    }
    const prevStep = () => {
        if(step === 0 || step === 1){
            return setStep(1);
        }
        setStep(step - 1);
        console.log(step);
    }

    const handleSubmit = () => {
        setIsOpen(prevState => !prevState);
    }

    const handleAgent = (event) => {
      const value = event.target.value;
      setSelectedOption(event.target.id);
      setFormData(prevData => ({ ...prevData, clientType: value }));
      // setActiveComponent('Registered');
    };

    const renderContent = () => {
        // eslint-disable-next-line no-lone-blocks
        {
            switch(step){
                case 1:
                return(
                    <>
                        <Col md={12} className='form-inputs mt-2'>
                          <Row>
                            <Col md={6}>
                              <InputField
                                  name="fullname"
                                  type="text"
                                  label="Name"
                                  placeholder="Enter name"
                                  required
                                  value={formData.fullname}
                                  onChange={handleFormChange}
                              />
                            </Col>
                            <Col md={6}>
                              <DropdownCustom 
                                  name="gender"
                                  title="Gender"
                                  options={gender}
                                  onSelect={handleSelect}
                                  defaultOption='Choose Gender'
                                  value={formData.gender}
                              />
                            </Col>
                            <Col md={6} className='mt-3'>
                              <NumberDropdown
                                  name="mobile"
                                  title='Mobile Number'
                                  options={['+91', '+92', '+1']}
                                  onSelect={handleNumberInput}
                                  defaultOption={formData.countryCode}
                                  type='number'
                                  placeholder='Enter Mobile Number'
                                  required={true}
                                  onChange={handleFormChange}
                                  value={formData.mobile}
                              />
                            </Col>
                            <Col md={6} className='mt-3'>
                              <InputField
                                  name="email"
                                  type="email"
                                  label="Email"
                                  placeholder="Enter Email Address"
                                  required
                                  value={formData.email}
                                  onChange={handleFormChange}
                              />
                            </Col>
                            <Col md={12} className='mt-3'>
                              <InputField
                                    name="address"
                                    type="text"
                                    label="Address"
                                    placeholder="Enter Address"
                                    required
                                    value={formData.address}
                                    onChange={handleFormChange}
                                />
                            </Col>
                            <Col md={4} className='mt-4'> 
                              <DropdownCustom 
                                name="city"
                                title="City"
                                options={city}
                                onSelect={handleSelect}
                                defaultOption='Select City'
                                value={formData.city}
                              />
                            </Col>
                            <Col md={4} className='mt-4'> 
                              <DropdownCustom 
                                name="country"
                                title="Country"
                                options={country}
                                onSelect={handleSelect}
                                defaultOption='Select Country'
                                value={formData.country}
                              />
                            </Col>
                            <Col md={4} className='mt-4'>
                              <InputField
                                  name="zip"
                                  type="text"
                                  label="Zip Code"
                                  placeholder="Enter Zip Code"
                                  required
                                  value={formData.zip}
                                  onChange={handleFormChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <div className='d-flex justify-content-end w-100 mt-4'>
                            <Col md={5} className='d-flex'>
                                <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                    Cancel
                                </ButtonComponent>
                                <ButtonComponent className='w-100' onClick={nextStep}>
                                    Next
                                    <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                </ButtonComponent>
                            </Col>
                        </div>
                    </>
                )
                case 2:
                return(
                    <>
                        <Col md={12} className='px-0'>
                          <div className='radio-btn-wrap'>
                              <div className='mr-18 mb-0 d-flex align-items-center'>
                                  <p className='mb-1'>Agent type<span className='text-danger'>*</span></p>
                              </div>
                              <div className='mb-0 d-flex align-items-center flex-wrap mt-2'>
                                  <div className='mr-2 mr-10 mb-2'>
                                      <RadioButton
                                          id="option1"
                                          name="clientType"
                                          checked={selectedOption === 'option1'}
                                          onChange={handleAgent}
                                          label="Agency"
                                          value="option1"
                                      />
                                  </div>
                                  <div className='mr-10 mb-2'>
                                      <RadioButton
                                          id="option2"
                                          name="clientType"
                                          checked={selectedOption === 'option2'}
                                          onChange={handleAgent}
                                          label="Associate Client"
                                          value="option2"
                                      />
                                  </div>
                              </div>
                          </div>
                        </Col>
                        <Col md={12} className='form-inputs mt-2'>
                          <Row>
                            <Col md={12} className='mt-1'>
                              <InputField
                                    name="address"
                                    type="text"
                                    label="Address"
                                    placeholder="Enter Address"
                                    required
                                    value={formData.address}
                                    onChange={handleFormChange}
                                />
                            </Col>
                            <Col md={4} className='mt-4'> 
                              <DropdownCustom 
                                name="city"
                                title="City"
                                options={city}
                                onSelect={handleSelect}
                                defaultOption='Select City'
                                value={formData.city}
                              />
                            </Col>
                            <Col md={4} className='mt-4'> 
                              <DropdownCustom 
                                name="country"
                                title="Country"
                                options={country}
                                onSelect={handleSelect}
                                defaultOption='Select Country'
                                value={formData.country}
                              />
                            </Col>
                            <Col md={4} className='mt-4'>
                              <InputField
                                  name="zip"
                                  type="text"
                                  label="Zip Code"
                                  placeholder="Enter Zip Code"
                                  required
                                  value={formData.zip}
                                  onChange={handleFormChange}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <div className='d-flex justify-content-end w-100 mt-4'>
                            <Col md={5} className='d-flex'>
                                <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                  <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                    Back
                                </ButtonComponent>
                                <ButtonComponent className='w-100' onClick={nextStep}>
                                    Next
                                    <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                </ButtonComponent>
                            </Col>
                        </div>
                    </>
                )
                case 3:
                return(
                    <>
                      <Col md={12} className='form-inputs mt-0'>
                        <Row>
                        <Col md={6} className='mt-3'> 
                            <DropdownCustom 
                              name="currency"
                              title="Currency"
                              options={currency}
                              onSelect={handleSelect}
                              defaultOption='Select Currency'
                              value={formData.currency}
                            />
                          </Col>
                          <Col md={6} className='mt-3'>
                            <InputField
                                name="amount"
                                type="text"
                                label="Amount"
                                placeholder="Enter Amount"
                                required
                                value={formData.amount}
                                onChange={handleFormChange}
                            />
                          </Col>
                          <Col md={12} className='mt-4'>
                            <InputField
                                name="reason"
                                type="text"
                                label="Reason for Sending"
                                placeholder="Please describe your reason"
                                required
                                value={formData.reason}
                                onChange={handleFormChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <div className='d-flex justify-content-end w-100 mt-4'>
                          <Col md={5} className='d-flex'>
                              <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                  Back
                              </ButtonComponent>
                              <ButtonComponent className='w-100' onClick={nextStep}>
                                  Next
                                  <i className="ml-3 fa fa-regular fa-angle-right"></i>
                              </ButtonComponent>
                          </Col>
                      </div>
                    </>
                )
                case 4:
                return(
                  <>
                    <Col md={12} className='form-inputs preview-wrapper mt-2'>
                        <div className='d-block'>
                            <p className='title-top'>Preview (recipient details)</p>
                            <div className='section d-flex'>
                                <div className='item'>
                                    <p className='title mb-0'>Recipient type</p>
                                    <p className='description mb-0 colored'>Registered</p>
                                </div>
                            </div>
                            <div className='section d-flex'>
                              <div className='item'>
                                  <p className='title mb-0'>Recipient name</p>
                                  <p className='description text mb-0'>Samuel Bennett</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Gender</p>
                                <p className='description text mb-0'>Male</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Phone</p>
                                <p className='description text mb-0'>+1 702-414-1000</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Email</p>
                                <p className='mail-id mb-0'>samuel@gmail.com</p>
                              </div>
                              <div className='item'>
                                  <p className='title mb-0'>Amount</p>
                                  <p className='description text mb-0'>$100.00</p>
                              </div>
                              <div className='item'>
                                  <p className='title mb-0'>Reason for sending</p>
                                  <p className='description text mb-0'>
                                    400 Lavaca St, Austin, TX 78701, United States
                                  </p>
                              </div>
                            </div>

                            <p className='title-top mb-0 mt-3'>Cash Pickup Details</p>
                            <div className='section d-flex'>
                              <div className='item'>
                                  <p className='title mb-0'>Agent city</p>
                                  <p className='description text mb-0'>Austin</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Agent country</p>
                                <p className='description text mb-0'>United States</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Zip Code</p>
                                <p className='description text mb-0'>TX 78701</p>
                              </div>
                            </div>
                            <p className='title-top mb-0 mt-3'>Money Details</p>
                            <div className='section d-flex'>
                              <div className='item'>
                                  <p className='title mb-0'>Amount</p>
                                  <p className='description text mb-0'>$100.00</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Reason for Sending</p>
                                <p className='description text mb-0'>Reimbursement for travel expenses incurred during the conference.</p>
                              </div>
                            </div>
                        </div>
                    </Col>
                    <div className='d-flex justify-content-end w-100 mt-5'>
                        <Col md={5} className='d-flex'>
                            <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                Back
                            </ButtonComponent>
                            <ButtonComponent className='w-100' onClick={handleSubmit}>
                                Send Money
                                <i className="ml-3 fa fa-regular fa-angle-right"></i>
                            </ButtonComponent>
                        </Col>
                    </div>
                  </>
                )
                default:
                return null
            }
        }
    }


    return (
    <div className='w-100 registered-user unregistered-form-preview register-form-mt'>
        {renderContent()}
    </div>
    )
}

export default UnregisteredForm