import React from 'react'
import { IoCheckmark } from "react-icons/io5";
import ButtonComponent from '../Buttons/ButtonSubmit';
import { Link } from 'react-router-dom';
import { IoCloseOutline } from "react-icons/io5";


const Popup = ({children, onClick, className}) => {

  return (
    <div className={`popup d-flex flex-column ${className} `}>
        {
          children ? (
            <>
              {children}
            </>
          ) : (     
            <>
              <div className='close'><IoCloseOutline onClick={onClick} /></div>
              <div className='icon d-flex justify-content-center align-items-center p-2'><IoCheckmark /></div>
              <h2>Completed!</h2>
              <p className='light-text'>Congratulations, your profile has been successfully created.</p>
              <p className='dark-text'>A verification link has been sent to your email, please click & verify your email ID.</p>
              <ButtonComponent className='w-100 mt-3' onClick>
                  Continue
                  <i className="ml-3 fa fa-regular fa-angle-right"></i>
              </ButtonComponent>
              <p className='mt-3 signup-link'>Didn’t receive verification link? 
                  <Link to='/html/dashboard' className='text-decoration-none'> Resend</Link> 
              </p>
            </>   
           )
        }
    </div>
  )
}

export default Popup