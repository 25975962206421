import React, { useState } from 'react';
import WordlMap from '../../../assets/images/world-map.png'; // Import your CSS file
import pinImageGrey from '../../../assets/images/pin-grey.svg';
import pinImageOrange from '../../../assets/images/pin-orange.svg';

const AgenciesMap = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);


  const countries = [
    { name: 'USA', coords: { top: '31%', left: '22%' }, tooltip: 'USA' },
    { name: 'USA', coords: { top: '29%', left: '30%' }, tooltip: 'USA' },
    { name: 'Canada', coords: { top: '22%', left: '17%' }, tooltip: 'Canada' },
    { name: 'Brazil', coords: { top: '58%', left: '28%' }, tooltip: 'Brazil' },
    { name: 'Africa', coords: { top: '50%', left: '44%' }, tooltip: 'Africa' },
    { name: 'Africa', coords: { top: '50%', left: '53%' }, tooltip: 'Africa' },
    { name: 'Africa', coords: { top: '55%', left: '48%' }, tooltip: 'Africa' },
    { name: 'Europe', coords: { top: '36%', left: '49%' }, tooltip: 'Europe' },
    { name: 'Europe', coords: { top: '28%', left: '55%' }, tooltip: 'Europe' },
    { name: 'Asia', coords: { top: '44%', left: '63.5%' }, tooltip: 'Asia' },
    { name: 'India', coords: { top: '49.9%', left: '68.1%' }, tooltip: 'India' },
    { name: 'India', coords: { top: '47.8%', left: '70.6%' }, tooltip: 'India' },
    { name: 'SK', coords: { top: '35.9%', left: '77%' }, tooltip: 'SK' },
    { name: 'SK', coords: { top: '35.9%', left: '82.9%' }, tooltip: 'SK' },
    { name: 'Japan', coords: { top: '40.8%', left: '84.2%' }, tooltip: 'Japan' },
  ];

  return (
    <div className="world-map">
      <img src={WordlMap} alt="World Map" className="map" />
      {countries.map((country, index) => (
        <div key={index} className="pin" style={country.coords}>

          <img src={hoveredIndex === index ? pinImageOrange : pinImageGrey}
               alt='' className='pin-image' 
               onMouseEnter={() => setHoveredIndex(index)}
               onMouseLeave={() => setHoveredIndex(null)}
            />

          <span className="tooltip">{country.tooltip}</span>
        </div>
      ))}
    </div>
  );
};

export default AgenciesMap;
