import React from 'react'

const TrashIcon = ({color, className}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M2.5 5.00008H17.5M15.8333 5.00008V16.6667C15.8333 17.5001 15 18.3334 14.1667 18.3334H5.83333C5 18.3334 4.16667 17.5001 4.16667 16.6667V5.00008M6.66667 5.00008V3.33341C6.66667 2.50008 7.5 1.66675 8.33333 1.66675H11.6667C12.5 1.66675 13.3333 2.50008 13.3333 3.33341V5.00008M8.33333 9.16675V14.1667M11.6667 9.16675V14.1667" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default TrashIcon