import React from 'react'

const ButtonStatus = ({img, content, className, status}) => {
  return (
    <div className={`${className} ${
                    status === 'Successful' || status === 'Accepted' || status === 'Unblocked' ? 'btn-status btn-green' : status === 'Rejected' || status === 'Blocked'  ? 'btn-status btn-red' : 
                    status === 'Failed' ? 'btn-status btn-red' : status === 'Pending' ? 'btn-status btn-olive' : 'btn-status btn-orange'}`
    }>
        <img src={img} alt='' className='pr-2' />
        <p className='pb-0 mb-0'>{content}</p>
    </div>
  )
}

export default ButtonStatus