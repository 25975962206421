import React, {useState} from 'react'
import { Container, Row, Col } from 'reactstrap'
import { CiSearch } from "react-icons/ci";
import DropdownCustom from '../../components/Buttons/DropdownCustom';
import TransactionTable from './TransactionTable'
import { tableData } from './TransactionTableData';
import { useClientType } from '../../ContextApi/ClientTypeContext';


const DashboardTable = () => {
    const [search, setSearch] = useState();
    const [optionData, setOptionData] = useState();
    const {clientType} = useClientType();

    const handleChange = (e) => {
        setSearch(e.target.value);
    }

    const options = ['Latest', 'Low to High', 'High to Low'];
    const filterOption = ['Status-all', 'Status'];

    const handleSelect = (e) => {
        setOptionData(e.target.value)
    };
    

  return (
    <Container className='dashboard-table mt-4 px-0'>
        <Row>
            <Col md={clientType === 'option3' ? 2 : 12} className={clientType === 'option3' ? 'px-0 d-flex align-items-end' : 'px-0'}>        
                <div className='dashboard-table-title'>
                    <p className='mb-0'>My Transaction</p>
                </div>
            </Col>
            <Col md={clientType === 'option3' ? 4 : 6} className={`${clientType === 'option3' ? 'px-0 d-flex align-items-end' : 'mt-3 px-0'}`}>
                <div className='search-group d-flex align-items-center'>
                    <CiSearch />
                    <input className='p-2' placeholder='Search' type='text' name='search' value={search} onChange={handleChange}/>
                </div>
            </Col>
            <Col md={3} className={clientType === 'option3' ? 'px-0 d-flex justify-content-end pr-3' : 'px-0'}>
                <div className='sort d-flex align-items-center'>
                    <img src='../assets/sort.svg' />
                    <label htmlFor='sort' className='mb-0 pl-2'>Sort By:</label>
                    <DropdownCustom 
                        className='dashboard-drop dark-text pl-2'
                        name="sort"
                        title=""
                        isRequired = {false}
                        options={options}
                        onSelect={handleSelect}
                        defaultOption='Latest'
                        value={optionData}
                    />
                </div>
            </Col>
            <Col md={3} className={clientType === 'option3' ? 'px-0 d-flex justify-content-end' : 'px-0'}>
                <div className='sort filter d-flex align-items-center'>
                    <img src='../assets/filter.svg' />
                    <label htmlFor='sort' className='mb-0 pl-2'>Filter By:</label>
                    <DropdownCustom 
                        className='dashboard-drop dark-text pl-2'
                        name="sort"
                        title=""
                        isRequired = {false}
                        options={filterOption}
                        onSelect={handleSelect}
                        defaultOption='Status-all'
                        value={optionData}
                    />
                </div>
            </Col>
            <Col md={12} className='px-0'>
                <TransactionTable tableData={tableData} />
            </Col>
        </Row>
    </Container>
  )
}

export default DashboardTable