// ADMIN TYPES ===================
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";

export const ADD_MONEY_WALLET = "ADD_MONEY_WALLET";
export const GET_MONEY_WALLET = "GET_MONEY_WALLET";
export const GET_MONEY_TRANSACTION_LIST = "GET_MONEY_TRANSACTION_LIST";

export const ADMIN_DASHBOARD_DATA = "ADMIN_DASHBOARD_DATA";
export const ADMIN_COMMISSION_GET = "ADMIN_COMMISSION_GET";
export const ADMIN_COMMISSION_UPDATE = "ADMIN_COMMISSION_UPDATE";
export const ADMIN_GEO_LOCATIONS_GET = "ADMIN_GEO_LOCATIONS_GET";

export const ADMIN_AGENCY_COMMISSION_GET = "ADMIN_AGENCY_COMMISSION_GET";
export const ADMIN_AGENCY_COMMISSION_UPDATE = "ADMIN_AGENCY_COMMISSION_UPDATE";

export const ADMIN_RECHARGE_LIST = "ADMIN_RECHARGE_LIST";

// ASSOCIATE & NORMAL TYPES =======================

export const CLIENT_REGISTER = "CLIENT_REGISTER";
export const CLIENT_REGISTER_FAIL = "CLIENT_REGISTER_FAIL";

export const CLIENT_LOGIN_SUCCESS = "CLIENT_LOGIN_SUCCESS";
export const CLIENT_LOGIN_FAIL = "CLIENT_LOGIN_FAIL";
export const CLIENT_LOGOUT = "CLIENT_LOGOUT";
export const CLIENT_SET_MESSAGE = "CLIENT_SET_MESSAGE";

export const CLIENT_DASHBOARD_DATA = "CLIENT_DASHBOARD_DATA";
export const CLIENT_TRANSACTION_LIST = "CLIENT_TRANSACTION_LIST";

export const CLIENT_SEND_MONEY_AUTH_CLIENT = "CLIENT_SEND_MONEY_AUTH_CLIENT";
export const CLIENT_SEND_MONEY_CLIENT = "CLIENT_SEND_MONEY_CLIENT";

export const CLIENT_TRASNFER_TRANSACTION_LIST =
  "CLIENT_TRASNFER_TRANSACTION_LIST";


export const CLIENT_TRASNFER_TRANSACTION_LIST_BYID =
"CLIENT_TRASNFER_TRANSACTION_LIST_BYID";
export const CLIENT_IMAGE_UPDATE = "CLIENT_IMAGE_UPDATE";

export const CLIENT_GEO_LOCATIONS_GET = "CLIENT_GEO_LOCATIONS_GET";

export const CLIENT_SENT_INVITE = "CLIENT_SENT_INVITE";
export const CLIENT_GET_INVITE = "CLIENT_GET_INVITE";

export const CLIENT_UPDATE_ROLE = "CLIENT_UPDATE_ROLE";
export const CLIENT_GET_AVAILABLITY = "CLIENT_GET_AVAILABLITY";
export const CLIENT_UPDATE_AVAILABLITY = "CLIENT_UPDATE_AVAILABLITY";

export const CLIENT_SEND_MONEY_UNREGISTER_TO_UNREGISTER =
  "CLIENT_SEND_MONEY_UNREGISTER_TO_UNREGISTER";
export const CLIENT_SEND_MONEY_REGISTER_TO_UNREGISTER =
  "CLIENT_SEND_MONEY_REGISTER_TO_UNREGISTER";
export const CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_ONLINE =
  "CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_ONLINE";
export const CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_OFFLINE =
  "CLIENT_SEND_MONEY_REGISTER_TO_REGISTER_OFFLINE";

// AGENCY TYPES ==================

export const AGENCY_LOGIN_SUCCESS = "AGENCY_LOGIN_SUCCESS";
export const AGENCY_LOGIN_FAIL = "AGENCY_LOGIN_FAIL";
export const AGENCY_LOGOUT = "AGENCY_LOGOUT";
export const AGENCY_SET_MESSAGE = "AGENCY_SET_MESSAGE";

export const AGENCY_DASHBOARD_DATA = "AGENCY_DASHBOARD_DATA";

export const AGENCY_TRASNFER_TRANSACTION_LIST =
  "AGENCY_TRASNFER_TRANSACTION_LIST";

export const AGENCY_TELLER_LIST = "AGENCY_TELLER_LIST";
export const AGENCY_TELLER_DETAILS = "AGENCY_TELLER_DETAILS";



// 
export const GET_DOCUMENT_LIST = "GET_DOCUMENT_LIST";

export const CHAT_USERS_LIST = "CHAT_USERS_LIST";


export const NOTIFICATION_LIST = 'NOTIFICATION_LIST' 