import React, {useState} from 'react'
import { Container, Row, Col } from 'reactstrap'
import ProgressBar from '../../../components/Progressbar/ProgressBar'
import InputField from '../../../components/Buttons/InputField'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import DropdownCustom from '../../../components/Buttons/DropdownCustom'
import { RiUserSearchLine } from 'react-icons/ri'
import DatePickerCustom from '../../../components/Buttons/DatePickerCustom'
import NumberDropdown from '../../../components/Buttons/NumberDropdown'
import UploadButton from '../../../components/Buttons/UploadButton'
import Popup from '../../../components/Popup/Popup'
import BookPerson from '../../../components/svg/BookPerson';
import { IoCloseOutline } from 'react-icons/io5'


const CreateAccount = () => {
  const [step, setStep] = useState(1);  
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    fname: '',
    surname: '',
    dob: '',
    gender: '',
    mobile: '',
    email: '',
    option: '',
    countryCode: '+1',
    company: '',
    sinceyear: '',
    cashcapacity: '',
  })

  const options = ['Male', 'Female', 'Other'];

  const nextStep = () => {
      setStep(step + 1);
  }

  const handleNumberInput = (code) => {
    setFormData((prevData) => ({
      ...prevData,
      countryCode: code,
      // mobile: '',
    }));
  };

  const handleSelect = (name, value) => {
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
  };

  const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
  };

  const handleDate = (date) => {
    setFormData(prev => ({ ...prev, dob: date }));
  }

  const prevStep = () => {
      if(step === 0 || step === 1){
          return setStep(1);
      }
      setStep(step - 1);
  }

  const handleSubmit = () => {
    setIsOpen(!isOpen);
  }

  const handleClose = () => {
      setIsOpen(!isOpen);
  }

  const renderContent = () => {
    {
        switch(step){
            case 1:
            return(
                <>
                    <Col md={12} className='form-inputs mt-0'>
                        <h3 className='mb-3'>Teller Personal Details</h3>
                        <Row>
                            <Col md={6}>
                              <InputField
                                  name="fname"
                                  type="text"
                                  label="First Name"
                                  placeholder="Enter name"
                                  required
                                  value={formData.fname}
                                  onChange={handleFormChange}
                              />
                            </Col>
                            <Col md={6}>
                              <InputField
                                  name="surname"
                                  type="text"
                                  label="Surname"
                                  placeholder="Enter Surname"
                                  required
                                  value={formData.surname}
                                  onChange={handleFormChange}
                                  className='mt-0'
                              />
                            </Col>
                            <Col md={6} className='mt-4'>
                              <DatePickerCustom 
                                  name="dob"
                                  title='DOB'
                                  required={true}
                                  value={formData.dob}
                                  onChange={handleDate}
                                  placeholderText='Choose Date of Birth'
                              />
                            </Col>
                            <Col md={6} className='mt-4'>
                              <DropdownCustom 
                                  name="gender"
                                  title="Gender"
                                  options={options}
                                  onSelect={handleSelect}
                                  defaultOption='Choose Gender'
                                  value={formData.gender}
                              />
                            </Col>
                            <Col md={6} className='mt-4'>
                                <NumberDropdown
                                    name="mobile"
                                    title='Mobile Number'
                                    options={['+91', '+92', '+1']}
                                    onSelect={handleNumberInput}
                                    defaultOption={formData.countryCode}
                                    type='number'
                                    placeholder='Enter Mobile Number'
                                    required={true}
                                    onChange={handleFormChange}
                                    value={formData.mobile}
                                />
                            </Col>
                            <Col md={6} className='mt-4'>
                                <InputField
                                    name="email"
                                    type="email"
                                    label="Email"
                                    placeholder="Enter Email Address"
                                    required
                                    value={formData.email}
                                    onChange={handleFormChange}
                                />
                            </Col>
                            <Col md={4} className='mt-4'>
                              <DropdownCustom 
                                  name="city"
                                  title="City"
                                  options={options}
                                  onSelect={handleSelect}
                                  defaultOption='Enter City'
                                  value={formData.city}
                              />
                            </Col>
                            <Col md={4} className='mt-4'>
                              <DropdownCustom 
                                  name="country"
                                  title="Country"
                                  options={options}
                                  onSelect={handleSelect}
                                  defaultOption='Choose Country'
                                  value={formData.country}
                              />
                            </Col>
                            <Col md={4} className='mt-4'>
                              <DropdownCustom 
                                  name="zipcode"
                                  title="Zip Code"
                                  options={options}
                                  onSelect={handleSelect}
                                  defaultOption='Enter Zip Code'
                                  value={formData.zipcode}
                              />
                            </Col>
                            <Col md={12} className='mt-4'>
                              <InputField
                                  name="address"
                                  type="text"
                                  label="Address"
                                  placeholder="Enter Address"
                                  required
                                  value={formData.address}
                                  onChange={handleFormChange}
                              />
                            </Col>                            
                            {/* <p className='sub-head w-100 mdl mb-0'>Occupation Details</p> */}
                            <h3 className='mb-3 mt-4 w-100 col-md-12'>Occupation Details</h3>
                            <Col md={12}>
                              <InputField
                                  name="company"
                                  type="text"
                                  label="Name of the Company"
                                  placeholder="Enter Company Name"
                                  required
                                  value={formData.company}
                                  onChange={handleFormChange}
                              />
                            </Col>
                            <Col md={6} className='mt-4'>
                              <DatePickerCustom 
                                  name="sinceyear"
                                  title='Year since'
                                  placeholderText="Select Year"
                                  required={true}
                                  value={formData.sinceyear}
                                  onChange={(e) => setFormData(prevState => ({
                                      ...prevState,
                                      sinceyear: e 
                                  }))}
                              />
                            </Col>
                            <Col md={6} className='mt-4'>
                              <InputField
                                  name="cashcapacity"
                                  type="text"
                                  label="Daily Cash Capacity"
                                  placeholder="Enter the value"
                                  required
                                  value={formData.cashcapacity}
                                  onChange={handleFormChange}
                              />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className='btn-teller-group d-flex justify-content-end'>
                      <ButtonComponent className='w-100' onClick={prevStep} btnType=''>
                          Cancel
                      </ButtonComponent>
                      <ButtonComponent className='w-100' onClick={nextStep}>
                          Next
                          <i className="ml-3 fa fa-regular fa-angle-right"></i>
                      </ButtonComponent>
                    </Col>
                </>
            )
            
            case 2:
            return(
                <>
                  <Col md={12} className='mt-4'>
                      <h3>Upload Documents</h3>
                      <Row className='m-0 p-0'>
                          <Col md={6} className='mb-5 pl-0'><UploadButton title='ID Document' /></Col>
                          <Col md={6} className='mb-5 pr-0'><UploadButton title='Profile Image' /></Col>
                          <Col md={6} className='mb-3 pl-0'><UploadButton title='Upload Signature' /></Col>
                          <Col md={6} className='mb-3 pr-0'><UploadButton title='Digital Signature' btnType="" /></Col>
                      </Row>
                  </Col>
                  <Col md={12} className='btn-teller-group d-flex justify-content-end'>
                      <ButtonComponent className='w-100' onClick={prevStep} btnType=''>
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent className='w-100' onClick={handleSubmit}>
                        Create
                        <i className="ml-3 fa fa-regular fa-angle-right"></i>
                    </ButtonComponent>
                  </Col>
                </>
            )

            default:
            return null
        }
    }
}

  return (
    <Container fluid className='normal-user signup-user px-0 mx-0 create-account-container'>
      <Row className='row px-0 mx-0'>
          <Col md={12} className='px-0 mx-0 left-column d-flex justify-content-center'>
              <div className='m-0 w-100'>
              <Row className='row px-0 mx-0'>
                  <Col md={12} className='mt-5 px-0'>
                    <ProgressBar 
                      currentStep={step}
                      firstText='1. Teller Details'
                      secondText='2. Documents'
                      stepOneIcon={<RiUserSearchLine />} 
                      stepTwoIcon={<BookPerson color={step >= 2 ? '#fff' : '#393939'} />} 
                      stepOne={step > 1 ? true : false}
                      stepTwo={step > 2 ? true : false}
                      className='teller-progress'
                    />
                  </Col>
              </Row>  
              </div>
          </Col>
          <div className='shadow-box'>
           {renderContent()}
          </div>
      </Row>
      { isOpen && 
          <Row className='row mx-0 popup-container d-flex justify-content-center align-items-center px-3 px-md-0'>
              <Col md={12} className='popupWrapper'>
                  <Popup onClick={handleClose} className='justify-content-center align-items-center p-2 p-md-5 teller-pop'>
                    <div className='close'><IoCloseOutline onClick={handleClose} /></div>
                    <div className='icon d-flex justify-content-center align-items-center p-2 mb-4'>
                      <img src='../assets/check-mark.svg' alt='' className='' />
                    </div>
                    <h2>Success !</h2>
                    <p className='dark-text'>Congratulation, a Agency teller account has been successfully created</p>
                    <ButtonComponent className='w-100 mt-3' onClick>
                        Continue
                    </ButtonComponent>
                  </Popup>
              </Col>
          </Row>
      }
    </Container>
  )
}

export default CreateAccount