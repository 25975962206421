import React, {useState} from 'react'
import { userData } from './UserData'
import TrashIcon from '../../../components/svg/TrashIcon';
import { CiSearch } from 'react-icons/ci';

const Message = () => {
    const [chatData, setChatData] = useState(userData);
    const [activeChat, setActiveChat] = useState(null);
    const [search, setSearch] = useState();
    const [formData, setFormData] = useState({
        sendMsg: '',
    })

    const handleUserClick = (user) => {
        setActiveChat(user);
    };

    const handleDelete = () => {
        // Delete message code goes here
    }

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleSubmit = () => {
        //Form submit / Chat send code goes here
    }

    const handleSearch = (e) => {
        //Search Feild code goes here
        setSearch(e.target.value);
    }

  return (
    <div className='message-container d-flex'>
        <div className='sidebar-message'>
            <div className='search-group d-flex align-items-center'>
                <CiSearch />
                <input className='p-2' placeholder='Search' type='text' name='search' value={search} onChange={handleSearch}/>
            </div>
            {chatData.length > 0 ? (
                <ul className='px-0'>
                    {chatData.map((item) => {
                        const { id, userImage, userName, userMessage, messageDate } = item;
                        return (
                            <li 
                            key={id} 
                            className='d-flex justify-content-between align-items-center' 
                            onClick={() => handleUserClick(item)}>
                                <div className='d-flex'>
                                    <div className='user-image'>
                                        <img src={userImage} alt={userName} />
                                    </div>
                                    <div className='user'>
                                        <div className='user-name'>{userName}</div>
                                        <div className='user-message'>{userMessage}</div>
                                    </div>
                                </div>
                                <div className='message-date'>{messageDate}</div>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                null
            )}
        </div>
        <div className='chat-box ml-2 ml-4'>
            {
                activeChat ? (<>
                    <div className='user-info d-flex'>
                        <div className='user-image'>
                            <img src={activeChat.userImage} alt={activeChat.userName} />
                        </div>
                        <div className='user'>
                            <div className='user-name'>{activeChat.userName}</div>
                            <div className='user-message'>Online</div>
                        </div>
                    </div>

                    <div className='message-box'>
                        <div className='left-align'>
                            <div className='d-flex align-items-end'>
                                <img src={activeChat.userImage} alt='' />
                                <div className='mesg-field'>When it comes to the world of investing, most people don't know where to start. Fortunately, great investors of the past and present can provide us with guidance.</div>
                            </div>
                            <div className='time'>11.40 AM</div>
                        </div>
                        <div className='right-align'>
                            <div className='d-flex align-items-center justify-content-end'>
                                <TrashIcon color='#FF0000' className='trash' onClick={handleDelete} />
                                <div className='d-flex align-items-end'>
                                    <div className='mesg-field d-flex align-items-center'>Hey, Let’s have a meeting once I’m back in office</div>
                                    <img src='../assets/user-pic-invite.png' alt='' />
                                </div>
                            </div>
                            <div className='time'>11.40 AM</div>
                        </div>
                        <div className='left-align'>
                            <div className='d-flex align-items-end'>
                                <img src={activeChat.userImage} alt='' />
                                <div className='mesg-field'>Hi, Eoin <br /> These investment quotes date back to Benjamin Franklin, and some are from modern experts like Dave Ramsey.</div>
                            </div>
                            <div className='time'>11.40 AM</div>
                        </div>
                        <div className='right-align'>
                            <div className='d-flex align-items-center justify-content-end'>
                                <TrashIcon color='#FF0000' className='trash' onClick={handleDelete} />
                                <div className='d-flex align-items-end'>
                                    <div className='mesg-field d-flex align-items-center'>Hey, Let’s have a meeting once I’m back in office</div>
                                    <img src='../assets/user-pic-invite.png' alt='' />
                                </div>
                            </div>
                            <div className='time'>11.40 AM</div>
                        </div>
                    </div>

                    <div className='input-box px-0'>
                        <input type='text' placeholder='Type Message' onChange={handleChange} />
                        <img src='../assets/send-chat-button.svg' alt='' onClick={handleSubmit} />
                    </div>

                </>) : (<>
                    <div>Select a user to start chatting</div>
                </>)
            }
        </div>
    </div>
  )
}

export default Message