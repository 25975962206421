import React, {useState} from 'react';
import ButtonStatus from '../ButtonStatus';
import Pagination from '../../Dashboard/Recharge/Pagination';
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { CiSearch } from 'react-icons/ci';
import { Col, Row } from 'reactstrap';
import DropdownCustom from '../../../components/Buttons/DropdownCustom';
import { useClientType } from '../../../ContextApi/ClientTypeContext';
import ButtonComponent from '../../../components/Buttons/ButtonSubmit';
import PlusIcon from '../../../components/svg/PlusIcon';
import ProfileDetails from './ProfileDetails';


const TellerAllListingTable = ({dataTable}) => {
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [optionData, setOptionData] = useState();
  const [userProfile, setUserProfile] = useState(false);
  
  const handleChange = (e) => {
    setSearch(e.target.value);
  }

  const options = ['Latest', 'Low to High', 'High to Low'];
  const filterOption = ['Status-all', 'Status'];

  const handleSelect = (e) => {
      setOptionData(e.target.value)
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const handleDetails = (e) => {
    setUserProfile(prev => !prev);
  }

  const filteredData = dataTable.filter(item => {
    if (filter === 'All') return true;
    if (filter === 'Recharge') return item.type === 'Recharge';
    if (filter === 'Sent') {
      return item.type === 'Sent' || item.type === 'Sent (online)';
    }
    return filter === 'Received' ? item.type === 'Received' : false;
  });
  

  const totalPages = Math.ceil(filteredData.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = filteredData.slice(startIndex, startIndex + itemsPerPage)
  
  return (
    <div className="container mt-4 px-0 transfer-table teller-table-all dashboard-table">
        {
            userProfile ? (
                <>
                    <ProfileDetails status={currentItems.status} setUserProfile={setUserProfile} />
                </>
            ) : (
                <>
                    <Row className='mx-0 mr-0 ml-0 mb-3'>
                        <Col className='pl-0 pr-0 filter-btn-group d-flex alitn-items-end'>
                            <button className={`btn btn-border ${filter === 'All' ? 'active' : ''}`} onClick={() => handleFilterChange('All')}>All</button>
                            <button className={`btn btn-border ${filter === 'Sent' ? 'active' : ''}`} onClick={() => handleFilterChange('Sent')}>Sent</button>
                            <button className={`btn btn-border ${filter === 'Received' ? 'active' : ''}`} onClick={() => handleFilterChange('Received')}>Received</button>
                            <button className={`btn btn-border ${filter === 'Recharge' ? 'active' : ''}`} onClick={() => handleFilterChange('Recharge')}>Recharge</button>
                        </Col>
                        <Col className='mt-0 px-0 search-group-btn d-flex align-items-end pl-2'>
                            <div className='search-group d-flex align-items-center'>
                                <CiSearch />
                                <input className='p-2' placeholder='Search' type='text' name='search' value={search} onChange={handleChange}/>
                            </div>
                        </Col>
                        <div className='d-flex justify-content-end align-items-end sorting-btn-group'>
                            <div className='px-0 d-flex justify-content-end m-10'>
                                <div className='sort d-flex align-items-center'>
                                    <img src='../assets/sort.svg' />
                                    <label htmlFor='sort' className='mb-0 pl-2'>Sort By:</label>
                                    <DropdownCustom 
                                        className='dashboard-drop dark-text pl-2'
                                        name="sort"
                                        title=""
                                        isRequired = {false}
                                        options={options}
                                        onSelect={handleSelect}
                                        defaultOption='Latest'
                                        value={optionData}
                                    />
                                </div>
                            </div>
                            <div className='px-0 d-flex justify-content-end m-10'>
                                <div className='sort filter d-flex align-items-center'>
                                    <img src='../assets/filter.svg' />
                                    <label htmlFor='sort' className='mb-0 pl-2'>Filter By:</label>
                                    <DropdownCustom 
                                        className='dashboard-drop dark-text pl-2'
                                        name="sort"
                                        title=""
                                        isRequired = {false}
                                        options={filterOption}
                                        onSelect={handleSelect}
                                        defaultOption='Status-all'
                                        value={optionData}
                                    />
                                </div>
                            </div>
                            <div className='px-0 create-btn-wrap'>
                                <ButtonComponent className='create-btn'>
                                    <PlusIcon color='#fff' className='mr-2' />
                                    create
                                </ButtonComponent>
                            </div>
                        </div>
                    </Row>
                    <table className="table table-bordered-bottom">
                        <thead>
                        <tr>
                            <th>Teller Id</th>
                            <th>Teller Date</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Creation Date</th>
                            <th className='text-center'>Status</th>
                            <th className='text-center'>Actions</th>
                        </tr>
                        </thead>
                        <tbody className='table-data'>
                        {
                        currentItems.map( (Item, index) => {
                            const {Img, TellerId, TellerDate, phone, email, creationDate, status, action} = Item;
                            return(
                            <tr key={index}>
                                <td className=''><img src={Img} alt={TellerId} className='img-id mr-1' /> {TellerId}</td>
                                <td className='pr-3'>{TellerDate}</td>
                                <td className='phone font-weight-bold'>{phone}</td>
                                <td className='orange'>{email}</td>
                                <td className=''>{creationDate}</td>
                                <td className='text-center' align='middle'>
                                    {
                                    status === 'Unblocked' ? (
                                        <ButtonStatus
                                        img='../assets/circle-green.svg'
                                        content='Unblocked'
                                        className=''
                                        status='Unblocked'
                                        />
                                    ) : status === 'Blocked' ? (
                                        <ButtonStatus
                                            img='../assets/circle-red.svg'
                                            content='Blocked'
                                            className=''
                                            status='Blocked'
                                        />
                                        ) : null
                                    }
                                </td>
                                <td className='orange details-btn' align='middle' onClick={(e) => handleDetails(e)}>{action}</td>
                            </tr>
                            )
                        })
                        }
                        </tbody>
                    </table>
                    {
                        totalPages > 1 && 
                        <div className='pagination'>
                            <Pagination 
                                totalPages={totalPages} 
                                currentPage={currentPage} 
                                onPageChange={handlePageChange} 
                            />
                        </div>
                    }
                </>
            )
        }
    </div>
  );
};

export default TellerAllListingTable;
