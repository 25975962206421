export const transactionData = [
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: null, sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Failed', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: null, sender: null, senderAgency: 'Pinnacle Partners', time: '00:13', status: 'Incomplete', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: null, sender: 'H.Davis', senderAgency: 'Zenith Solutions', time: '00:13', status: 'Failed', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: null, sender: null, senderAgency: 'Nexus Agency', time: '00:13', status: 'Pending', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: 'S.Bennett', senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: null, sender: null, senderAgency: 'Vertex Group', time: '00:13', status: 'Failed', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: 'F.Turner', senderAgency: 'Zenith Solutions', time: '00:13', status: 'Pending', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: 'C.Alison', senderAgency: 'Zenith Solutions', time: '00:13', status: 'Failed', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Radiant Agency', time: '00:13', status: 'Incomplete', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Recharge', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Radiant Agency', time: '00:13', status: 'Incomplete', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Recharge', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Radiant Agency', time: '00:13', status: 'Incomplete', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Recharge', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Radiant Agency', time: '00:13', status: 'Incomplete', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Radiant Agency', time: '00:13', status: 'Incomplete', activity: '@andrew', paymentMethod: 'To wallet'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Sent (online)', receiver: 'H.Adams abcd', receiverAgency: 'Apex Innovations', sender: null, senderAgency: 'Zenith Solutions', time: '00:13', status: 'Successful', activity: '@bani zuhilmin', paymentMethod: 'Wallet transfer'},

]