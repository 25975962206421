import "./App.css";
import { lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// ROUTES
import AdminRoute from "./routes/AdminRoutes";
import PublicRoute from "./routes/publicRoutes";

import ClientRoute from "./routes/ClientRoutes";
import { getStorage } from "./helpers/apihelper";
import AgencyRoute from "./routes/AgencyRoutes";
import { useClientType } from "./ContextApi/ClientTypeContext";
import { onMessageListener } from "./services/firebase/firebase";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { GetNotification } from "./redux/actions/client/dashboard";

// ADMIN PUBLIC
const Signin = lazy(() => import("./features/admin/authentication/Signin"));
const ForgotPassword = lazy(
  () => import("./features/admin/authentication/ForgotPassword")
);
const ResetPassword = lazy(
  () => import("./features/admin/authentication/ResetPassword")
);
// ADMIN PRIVATE
const DashBoard = lazy(() => import("./components/dashboard/dashBoard"));
const Profile = lazy(() => import("./features/admin/authentication/profile"));
const ClientCreate = lazy(
  () => import("./features/admin/client-management/Client-create-update")
);
const ClientList = lazy(
  () => import("./features/admin/client-management/Client-List")
);
const PermissionList = lazy(
  () => import("./features/admin/permission-management/permission-list")
);
const RoleList = lazy(
  () => import("./features/admin/role-management/RoleList")
);
const AdminList = lazy(
  () => import("./features/admin/admin-management/Admin-List")
);
const AdminCreate = lazy(
  () => import("./features/admin/admin-management/Admin-create-update")
);
const AdminRecharge = lazy(
  () => import("./features/admin/recharge-management/New-Recharge")
);
const AdminReport = lazy(
  () => import("./features/admin/report-management/Report")
);

const AdminReportTransaction = lazy(
  () => import("./features/admin/report-management/Report-Table")
);
const Commission = lazy(
  () =>
    import(
      "./features/admin/commission-management/default-commissionManagement"
    )
);
const AgencyCommission = lazy(
  () =>
    import("./features/admin/commission-management/agency-commissionManagement")
);
const RechargeList = lazy(
  () => import("./features/admin/recharge-management/Recharge-List")
);
const GeoLocation = lazy(
  () => import("./features/admin/geolocation-management/MapView")
);
const TransactionHistory = lazy(
  () => import("./features/admin/user-management/TransactionHistory")
);
const UserActivity = lazy(
  () => import("./features/admin/user-management/UserActivity")
);
const ManageUser = lazy(
  () => import("./features/admin/user-management/ManageUser")
);

const ClientLogin = lazy(
  () => import("./features/client/authentication/Login/Login")
);
const ClientSignUp = lazy(
  () => import("./features/client/authentication/Signup/Signup")
);
const ClientForgetPassword = lazy(
  () => import("./features/client/authentication/Password/ForgotLoginPassword")
);
const ClientRestPassword = lazy(
  () => import("./features/client/authentication/Password/CreateNewPassword")
);

const ClientDashboard = lazy(
  () => import("./features/client/Dashboard/Dashboard")
);
const ClientReacharge = lazy(
  () => import("./features/client/Recharge/RechargeAccount")
);
const ClientProfile = lazy(() => import("./features/client/Profile/Profile"));
const ClientGeoLocation = lazy(() => import("./features/client/GeoLocation"));
const ClientInvite = lazy(() => import("./features/client/Invite/InviteUser"));
const ClientTrasaction = lazy(
  () => import("./features/client/Transaction/Transaction")
);
const ClientTrasactionWithdrwal = lazy(
  () => import("./features/client/Transaction/Withdrawal/index")
);
const ClientWithdrwalDetails = lazy(
  () => import("./features/client/Transaction/Withdrawal/WithdrwalDetails")
);
const Message = lazy(() => import("./features/client/Message/Chat"));

// AGENCY
const AgencyDashborad = lazy(
  () => import("./features/agency/Dashboard/Dashboard")
);
const AgencyProfile = lazy(() => import("./features/agency/Profile/Profile"));
const AgencyGeoLocation = lazy(() => import("./features/agency/GeoLocation"));
const AgencyTeller = lazy(
  () => import("./features/agency/TellerAccount/TellerAccountMain")
);
const AgencyReacharge = lazy(
  () => import("./features/agency/Recharge/RechargeAccount")
);
// const AgencyMoney = lazy(() => import('./features/agency/Invite/InviteUser'))
const AgencyTransaction = lazy(
  () => import("./features/agency/Transaction/Transaction")
);
const AgencyWithdrwalDetails = lazy(
  () => import("./features/agency/Transaction/Withdrawal/Details")
);
const AgencyTrasactionWithdrwal = lazy(
  () => import("./features/agency/Transaction/Withdrawal/index")
);
const SuccessPage = lazy(
  () => import("./components/StripePayment/Success/Success")
);
export default function App() {
  const isLoggedInAdmin = getStorage("token") !== undefined ? true : false;
  const isLoggedInClient = getStorage("__ct") !== undefined ? true : false;
  const isLoggedInAgency = getStorage("__at") !== undefined ? true : false;
  const userType = useSelector(
    (state) => state?.auth?.agency?.user?.data?.data?.user?.userTypes
  );
  const dispatch = useDispatch();
  const { clientType } = useClientType();

  async function notifyMe() {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notifications.");
    } else if (Notification.permission === "granted") {
      console.log("Notification permission granted.");
    } else if (Notification.permission === "denied") {
      alert(
        "You have denied notifications. To enable them, please go to your browser settings."
      );
      // console.log("Notification permission denied. Ask the user to enable notifications manually.");
    } else if (Notification.permission === "default") {
      Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          // const notification = new Notification("Hi there!");
          // await setToken()
          console.log("Notification permission granted.");
        } else {
          console.log("User denied notifications.");
        }
      });
    }
  }

  useEffect(() => {
    notifyMe();
    const unsubscribe = onMessageListener()
      .then((payload) => {
        const { notification } = payload;
        console.log("Received foreground message:", notification);
        if (notification) {
          dispatch(GetNotification(clientType));
          toast.success(notification.body);
        }
      })
      .catch((err) =>
        console.log("Failed to receive foreground message:", err)
      );
    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, []);

  return (
    <BrowserRouter future={{ v7_startTransition: true }}>
      <Routes>
        <Route element={<PublicRoute />}>
          {/* ADMIN PUBLIC ROUTE */}
          <Route
            path="/"
            element={<Navigate to="/client/signin" replace />}
          />
          <Route path="/admin/signin" key="signin" element={<Signin />} />
          <Route
            path="/admin/forgotpassword"
            key={"forgetpassword"}
            element={<ForgotPassword />}
          />
          <Route
            path="/admin/resetpassword/:token"
            key="restpassword"
            element={<ResetPassword />}
          />

          {/* CLIENT PUBLIC ROUTES */}
          <Route
            path="/client/signin"
            key="clientsignin"
            element={<ClientLogin />}
          />
          <Route
            path="/client/signup"
            key="clientsignin1"
            element={<ClientSignUp />}
          />
          <Route
            path="/client/forget-password"
            key="client-forget-password"
            element={<ClientForgetPassword />}
          />
          <Route
            path="/client/reset-password/:token"
            key="client-reset-password"
            element={<ClientRestPassword />}
          />
        </Route>

        {/* ADMIN ROUTES */}
        <Route element={<AdminRoute isLoggedInAdmin={isLoggedInAdmin} />}>
          <Route path="admin">
            <Route
              index
              path="dashboard"
              key="dashboard"
              element={<DashBoard />}
            />
            <Route path="clients">
              <Route
                index
                path="create"
                key="client-create"
                element={<ClientCreate />}
              />
              <Route
                index
                path="list"
                key="client-list"
                element={<ClientList />}
              />
              <Route
                index
                path="edit/:userId"
                key="client-id"
                element={<ClientCreate />}
              />
            </Route>
            <Route path="admins">
              <Route
                index
                path="create"
                key="client-create"
                element={<AdminCreate />}
              />
              <Route
                index
                path="list"
                key="client-list"
                element={<AdminList />}
              />
              <Route
                index
                path="edit/:userId"
                key="client-id"
                element={<AdminCreate />}
              />
            </Route>
            <Route
              index
              path="permission-management"
              key="permission-list"
              element={<PermissionList />}
            />
            <Route
              index
              path="role-management"
              key="role-list"
              element={<RoleList />}
            />
            <Route path="profile" element={<Profile />} />
            <Route path="commission">
              <Route path="default-management" element={<Commission />}></Route>
              <Route
                path="agency-management"
                element={<AgencyCommission />}
              ></Route>
            </Route>

            <Route
              path="recharge-management"
              element={<RechargeList />}
            ></Route>
            <Route path="new-recharge" element={<AdminRecharge />}></Route>
            <Route path="report-management" element={<AdminReport />}></Route>
            <Route
              path="transaction-report-management"
              element={<AdminReportTransaction />}
            ></Route>
            <Route
              path="geolocation-management"
              element={<GeoLocation />}
            ></Route>
            <Route path="user-management">
              <Route path="manage-user" element={<ManageUser />}></Route>
              <Route
                path="transaction-history"
                element={<TransactionHistory />}
              ></Route>
              <Route path="user-activity" element={<UserActivity />}></Route>
            </Route>
          </Route>
        </Route>

        {/* NORMAL AND ASSOCIATE ROUTES */}
        <Route element={<ClientRoute isLoggedInClient={isLoggedInClient} />}>
          <Route path="client">
            <Route
              index
              path="dashboard"
              key="dashboard"
              element={<ClientDashboard />}
            />
            <Route
              path="completion"
              key="success-page"
              element={<SuccessPage />}
            />

            <Route
              index
              path="add-money"
              key="client-Recharge"
              element={<ClientReacharge />}
            />
            <Route
              index
              path="profile"
              key="client-Recharge"
              element={<ClientProfile />}
            />
            <Route
              index
              path="money-transfer"
              key="money-trasnfer"
              element={
                <ClientTrasaction
                  activeTab={"transfer"}
                  setActiveTab={"transfer"}
                />
              }
            />
            <Route
              index
              path="transaction-history"
              key="client-history"
              element={
                <ClientTrasaction
                  activeTab={"history"}
                  setActiveTab={"history"}
                />
              }
            />
            {clientType === "associate" ? (
              <Route
                index
                path="transaction-withdrwal"
                key="client-withdrwal"
                element={
                  <ClientTrasactionWithdrwal
                    activeTab={"withdrwal"}
                    setActiveTab={"withdrwal"}
                  />
                }
              />
            ) : null}
            <Route
              index
              path="transaction-details/:id"
              key="client-withdrwal-details"
              element={
                <ClientWithdrwalDetails
                  activeTab={"withdrwal"}
                  setActiveTab={"withdrwal"}
                />
              }
            />
            <Route
              index
              path="geolocation"
              key="client-Geolocation"
              element={<ClientGeoLocation />}
            />
            <Route
              index
              path="invite"
              key="client-Invite"
              element={<ClientInvite />}
            />
            {/* <Route
              path={`payment-completion`}
              key="success-page"
              element={<SuccessPage />}
            /> */}
            <Route index path="message" key="message" element={<Message />} />
          </Route>
        </Route>

        {/* AGENCY ROUTE */}
        <Route element={<AgencyRoute isLoggedInAgency={isLoggedInAgency} />}>
          <Route path="agency">
            <Route
              index
              path="dashboard"
              key="agency-Dashbaord"
              element={<AgencyDashborad />}
            />
            <Route
              path="completion"
              key="success-page-agency"
              element={<SuccessPage />}
            />
            <Route
              index
              path="profile"
              key="agency-Profile"
              element={<AgencyProfile />}
            />
            {userType !== "teller" && (
              <Route
                index
                path="teller"
                key="agency-Teller"
                element={<AgencyTeller />}
              />
            )}

            <Route
              index
              path="add-money"
              key="agency-Recharge"
              element={<AgencyReacharge />}
            />
            <Route
              index
              path="money-transfer"
              key="money-trasnfer"
              element={
                <AgencyTransaction
                  activeTab={"transfer"}
                  setActiveTab={"transfer"}
                />
              }
            />
            <Route
              index
              path="transaction-history"
              key="client-history"
              element={
                <AgencyTransaction
                  activeTab={"history"}
                  setActiveTab={"history"}
                />
              }
            />
            <Route
              index
              path="transaction-withdrwal"
              key="agency-withdrwal"
              element={
                <AgencyTrasactionWithdrwal
                  activeTab={"withdrwal"}
                  setActiveTab={"withdrwal"}
                />
              }
            />
            <Route
              index
              path="geolocation"
              key="agency-Geolocation"
              element={<AgencyGeoLocation />}
            />
            <Route
              index
              path="transaction-details/:id"
              key="agency-withdrwal-details"
              element={
                <AgencyWithdrwalDetails
                  activeTab={"withdrwal"}
                  setActiveTab={"withdrwal"}
                />
              }
            />
            {/* <Route
              path={`payment-completion`}
              key="success-page"
              element={<SuccessPage />}
            /> */}

            <Route index path="message" key="message" element={<Message />} />
          </Route>
        </Route>

        {/* PAYEMNT SUCCESS PAGE*/}

        {/* 404 ROUTE */}
        <Route
          path="*"
          element={<h1 className="text-center mt-5">404 Not Found</h1>}
        />
      </Routes>
    </BrowserRouter>
  );
}
