import React from 'react'

const UserBook = ({color}) => {
  return (
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3737 11.4582C11.3737 10.6957 11.0708 9.96441 10.5316 9.42524C9.99246 8.88607 9.2612 8.58317 8.4987 8.58317M8.4987 8.58317C7.7362 8.58317 7.00493 8.88607 6.46577 9.42524C5.9266 9.96441 5.6237 10.6957 5.6237 11.4582M8.4987 8.58317C9.55724 8.58317 10.4154 7.72505 10.4154 6.6665C10.4154 5.60796 9.55724 4.74984 8.4987 4.74984C7.44015 4.74984 6.58203 5.60796 6.58203 6.6665C6.58203 7.72505 7.44015 8.58317 8.4987 8.58317ZM0.832031 17.6873V3.31234C0.832031 2.67692 1.08445 2.06753 1.53375 1.61823C1.98306 1.16892 2.59245 0.916504 3.22786 0.916504H15.207C15.4612 0.916504 15.705 1.01747 15.8847 1.19719C16.0644 1.37692 16.1654 1.62067 16.1654 1.87484V19.1248C16.1654 19.379 16.0644 19.6228 15.8847 19.8025C15.705 19.9822 15.4612 20.0832 15.207 20.0832H3.22786C2.59245 20.0832 1.98306 19.8308 1.53375 19.3814C1.08445 18.9321 0.832031 18.3228 0.832031 17.6873ZM0.832031 17.6873C0.832031 17.0519 1.08445 16.4425 1.53375 15.9932C1.98306 15.5439 2.59245 15.2915 3.22786 15.2915H16.1654" stroke={color} stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default UserBook