import React from 'react'
import MyAccountBar from './MyAccountBar'
import { Col } from 'reactstrap'
import DashboardTable from '../Dashboard/DashboardTable'

const MyAccount = () => {
  return (
    <div className='my-account pt-3'>
        <MyAccountBar />
        <Col md={12}>
            <DashboardTable />
        </Col>

    </div>
  )
}

export default MyAccount