import React, {useState} from 'react'
import ProgressBar from '../../../../components/Progressbar/ProgressBar'
import { RiUserSearchLine } from 'react-icons/ri'
import { GrTransaction } from 'react-icons/gr'
import PreviewFocus from '../../../../components/svg/PreviewFocus'
import { Col, Row } from 'reactstrap'
import RadioButton from '../../../../components/Buttons/RadioButton'
import DropdownCustom from '../../../../components/Buttons/DropdownCustom'
import UnregisteredForm from './UnregisteredForm'
import Popup from '../../../../components/Popup/Popup'
import { IoCloseOutline } from 'react-icons/io5'
import ButtonComponent from '../../../../components/Buttons/ButtonSubmit'
import UserBook from '../../../../components/svg/UserBook'


const AssociateUser = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(1);  
    const [formData, setFormData] = useState({
        receiverType: '',
        senderType: '',
        checked: '',
        sendTransferOption: '',
        receiveTransferOption: '',
    });
    const [selectedSenderOption, setSelectedSenderOption] = useState('senderRegistered');
    const [selectedRecieverOption, setSelectedRecieverOption] = useState('recieverRegistered');

    const senderOptions = ['cash', 'online',];
    const receiveOptions  = ['Offline (in cash)', 'Online Transfer'];

    const handleSelect = (dropName, value) => {
        console.log(`Selected ${dropName}: ${value}`);
        setFormData((prevData) => ({
            ...prevData,
            [dropName]: value,
        }));
    };
    
    const handleSenderChange = (event) => {
        const value = event.target.value;
        setSelectedSenderOption(event.target.id);
        setFormData((prevData) => ({ ...prevData, senderType: value }));
    };

    const handleReceiverChange = (event) => {
        const value = event.target.value;
        setSelectedRecieverOption(event.target.id);
        setFormData((prevData) => ({ ...prevData, receiverType: value }));
    };

    const handleClose = () => {
        setIsOpen(!isOpen);
    }


  return (
    <div className='assoicate-user register'>
        {
            ((formData.senderType === 'senderRegistered' || selectedSenderOption === 'senderRegistered')
            && formData.receiveTransferOption === 'Online Transfer'
            && (formData.receiverType === 'recieverRegistered' || selectedRecieverOption === 'recieverRegistered'))
            && (formData.receiveTransferOption !== 'Offline (in cash)' || formData.receiveTransferOption !== '')
            ? (
                <>
                <ProgressBar 
                    currentStep={step}
                    firstText='1. Recipient Details'
                    secondText='2. Sender Details'
                    thirdText='3. Transaction Details'
                    FourthText='4. Preview'
                    stepOneIcon={<RiUserSearchLine />}
                    stepTwoIcon={step < 2  ? <UserBook color='#393939' /> : <UserBook color='#fff' />}
                    stepThreeIcon={<GrTransaction />}
                    stepFourIcon={step < 4  ? <PreviewFocus color='#393939' /> : <PreviewFocus color='#fff' />}
                    stepOne={step > 1 ? true : false}
                    stepTwo={step > 2 ? true : false}
                    stepThree={step > 3 ? true : false}
                    stepFour={step > 4 ? true : false}
                    className='moneytransfer-progress'
                />
                </>
            ) : (
                <>
                    <ProgressBar 
                        currentStep={step}
                        firstText='1. Recipient Details'
                        secondText='2. Sender Details'
                        thirdText='3. Agent Details'
                        FourthText='4. Transaction Details'
                        FiveText='5. Preview'
                        stepOneIcon={<RiUserSearchLine />}
                        stepTwoIcon={step < 2  ? <UserBook color='#393939' /> : <UserBook color='#fff' />}
                        stepThreeIcon={step < 3  ? <UserBook color='#393939' /> : <UserBook color='#fff' />}
                        stepFourIcon={<GrTransaction />}
                        stepFiveIcon={step < 5  ? <PreviewFocus color='#393939' /> : <PreviewFocus color='#fff' />}
                        stepOne={step > 1 ? true : false}
                        stepTwo={step > 2 ? true : false}
                        stepThree={step > 3 ? true : false}
                        stepFour={step > 4 ? true : false}
                        stepFive={step > 5 ? true : false}
                        className='moneytransfer-progress'
                />
                </>
            )

        }
        <div>
        <Col md={12} className='d-flex justify-content-start register-wrap'>
            <div className='m-0 left-inner w-100'>
                <Row className='row px-0 mx-0'>
                {
                    step === 1 && <Col md={12} className='recipient-details px-0'><p className='mb-3'>Money Transfer</p></Col>
                }
                    <div className='form-wrapper w-100'>
                        <div className='d-flex flex-wrap'>
                            {
                                step === 1 && (
                                    <>
                                        <Col md={6} className='pl-0'>
                                            <DropdownCustom
                                                dropName="sendTransferOption"
                                                title="Payment Type (from sender)"
                                                options={senderOptions}
                                                onSelect={handleSelect}
                                                defaultOption='Cash'
                                                value={formData.sendTransferOption}
                                            />
                                        </Col>
                                        <Col md={6} className='pr-0'>
                                            <DropdownCustom
                                                dropName="receiveTransferOption"
                                                title="Payment Type (for recipient)"
                                                options={receiveOptions}
                                                onSelect={handleSelect}
                                                defaultOption='Offline (in acash)'
                                                value={formData.receiveTransferOption}
                                            />
                                        </Col>
                                        <Col md={6} className='pl-0 mt-3'>
                                            <div className='mr-18 mb-0 d-flex align-items-center'>
                                                <p className='mb-2 font-small'>Sender Type<span className='text-danger'>*</span></p>
                                            </div>
                                            <div className='mb-0 d-flex align-items-center flex-wrap mt-2'>
                                                <div className='radio-btn-wrap d-flex'>
                                                    <div className='mr-2 mr-10 mb-2'>
                                                        <RadioButton
                                                            id="senderRegistered"
                                                            name="senderType"
                                                            checked={selectedSenderOption === 'senderRegistered'}
                                                            onChange={handleSenderChange}
                                                            label="Registered"
                                                            value="senderRegistered"
                                                        />
                                                    </div>
                                                    <div className='mr-10 mb-2'>
                                                        <RadioButton
                                                            id="senderUnregistered"
                                                            name="senderType"
                                                            checked={selectedSenderOption === 'senderUnregistered'}
                                                            onChange={handleSenderChange}
                                                            label="Unregistered"
                                                            value="senderUnregistered"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6} className='pr-0 mt-3'>
                                            <div className='mr-18 mb-0 d-flex align-items-center'>
                                                <p className='mb-2 font-small'>Recipient Type<span className='text-danger'>*</span></p>
                                            </div>
                                            <div className='mb-0 d-flex align-items-center flex-wrap mt-2'>
                                                <div className='radio-btn-wrap d-flex'>
                                                    <div className='mr-2 mr-10 mb-2'>
                                                        <RadioButton
                                                            id="recieverRegistered"
                                                            name="receiverType"
                                                            checked={selectedRecieverOption === 'recieverRegistered'}
                                                            onChange={handleReceiverChange}
                                                            label="Registered"
                                                            value="recieverRegistered"
                                                        />
                                                    </div>
                                                    <div className='mr-10 mb-2'>
                                                        <RadioButton
                                                            id="recieverUnregistered"
                                                            name="receiverType"
                                                            checked={selectedRecieverOption === 'recieverUnregistered'}
                                                            onChange={handleReceiverChange}
                                                            label="Unregistered"
                                                            value="recieverUnregistered"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                )
                            }
                            {
                                formData.senderType === 'senderUnregistered' 
                                && formData.receiverType === 'recieverUnregistered' 
                                ? (
                                    <>
                                        <UnregisteredForm step={step} setStep={setStep} isOpen={isOpen} setIsOpen={setIsOpen} />
                                    </>
                                ) : (formData.senderType === 'senderRegistered' || selectedSenderOption === 'senderRegistered')
                                && formData.receiverType === 'recieverUnregistered' ? (
                                    <>
                                        <UnregisteredForm 
                                            step={step} 
                                            setStep={setStep} 
                                            isOpen={isOpen} 
                                            setIsOpen={setIsOpen}
                                            senderType={formData.senderType}
                                            selectedOpt={selectedSenderOption}
                                            receiverType={formData.receiverType}
                                        />
                                    </>
                                ) : (formData.senderType === 'senderRegistered' || selectedSenderOption === 'senderRegistered')
                                && formData.receiveTransferOption === 'Online Transfer'
                                && (formData.receiverType === 'recieverRegistered' || selectedRecieverOption === 'recieverRegistered')
                                ? (
                                    <>
                                        <UnregisteredForm 
                                            step={step} 
                                            setStep={setStep} 
                                            isOpen={isOpen} 
                                            setIsOpen={setIsOpen}
                                            senderType={formData.senderType}
                                            selectedOpt={selectedSenderOption}
                                            receiverType={formData.receiverType}
                                            receiverOpt={selectedRecieverOption}
                                            receiverTransfer={formData.receiveTransferOption}
                                        />
                                    </>
                                ) : (formData.senderType === 'senderRegistered' || selectedSenderOption === 'senderRegistered')
                                && (formData.receiveTransferOption === 'Offline (in cash)' || formData.receiveTransferOption === '')
                                && (formData.receiverType === 'recieverRegistered' || selectedRecieverOption === 'recieverRegistered')
                                ? (
                                    <>
                                        <UnregisteredForm 
                                            step={step} 
                                            setStep={setStep} 
                                            isOpen={isOpen} 
                                            setIsOpen={setIsOpen}
                                            senderType={formData.senderType}
                                            selectedOpt={selectedSenderOption}
                                            receiverType={formData.receiverType}
                                            receiverOpt={selectedRecieverOption}
                                            receiverTransfer={formData.receiveTransferOption}
                                        />
                                    </>
                                ) : null
                            }
                        </div>
                    </div>
                </Row>  
            </div>
        </Col>
        </div>
        { isOpen && 
            <Row className='row mx-0 popup-container d-flex justify-content-center align-items-center px-3 px-md-0'>
                <Col md={12} className='popupWrapper'>
                    <Popup onClick={handleClose} className='justify-content-start mt-popup'>
                        <div className='close'>
                            <IoCloseOutline onClick={handleClose} />
                        </div>
                        <div className='icon d-flex justify-content-center align-items-center p-2'>
                            <img src='../assets/check-mark.svg' alt='' />
                        </div>
                        <h2 className='mt-2'>Payment Successful !</h2>
                        <p className='light-text'>Thank you for your payment. An automated receipt will be sent to your registered email.</p>
                        <div className='d-flex justify-content-center btn-wrap'>
                            <ButtonComponent className='mt-2 btn-mt' onClick>
                                Continue
                            </ButtonComponent>
                        </div>
                    </Popup>
                </Col>
            </Row>
        }
    </div>
  )
}

export default AssociateUser