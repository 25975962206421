import React from 'react';
import ButtonStatus from '../ButtonStatus';
import { useClientType } from '../../../ContextApi/ClientTypeContext';


const TellerTable = ({tableData}) => {
  const { clientType } = useClientType();
  
  return (
    <div className="container mt-4 px-0">
      <table className="table table-bordered-bottom teller-table">
        <thead>
          <tr>
            <th>Teller Id</th>
            <th>Teller Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th className='text-center'>Created Date</th>
            <th className='text-center'>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-data'>
          {
            tableData.map( (Item) => {
              const {TransactionId, date, amount, type, status, action, receiver, sender, time} = Item;
              return(
                <tr key={TransactionId}>
                    <td className='pl-5'>{TransactionId}</td>
                    <td className='pl-3 d-flex align-items-center'><img src='../assets/round-grey.png' alt='' className='mr-2 name-img' />S.Anderson</td>
                    <td className='pr-3'>+01 0999 0000</td>
                    <td className='pr-3 colored'>abd@gmail.com</td>
                    <td className='pr-3 text-center'>{date}</td>
                    <td className='pr-3' align='center'>
                      {
                        status === 'Successful' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : (
                          <ButtonStatus
                            img='../assets/circle-red.svg'
                            content='Rejected'
                            className=''
                            status='Rejected'
                          />
                        )
                      }
                    </td>
                    <td className='action'>
                      {
                        status === 'Successful' ? ( 
                          <>
                          <button className="">
                            <img src='../assets/eye-orange.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-orange.svg' />
                          </button>
                          </>
                        ) : (
                          <>
                          <button className="">
                            <img src='../assets/eye-grey.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-grey.svg' />
                          </button>
                          </>
                        )
                      }
                    </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default TellerTable;
