export const dataTable = [
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', status: 'Successful', action: ''},
  ]