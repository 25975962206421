import React, {useState} from 'react'
import { Col, Container, Row } from 'reactstrap';
import MoneyTransfer from '../Transaction/MoneyTransfer';
import TransactionHistory from '../Transaction/TransactionHistory';
import AgenciesMap from './AgenciesMap';
import AssociatedMap from './AssociatedMap';

const MapView = () => {
    const [activeTab, setActiveTab] = useState('agency');
    // const[isHistory, setIsHistory] = useState(true);

    const renderContent = () => {
        switch (activeTab) {
            case 'agency':
                return <AgenciesMap activeTab={activeTab} setActiveTab={setActiveTab} />;
            case 'associate':
                return <AssociatedMap activeTab={activeTab} setActiveTab={setActiveTab} />;
            default:
                return <AgenciesMap activeTab={activeTab} setActiveTab={setActiveTab} />;
        }
    };

  return (
    <Container className='recharge-account'>
        <Row>
          <Col md={12} className='px-0'>
            <div>
              <div className='tabs d-flex align-items-center px-0'>
                  <button onClick={() => setActiveTab('agency')} 
                          className={`tab p-0 pb-3 mr-4 ${activeTab === 'agency' ? 'active' : ''}  pr-3`}>
                      Agencies (15)
                  </button>
                  <button 
                          onClick={() => setActiveTab('associate')} 
                          className={`tab p-0 pb-3 ${activeTab === 'associate' ? 'active' : ''}`}>
                      Associated Client (10)
                  </button>
              </div>
              <div className='tab-content'>
                  {renderContent()}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  )
}

export default MapView