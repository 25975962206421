/* eslint-disable import/no-anonymous-default-export */

import { GET_DOCUMENT_LIST } from "../types";

const initialState = { docmentList: [] };
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DOCUMENT_LIST:
      return {
        ...state,
        docmentList: payload,
      };

    default:
      return state;
  }
}
