import React, { createContext, useContext, useState, useEffect } from "react";

const ClientTypeContext = createContext();

export const ClientTypeProvider = ({ children }) => {
  const [clientType, setClientType] = useState("");

  useEffect(() => {
    const storedClientType = JSON.parse(sessionStorage.getItem("__cd"));
    if (storedClientType) {
      setClientType(storedClientType?.role?.role);
    }
  }, []);
  // if(clientType) {
  //   console.log("Client Type Data:", clientType);
  // }
  // This function will be used to update the client type on login
  const updateClientType = (newClientType) => {
    sessionStorage.setItem("__cd", JSON.stringify(newClientType));
    setClientType(newClientType?.role?.role);
  };

  return (
    <ClientTypeContext.Provider value={{ clientType, updateClientType }}>
      {children}
    </ClientTypeContext.Provider>
  );
};

export const useClientType = () => {
  return useContext(ClientTypeContext);
};
