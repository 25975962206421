import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CiCalendarDate } from 'react-icons/ci';

const TimePickerCustom = ({ 
  name,
  title,
  required = false,
  value,
  onChange,
  onFocus,
  placeholderText,
  Icon,
  className,
 }) => {
  const [startDate, setStartDate] = useState(null); 
  const [open, setOpen] = useState(false);

  const toggleDatePicker = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    onChange(date)
    setOpen(false);
  };


  const handleOutsideClick = (event) => {
    if (!event.target.closest('.dob-picker-container')) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className={`${className} dob-picker-container time-picker-container`}>
      <label className='dob-picker-label w-100'>
          {title}
          {required && <span className="required-asterisk text-danger">*</span>}
        </label>
      <div className="dob-picker-input-wrapper w-100">
        <DatePicker
          name={name}
          selected={startDate}
          onChange={handleDateChange}
          showTimeSelect 
          showDateSelect={false}
          showTimeSelectOnly={true}
          timeFormat="HH:mm"
          timeIntervals={10}
          dateFormat="HH:mm"
          placeholderText={placeholderText || "Choose date and time"}
          className="dob-picker-input w-100" 
          onClick={toggleDatePicker}
          onFocus={onFocus}
          open={open}
        />

        {Icon ? (
          <Icon className="dob-picker-icon" onClick={toggleDatePicker} />
        ) : (
          <CiCalendarDate className="dob-picker-icon" onClick={toggleDatePicker} />
        )}        
      </div>
    </div>
  );
};

export default TimePickerCustom;
