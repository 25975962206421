import React, { useState } from 'react'
import { Col } from 'reactstrap'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import PasswordUpdate from './PasswordUpdate'

const Password = () => {
    const [changePassword, setChangePassword] = useState(false);

    const handlePasswordChange = () =>{
        setChangePassword(true);
    }

  return (
    <div className='password'>
        {
            changePassword ? ( <PasswordUpdate setChangePassword={setChangePassword} />
            ) : (
                <>
                    <p className='title'>Password</p>
                    <Col md={12} className='d-flex justify-content-between px-0 align-items-center'>
                        <div>
                            <p className='text mb-0'>Password</p>
                            <p className='h-pass mb-0'>******</p>
                        </div>
                        <ButtonComponent className='w-100 ml-2' onClick={handlePasswordChange}>
                            Change Password
                        </ButtonComponent>
                    </Col>
                </>
            )
        }

    </div>
  )
}

export default Password