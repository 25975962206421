import React from 'react'

const Company = ({color}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.99935 17.3327V2.33268C3.99935 1.89065 4.17494 1.46673 4.4875 1.15417C4.80007 0.84161 5.22399 0.666016 5.66602 0.666016H12.3327C12.7747 0.666016 13.1986 0.84161 13.5112 1.15417C13.8238 1.46673 13.9993 1.89065 13.9993 2.33268V17.3327M3.99935 17.3327H13.9993M3.99935 17.3327H2.33268C1.89065 17.3327 1.46673 17.1571 1.15417 16.8445C0.84161 16.532 0.666016 16.108 0.666016 15.666V10.666C0.666016 10.224 0.84161 9.80007 1.15417 9.48751C1.46673 9.17494 1.89065 8.99935 2.33268 8.99935H3.99935M13.9993 17.3327H15.666C16.108 17.3327 16.532 17.1571 16.8445 16.8445C17.1571 16.532 17.3327 16.108 17.3327 15.666V8.16602C17.3327 7.72399 17.1571 7.30007 16.8445 6.9875C16.532 6.67494 16.108 6.49935 15.666 6.49935H13.9993M7.33268 3.99935H10.666M7.33268 7.33268H10.666M7.33268 10.666H10.666M7.33268 13.9993H10.666" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default Company