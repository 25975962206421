import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash, FaCalendar } from 'react-icons/fa';
import { CiCalendarDate } from "react-icons/ci";

const InputField = ({
  name,
  type = 'text',       
  label,               
  placeholder,         
  icon: Icon,          
  imageSrc,          
  required = false,   
  onChange,            
  value,              
  className = '',  
  onClick,  
  onFocus,
  autoComplete,
  labelChildren,
  disable = false
}) => {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const inputType = type === 'password' ? (showPassword ? 'text' : 'password') : type;

  return (
    <div className={`input-field ${className}`}>
      <div className="label-container">
        <label>
          {label}
          {required && <span className="required-asterisk text-danger">*</span>}
          {Icon && <span className="icon-container">{<Icon />}</span>}
          {imageSrc && <img src={imageSrc} alt="" className="image-container icon-info" onClick={onClick} />}
          {labelChildren}
        </label>
      </div>
      <div className='d-flex input-wrapper'>
        <input
            name={name}
            type={inputType}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            className="input-field-input"
            disabled={disable}
            onFocus={onFocus}
            autoComplete={autoComplete}
        />
        {type === 'password' && (
            <button
              type="button"
              className="toggle-password-btn"
              onClick={togglePasswordVisibility}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
        )}
        {type==='date' && (
          <button
            type="button"
            className="date-btn"
            onClick={""}
          >
            <CiCalendarDate />
          </button>
        )}
      </div>
    </div>
  );
};

InputField.propTypes = {
  inputName: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password','number']),
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  icon: PropTypes.elementType,
  imageSrc: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  labelChildren: PropTypes.string,
};

export default InputField;
