import {
  CLIENT_IMAGE_UPDATE,
  CLIENT_UPDATE_ROLE,
  CLIENT_GET_AVAILABLITY,
  CLIENT_UPDATE_AVAILABLITY,
} from "../../types/index";

const initialState = { userProfile: {}, availablity: [] };
// 
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_IMAGE_UPDATE:
      return {
        ...state,
        userProfile: payload,
      };
    case CLIENT_UPDATE_ROLE:
      return {
        ...state,
      };
    case CLIENT_GET_AVAILABLITY:
      return {
        ...state,
        availablity: payload,
      };
    case CLIENT_UPDATE_AVAILABLITY:
      return {
        ...state,
        availablity: payload,
      };
    default:
      return state;
  }
}
