/* eslint-disable import/no-anonymous-default-export */
import {
  AGENCY_DASHBOARD_DATA,
  CLIENT_DASHBOARD_DATA,
  NOTIFICATION_LIST,
} from "../../types/index";

const initialState = { dashboard: {}, notification:[] };
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: payload,
      };
    case AGENCY_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: payload,
      };
    case NOTIFICATION_LIST:
      return {
        ...state,
        notification:payload
      }

    default:
      return state;
  }
}
