
import {
  AGENCY_DASHBOARD_DATA,
  CLIENT_DASHBOARD_DATA,
} from "../../types/index";

const initialState = { dashboard: {} };
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLIENT_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: payload,
      };
    case AGENCY_DASHBOARD_DATA:
      return {
        ...state,
        dashboard: payload,
      };

    default:
      return state;
  }
}
