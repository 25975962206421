import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import PersonalDetailsUpdate from './PersonalDetailsUpdate';
import OcupationInforUpdate from './OcupationInforUpdate';
import UpdateBtn from '../../../components/Buttons/UpdateBtn';
import ButtonStatus from '../ButtonStatus';
import DeleteIcon from '../../../components/svg/DeleteIcon';
import FileIcons from '../../../components/svg/FileIcons';
import PlusIcon from '../../../components/svg/PlusIcon';
import ButtonComponent from '../../../components/Buttons/ButtonSubmit';


const ProfileDetails = ({status, setUserProfile}) => {
  const [isPersonalOpen, setPersonalOpen] = useState(true);
  const [isPerOpen, setPerOpen] = useState(false);
  const [isDocumentsOpen, setDocumentsOpen] = useState(false);
  const [udpatePersonal, setPersonalUpdate] = useState(false);
  const [udpateOccupation, setOccupationUpdate] = useState(false);
  const [files, setFiles] = useState([]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <Container className='general-information teller-information p-0'>
        <Row>
            <Col md={12} className='pr-0'>
                <div className='profile-box mb-2'>
                    <div onClick={() => setPersonalOpen(!isPersonalOpen)} className='header-title d-flex justify-content-between align-items-center'>
                        <p className='mb-0'>Personal Details</p>
                        <span>{isPersonalOpen ? (<FaAngleUp />) : (<FaAngleDown />) }</span>
                    </div>
                    {isPersonalOpen && (
                        <div className='content-wrapper'>
                            <div className='content d-flex align-items-start w-100'>
                                <div className='image'>
                                    <img src="../assets/user-pic.webp" alt="" />
                                    <span><FaRegEdit /></span>
                                </div>
                                { udpatePersonal ? (<PersonalDetailsUpdate setPersonalUpdate={setPersonalUpdate} /> ) : (
                                    <>
                                        <div className='d-flex flex-column content-boxx'>
                                            <div className='items d-flex flex-wrap justify-content-between'>
                                                <div className='px-0'>
                                                    <div className='item d-flex align-items-center'>
                                                        <div>
                                                            <p className='title mb-0'>Name</p>
                                                            <p className='mt-2'>Daniel Gallego</p>
                                                        </div>
                                                        <ButtonStatus
                                                            img='../assets/circle-green.svg'
                                                            content='Unblocked'
                                                            className='ml-3'
                                                            status='Unblocked'
                                                        />
                                                    </div>
                                                    <div className='item'>
                                                        <p className='title mb-0'>Client type</p>
                                                        <p className='mt-2 green-color'>Associate</p>
                                                    </div>
                                                    <div className='item'>
                                                        <p className='title mb-0'>Address</p>
                                                        <p className='mt-2'>400 Lavaca St, Austin, TX 78701, United States</p>
                                                    </div>
                                                </div>
                                                <div className='px-0'>
                                                    <div className='item'>
                                                        <p className='title mb-0'>ID</p>
                                                        <p className='mt-2'>2245854</p>
                                                    </div>
                                                    <div className='item'>
                                                        <p className='title mb-0'>Phone</p>
                                                        <p className='mt-2'>+1 702-414-1000</p>
                                                    </div>
                                                </div>
                                                <div className='px-0'>
                                                    <div className='item'>
                                                        <p className='title mb-0'>Gender</p>
                                                        <p className='mt-2'>Male</p>
                                                    </div>
                                                    <div className='item'>
                                                        <p className='title mb-0'>Email</p>
                                                        <p className='mt-2'>
                                                            <a href="mailto:daniel@gmail.com">daniel@gmail.com</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <UpdateBtn onClick={() => setPersonalUpdate(true)} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className='profile-box mb-2'>
                    <div onClick={() => setPerOpen(!isPerOpen)} className='header-title d-flex justify-content-between align-items-center'>
                        <p className='mb-0'>Teller Occupation Information</p>
                        <span>{isPerOpen ? (<FaAngleUp />) : (<FaAngleDown />) }</span>
                    </div>
                    {isPerOpen && (
                        <div className='content-wrapper'>
                        <div className='content d-flex align-items-start w-100'>
                            { udpateOccupation ? (<OcupationInforUpdate setOccupationUpdate={setOccupationUpdate} /> ) : (
                                <>
                                    <div className='d-flex flex-column w-100'>
                                        <div className='items d-flex flex-wrap justify-content-between px-0'>
                                            <div className='px-0'>
                                                <div className='item d-flex align-items-center'>
                                                    <div>
                                                        <p className='title mb-0'>Company name</p>
                                                        <p className='mt-2'>Daniel Gallego</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='px-0'>
                                                <div className='item'>
                                                    <p className='title mb-0'>Since</p>
                                                    <p className='mt-2'>2020</p>
                                                </div>
                                            </div>
                                            <div className='px-0'>
                                                <div className='item'>
                                                    <p className='title mb-0'>Daily cash capacity</p>
                                                    <p className='mt-2'>$2000.00</p>
                                                </div>
                                            </div>
                                        </div>
                                        <UpdateBtn onClick={() => setOccupationUpdate(true)} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    )}
                </div>

                <div className='profile-box mb-2'>
                    <div onClick={() => setDocumentsOpen(!isDocumentsOpen)} className='header-title d-flex justify-content-between align-items-center'>
                        <p className='mb-0'>Documents</p>
                        <span>{isDocumentsOpen ? (<FaAngleUp />) : (<FaAngleDown />) }</span>
                    </div>
                    {isDocumentsOpen && (
                    <div className='content-wrapper upload-container'>
                        <div className='d-flex flex-wrap justify-content-between'>
                            {files.map((file, index) => (
                                <div className='d-flex align-items-center justify-content-between upload-wrap' key={index}>
                                    <div className='d-flex'>
                                        <FileIcons color='#C78F27' className='file' />
                                        <p className='mb-0 filename'>{file.name}</p>
                                    </div>
                                    <div className='d-flex'>
                                        <img src='../assets/eye-orange.svg' alt='View' className='view' /> 
                                        <div onClick={() => handleDeleteFile(index)} className='delete-wrap'>
                                            <DeleteIcon color='#C78F27' className='delete' />
                                        </div>
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                    <div className='add-btn'>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            multiple
                            id="file-upload"
                        />
                        <label htmlFor="file-upload" className="add-btn-btn d-flex align-items-center">
                            <PlusIcon color='#CC9752' className='mr-2' />
                            Add
                        </label>
                    </div>
                    </div>
                    )}
                </div>

                <div className='back-btn-wrap d-flex justify-content-end mt-4'>
                    <ButtonComponent className='no-bordered' onClick={() => setUserProfile(prev => !prev)}>
                        Back
                    </ButtonComponent>
                </div>
            </Col>
        </Row>      
    </Container>
  );
};

export default ProfileDetails;
