import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import MoneyTransfer from './MoneyTransfer';
import TransactionHistory from './TransactionHistory';
import TransactionHistoryDetails from './TransactionHistoryDetails';

const Transaction = ({activeTab, setActiveTab}) => {
    const [activeCurrent, setActiveCurrent] = useState(activeTab);
    const[isHistory, setIsHistory] = useState(true);
    const [openFilter, setOpenFilter] = useState(false);
    const [tDetails, setTDetails] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [Successful, setSuccessful] = useState(false);

    useEffect(() => {
      setActiveCurrent(activeTab);
    }, [activeTab]);

    const handleTabChange = (tab) => {
      setActiveCurrent(tab);
      setActiveTab(tab);
    };

    const handleApply = () => {
      setOpenFilter(prev => !prev);
      setTDetails(prev => !prev);
    }

    const handlePopUp = () => {
      setOpenPopup(prev => !prev);
    }

    const handlePopupSubmit = () => {
      setOpenPopup(prev => !prev);
      setSuccessful(prev => !prev);
    }

    const renderContent = () => {
        switch (activeTab || activeCurrent) {
            case 'transfer':
                return <MoneyTransfer activeCurrent={activeCurrent} setActiveCurrent={setActiveCurrent}  activeTab={activeCurrent} setActiveTab={setActiveCurrent}  />;
            case 'history':
                return <TransactionHistory 
                  activeCurrent={activeCurrent} 
                  setActiveCurrent={setActiveCurrent} 
                  activeTab={activeCurrent} 
                  setActiveTab={setActiveCurrent} 
                  isHistory={isHistory} 
                  openFilter={openFilter}
                  setOpenFilter={setOpenFilter}
                  handleApply={handleApply}
                />;
            default:
                return <MoneyTransfer activeCurrent={activeCurrent} setActiveCurrent={setActiveCurrent} activeTab={activeCurrent} setActiveTab={setActiveCurrent} />;
        }
    };

    return (
      <Container className='recharge-account'>
        <Row>
          <Col md={12} className='px-0'>
            <div>
              <div className='tabs d-flex align-items-center px-0'>
                  <button 
                          onClick={(e) => { e.stopPropagation(); handleTabChange('transfer') }}
                          className={`tab p-0 pb-3 mr-4 ${activeCurrent === 'transfer' ? 'active' : ''}`}>
                      Money Transfer
                  </button>
                  <button 
                          onClick={(e) => { e.stopPropagation(); handleTabChange('history') }}
                          className={`tab p-0 pb-3 ${activeCurrent === 'history' ? 'active' : ''}`}>
                      Transaction History
                  </button>
              </div>
              <div className='tab-content'>
                  {
                    activeCurrent !== 'transfer' && tDetails ? 
                    <TransactionHistoryDetails 
                      setTDetails={setTDetails} 
                      openPopup={openPopup} 
                      handlePopUp={handlePopUp} 
                      handlePopupSubmit={handlePopupSubmit}
                      Successful={Successful}
                      setSuccessful={setSuccessful}
                    /> 
                    : renderContent()
                  }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
};

export default Transaction;
