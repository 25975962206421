import React from 'react'

const PlusIcon = ({color, className}) => {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M1.25 5.5H9.75M5.5 1.25V9.75" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default PlusIcon