import React from 'react';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const Pagination = ({ totalPages, currentPage, onPageChange, className }) => {
  const handlePrevClick = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const dots = '...';

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        for (let i = 1; i <= 4; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push(dots, totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1, dots);
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1, dots);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push(dots, totalPages);
      }
    }

    return pageNumbers.map((pageNumber, index) => (
      <button
        key={index}
        onClick={() => typeof pageNumber === 'number' && onPageChange(pageNumber)}
        disabled={pageNumber === dots}
        className={`paginateButton ${pageNumber === currentPage && 'active'}`}
      >
        {pageNumber}
      </button>
    ));
  };

  return (
    <div className={`paginate-inner ${className}`}>
      <button onClick={handlePrevClick} disabled={currentPage === 1} className='paginateButton'>
        <FaAngleLeft className={`${currentPage >= 2 ? 'orange-class' : 'grey-class'}`} />
      </button>
      {renderPageNumbers()}
      <button onClick={handleNextClick} disabled={currentPage === totalPages} className='paginateButton'>
        <FaAngleRight className={`${currentPage === totalPages ? 'grey-class' : 'orange-class'}`} />
      </button>
    </div>
  );
};

export default Pagination;
