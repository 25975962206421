import axiosInstanceAgency from "../../api/agency";
import axiosInstanceClient from "../../api/client";

const GetDashboard = (payload) => {
  const data = { chartDuration: payload };
  return axiosInstanceClient
    .post(`${process.env.REACT_APP_API_BASE_URL}client/get-dashboard-data`, data)
    .then((response) => {
      return response;
    });
};

const Gettransaction = (payload) => {
  return axiosInstanceClient
    .post(`${process.env.REACT_APP_API_BASE_URL}client/get-transactions-for-dashboard`, payload)
    .then((response) => {
      return response;
    });
};

const GetAgencyDashboard = (payload) => {
  const data = { chartDuration: payload };
  return axiosInstanceAgency
    .post(`${process.env.REACT_APP_API_BASE_URL}agency/get-dashboard-data`, data)
    .then((response) => {
      return response;
    });
};
const getTransferAgencyTransaction = (payload) => {
  return axiosInstanceAgency
    .post(`${process.env.REACT_APP_API_BASE_URL}agency/get-transactions-for-dashboard`, payload)
    .then((response) => {
      return response.data;
    });
};
const getNotification = (type, ntfType) => {
  const axiosInstance = type === 'agency' ? axiosInstanceAgency : axiosInstanceClient;
  return axiosInstance.get(
    `${process.env.REACT_APP_API_BASE_URL}my-notifications${ntfType ? `?type=${ntfType}` : ""
    }`
  ).then((response) => {
    return response.data;
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GetDashboard,
  Gettransaction,
  GetAgencyDashboard,
  getTransferAgencyTransaction,
  getNotification
};
