import React, {useState} from 'react';
import ButtonStatus from './ButtonStatus';
import { useClientType } from '../../ContextApi/ClientTypeContext';
import Pagination from '../Dashboard/Recharge/Pagination';

const TransactionTable = ({tableData}) => {
  const { clientType } = useClientType();
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 8;
  const handlePageChange = (page) => {
      setCurrentPage(page);
  };

  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = tableData.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="container mt-4 px-0">
      <div className='table-responsive'>
      <table className="table table-bordered-bottom">
        <thead>
          <tr>
            <th>Transaction Id</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            {
              clientType === 'option2' || clientType === 'option3' && (
                <>
                  <th>Receiver</th>
                  <th>Sender</th>
                  <th>Time</th>
                </>
              )
            }
            <th className='text-center'>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-data'>
          {
            currentItems.map( (Item) => {
              const {TransactionId, date, amount, type, status, action, receiver, sender, time} = Item;
              return(
                <tr key={TransactionId}>
                    <td className='pl-5'>{TransactionId}</td>
                    <td className='pr-3'>{date}</td>
                    <td className={status === 'Successful' ? 'green-color pr-3' : 'grey-color pr-3'}>{amount}</td>
                    <td className='pr-5'>{type} <span>{status !== 'Successful' ? <img src='../assets/triangle-alert.svg' /> : ''}</span></td>
                    {
                      clientType === 'option2' || clientType === 'option3' && (
                        <>
                          <td className={`${!receiver && 'text-center'}`}>{receiver ? receiver : '-'}</td>
                          <td className={`${!sender && 'text-center'}`}>{sender ? sender : '-'}</td>
                          <td className=''>{time}</td>
                        </>
                      )
                    }
                    <td className='pr-3 text-center' align='center'>
                      {
                        status === 'Successful' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : (
                          <ButtonStatus
                            img='../assets/circle-red.svg'
                            content='Rejected'
                            className=''
                            status='Rejected'
                          />
                        )
                      }
                    </td>
                    <td className='action'>
                      {
                        status === 'Successful' ? ( 
                          <>
                          <button className="">
                            <img src='../assets/eye-orange.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-orange.svg' />
                          </button>
                          </>
                        ) : (
                          <>
                          <button className="">
                            <img src='../assets/eye-grey.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-grey.svg' />
                          </button>
                          </>
                        )
                      }
                    </td>
                </tr>
              )
            })
          }
        </tbody>
      </table></div>
      {
        totalPages > 1 && 
          <div className='pagination'>
            <Pagination 
                totalPages={totalPages} 
                currentPage={currentPage} 
                onPageChange={handlePageChange} 
                className='mt-2'
            />
          </div>
      }
    </div>
  );
};

export default TransactionTable;
