import React, {useState} from 'react'
import InputField from '../../../components/Buttons/InputField'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit' 
import { Col, Row } from 'reactstrap'
import { FaAngleLeft } from "react-icons/fa6";
import Tooltip from '../../../components/Tooltip/Tooltip';


const PasswordUpdate = ({setChangePassword}) => {
    const [openTooltop, setOpenTooltip] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        newpassword: '',
        confirmnewpassword: '',
    })

    const handleSubmit = () => {
        // submit code goes here...
        setChangePassword(false);
    }

    const handleBack = () => {
        setChangePassword(false);
    }

    const handleTooltip = () => {
        setOpenTooltip(!openTooltop);
    }


  return (
    <div className='password-form'>
        <div className='d-flex align-items-center'>
            <FaAngleLeft className='mr-1 back-btn' onClick={handleBack} />
            <p className='mb-0 title'>Change Password</p>
        </div>
        <Col md={12} className='form-inputs mt-4'>
            <Row>
                <Col md={12} className='px-0'>
                    <InputField
                        name="password"
                        type="password"
                        label="Old Password"
                        placeholder="Enter Old Password"
                        requi={false}
                        value={formData.password}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={12} className='mt-4 px-0'>
                    <InputField
                        name="newpassword"
                        type="password"
                        label="New Password"
                        placeholder="Enter New Password"
                        imageSrc="../assets/info.png"
                        requi={false}
                        value={formData.newpassword}
                        onChange={(e) => setFormData(e.target.value)}
                        onClick={handleTooltip}
                        labelChildren={ openTooltop && ( <Tooltip>
                                                            <p className='t-title'>Password must</p>
                                                            <ul className='list mb-0'>
                                                                <li>An <span className='green'>uppercase</span> character</li>
                                                                <li>A <span className='green'>lowercase</span> character</li>
                                                                <li>Minimum <span className='green'>8</span> character</li>
                                                                <li>A <span className='green'>number</span></li>
                                                                <li>A <span className='green'>special</span> character</li>
                                                            </ul>
                                                        </Tooltip>)}
                    />
                </Col>
                <Col md={12} className='mt-4 px-0'>
                    <InputField
                        name="confirmnewpassword"
                        type="password"
                        label="Re-enter New Password"
                        placeholder="Re-Enter New Password"
                        requi={false}
                        value={formData.confirmnewpassword}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={12} className='d-flex justify-content-end mt-5 mb-4 px-0'>
                    <ButtonComponent className='w-100 ml-2' onClick={handleSubmit}>
                        Save
                    </ButtonComponent>
                </Col>
            </Row>
        </Col>
    </div>
  )
}

export default PasswordUpdate