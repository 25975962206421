import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import ClientLayout from "../layouts/ClientLayout";
const ClientRoute = ({ isLoggedInClient }) => {

  return isLoggedInClient ?
    (
      <>
        <Suspense fallback={<div className="loader-container" style={{ width: "75vw", height: "75vh" }}>
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        </div>}>
          <ClientLayout>
            <Outlet />
            <ToastContainer />
          </ClientLayout>
        </Suspense>
      </>
    )
    : <Navigate to="/client/signin" replace />;
};

export default ClientRoute;
