import { createStore, applyMiddleware, } from "redux";
// import {configureStore} from '@reduxjs/toolkit'
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";
import reducers from "../reducers/index";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";


const persistConfig = {
  key: "root",
  storage,
  whitelist: ['auth', 'dashboard', 'recharge', 'profile', 'geolocation', 'invite', 'adminDashboardReducer', 'adminCommission', 'adminRecharge', 'adminGeolocationReducer', 'teller', 'document', 'chat'],
  blacklist: ["transaction"],
};


// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  applyMiddleware(thunk)
);

// Create a persistor
const persistor = persistStore(store);
export { store, persistor };
