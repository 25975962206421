import React, {useState} from 'react'
import InputField from '../../../components/Buttons/InputField'
import DropdownCustom from '../../../components/Buttons/DropdownCustom'
import DatePickerCustom from '../../../components/Buttons/DatePickerCustom'
import { Col } from 'reactstrap'
import { IoCloseOutline } from "react-icons/io5";
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'


const FilterPopup = ({onClick, openFilter, setOpenFilter, handleApply}) => {
    const [formData, setFormData] = useState({
        location: '',
        status: '',
        day: '',
        type: '',
    })

    const transactionOpt = ['Online', 'Cash'];
    const statusOpt = ['Successful', 'Pending', 'Rejected', 'Failed', 'Complete'];

    const handleFormChange = (e) =>{
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleDate = (date) => {
        setFormData(prev => ({ ...prev, day: date }));
    }

    const handleSelect = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleReset = (name) => {
        setFormData({
            location: '',
            status: '',
            day: '',
            type: '',
        });
    }

    // const handleApply = () => {
    //     // submit form code goes here.
    //     setOpenFilter(prev => !prev);
    // }

  return (
    <div className='filter-popup'>
        <div className='title'>All Filters</div>
        <div className='inner'>
            <Col md={12} className='px-0 form-inputs'>
                <InputField
                    name="location"
                    type="text"
                    label="Location"
                    placeholder="Enter country or city"
                    required
                    value={formData.location}
                    onChange={handleFormChange}
                />
            </Col>
            <Col md={12} className='px-0 form-inputs'> 
                <DropdownCustom 
                name="status"
                title="Status"
                options={statusOpt}
                onSelect={handleSelect}
                defaultOption='Select'
                value={formData.status}
                />
            </Col>
            <Col md={12} className='px-0 form-inputs'>
                <DatePickerCustom 
                    name="day"
                    title='Date'
                    placeholderText="Select"
                    required={true}
                    value={formData.day}
                    onChange={handleDate}
                />
            </Col>
            <Col md={12} className='px-0 form-inputs'> 
                <DropdownCustom 
                name="type"
                title="Type"
                options={transactionOpt}
                onSelect={handleSelect}
                defaultOption='Select transaction'
                value={formData.type}
                />
            </Col>
        </div>
        <div className='btn-group-filter d-flex justify-content-between'>
            <ButtonComponent className='reset' onClick={handleReset} btnType=''>
                Reset
            </ButtonComponent>
            <ButtonComponent className='apply' onClick={handleApply}>
                Apply
            </ButtonComponent>
        </div>
        <div className='close'><IoCloseOutline onClick={onClick} /></div>
    </div>
  )
}

export default FilterPopup