import React, {useState} from 'react'
import { LuWallet2, } from "react-icons/lu";
import { GoBell } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { clearSessionStorage } from '../../helpers/apihelper';


const TopBar = ({title, ...args }) => {
  const navigate = useNavigate()
    const [isNotification, setIsNotification] = useState(true);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const handleLogout =() => {
      navigate('/client/signin')
      clearSessionStorage()
    }
  return (
    <div className='topbar d-flex justify-content-between pt-4'>
        <p className='left mb-0'>{title}</p>
        <div className='right d-flex justify-content-end align-items-center'>
            <div className='wallet'><LuWallet2 /></div>
            <div className={`${isNotification ? 'notified' : 'no-notified'} ml-3`}><GoBell /></div>
            <div className='user d-flex align-items-center'>
                {/* <div className='user-pic ml-4'><img src='../assets/user-pic.webp' /></div>
                <p className='mb-0 pl-2'>Daniel Gallego</p>
                <MdKeyboardArrowDown className='icon' /> */}
                <Dropdown direction="down" style={{boxShadow:"none"}} isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle style={{boxShadow:"none", border:"none", background:"transparent"}} className='d-flex align-items-baseline' caret> <div className='user-pic ml-4'><img src='../assets/user-pic.webp' /></div> <p className='mb-0 pl-2'>Daniel Gallego</p></DropdownToggle>
                    <DropdownMenu {...args}>
                      <DropdownItem onClick={() => navigate('/client/profile')}>Profile</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
            </div>
        </div>
    </div>
  )
}

export default TopBar