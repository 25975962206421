import React, {useState} from 'react'
import { Container, Row, Col } from 'reactstrap'
import SidebarProfile from './SidebarProfile'
import Accordion from './Accordion'

const ProfileManagement = () => {
    const [activeComponent, setActiveComponent] = useState(<Accordion />);
    const handleComponentChange = (component) => {
        setActiveComponent(component);
    };

  return (
    <Container className='profile-management'>
        <Row>
            <Col md={3} className='px-0'>
                <SidebarProfile onComponentChange={handleComponentChange} />
            </Col>
            <Col md={9}>
                {activeComponent}
            </Col>
        </Row>
    </Container>
  )
}

export default ProfileManagement