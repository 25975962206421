import React, {useState} from 'react'
import UpdateBtn from '../../../components/Buttons/UpdateBtn';
import Popup from '../../../components/Popup/Popup';
import { IoCloseOutline } from "react-icons/io5";
import ButtonComponent from '../../../components/Buttons/ButtonSubmit';
import CheckBox from '../../../components/Buttons/CheckBox';
import InputField from '../../../components/Buttons/InputField';
import { Link } from 'react-router-dom';
import { useClientType } from '../../../ContextApi/ClientTypeContext';


const AccountType = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        reason: '',
    });
    const { clientType } = useClientType();

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(!isOpen);
    }

  return (
    <div className='account-type'>
        <div className='d-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center'>
                <p className='mb-0 title'>Account Type</p>
            </div>
            <UpdateBtn onClick={handleOpen} />
        </div>
        <div className='type'>
            <p className='mb-0'>{clientType === 'option2' ? 'Associated Client' : 'Normal User'}</p>
        </div>
        { isOpen && 
            <div className='mx-0 popup-container d-flex justify-content-center align-items-center px-3 px-md-0'>
                <div className='popupWrapper'>
                    <Popup onClick={handleClose} className='justify-content-start'>
                        <div className='close'>
                            <IoCloseOutline onClick={handleClose} />
                        </div>
                        <p className='titles mt-3'>Change User Type to : <span>Agency User</span></p>
                        <div className='form-inputs'>
                            <InputField
                                name="reason"
                                type="text"
                                label="Reason"
                                placeholder="Enter Your Reason For The Change"
                                required={false}
                                value={formData.reason}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </div>
                        <div className='checkbox position-relative mt-4'>
                            <CheckBox
                                className="custom-check"
                                id="terms-checkbox"
                                label={
                                    <>
                                        I agree to the <span className="highlighted-text"><Link to=''>Terms & Conditions</Link></span>.
                                    </>
                                    }
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className='d-flex justify-content-end btn-wrap'>
                            <ButtonComponent className='mt-3' onClick>
                                Sumbit
                            </ButtonComponent>
                        </div>
                    </Popup>
                </div>
            </div>
        }
    </div>
  )
}

export default AccountType