import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import ProfileUpdate from './ProfileUpdate';
import UpdateBtn from '../../../components/Buttons/UpdateBtn';
import { useClientType } from '../../../ContextApi/ClientTypeContext';



const Accordion = () => {
  const [isPersonalOpen, setPersonalOpen] = useState(true);
  const [isPerOpen, setPerOpen] = useState(false);
  const [isDocumentsOpen, setDocumentsOpen] = useState(false);
  const [udpate, setUpdate] = useState(false);
  const [isoccInformationOpen, setIsOccInformationOpen] = useState(false);
  const { clientType } = useClientType();

  return (
    <Container className='general-information p-0'>
        <Row>
            <Col md={12} className='pr-0'>
            <div className='profile-box mb-2'>
                <div onClick={() => setPersonalOpen(!isPersonalOpen)} className='header-title d-flex justify-content-between align-items-center'>
                    <p className='mb-0'>Personal Details</p>
                    <span>{isPersonalOpen ? (<FaAngleUp />) : (<FaAngleDown />) }</span>
                </div>
                {isPersonalOpen && (
                    <div className='content-wrapper'>
                        <div className='content d-flex align-items-start w-100'>
                            <div className='image'>
                                <img src="../assets/user-pic.webp" alt="" />
                                <span><FaRegEdit /></span>
                            </div>
                            { udpate ? (<ProfileUpdate setUpdate={setUpdate} /> ) : (
                                <>
                                    <div className='d-flex flex-column'>
                                        <div className='items d-flex flex-wrap'>
                                            <div className='col px-0'>
                                                <div className='item'><p className='title mb-0'>Name</p><p className='mt-2'>Daniel Gallego</p></div>
                                                {
                                                    clientType === 'option3'? (
                                                        <>
                                                            <div className='item'>
                                                                <p className='title mb-0'>Email</p>
                                                                <p className='mt-2'>
                                                                    <a href="mailto:daniel@gmail.com">daniel@gmail.com</a>
                                                                </p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className='item'><p className='title mb-0'>Phone</p><p className='mt-2'>+1 702-414-1000</p></div>
                                                        </>
                                                    )
                                                }
                                                <div className='item address'><p className='title'>Address</p><p className='mt-2'>400 Lavaca St, Austin, TX 78701, United States</p></div>
                                            </div>
                                            <div className='col px-0'>
                                                {
                                                    clientType === 'option3' ? 
                                                    <div className='item'><p className='title mb-0'>Phone</p><p className='mt-2'>+1 702-414-1000</p></div>
                                                    : <div className='item'><p className='title mb-0'>Gender</p><p className='mt-2'>Male</p></div>
                                                }
                                                {
                                                     clientType !== 'option3'&& (
                                                        <>
                                                            <div className='item'>
                                                                <p className='title mb-0'>Email</p>
                                                                <p className='mt-2'>
                                                                    <a href="mailto:daniel@gmail.com">daniel@gmail.com</a>
                                                                </p>
                                                            </div>
                                                        </>
                                                     )
                                                }
                                            </div>
                                            <div className='col px-0'>
                                                <div className='item'><p className='title mb-0'>Client Type</p><p className='mt-2'>Associate</p></div>
                                            </div>
                                            
                                        </div>
                                        <UpdateBtn onClick={() => setUpdate(true)} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>

            <div className='profile-box mb-2'>
                <div onClick={() => setPerOpen(!isPerOpen)} className='header-title d-flex justify-content-between align-items-center'>
                    <p className='mb-0'>Peroneal Details</p>
                    <span>{isPerOpen ? (<FaAngleUp />) : (<FaAngleDown />) }</span>
                </div>
                {isPerOpen && (
                    <div className='content-wrapper'>
                        Peroneal Details Content
                    </div>
                )}
            </div>

            <div className='profile-box mb-2'>
                <div onClick={() => setDocumentsOpen(!isDocumentsOpen)} className='header-title d-flex justify-content-between align-items-center'>
                    <p className='mb-0'>Documents</p>
                    <span>{isDocumentsOpen ? (<FaAngleUp />) : (<FaAngleDown />) }</span>
                </div>
                {isDocumentsOpen && (
                <div className='content-wrapper'>
                    Documents Content
                </div>
                )}
            </div>
            {
                clientType === 'option2' && (
                    <div className='profile-box mb-2'>
                        <div onClick={() => setIsOccInformationOpen(!isoccInformationOpen)} className='header-title d-flex justify-content-between align-items-center'>
                            <p className='mb-0'>Occupation Information</p>
                            <span>{isDocumentsOpen ? (<FaAngleUp />) : (<FaAngleDown />) }</span>
                        </div>
                        {isoccInformationOpen && (
                        <div className='content-wrapper p-3'>
                            Occupation Information Content
                        </div>
                        )}
                    </div>
                )
            }
            </Col>
        </Row>      
    </Container>
  );
};

export default Accordion;
