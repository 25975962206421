// RechargeHistory.js
import React from 'react';
import RechargeTransactionTable from './RechargeTransactionTable';
import { tableData } from '../TransactionTableData';
import RechargeTable from './RechargeTable';

const RechargeHistory = () => {
    return (
        <div>
            <RechargeTable />
        </div>
    );
};

export default RechargeHistory;
