import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CardInputNumber = ({ label, isRequired = false }) => {
    const [cardNumber, setCardNumber] = useState(['', '', '', '']);
    const handleInputChange = (index, value) => {
        const newCardNumber = [...cardNumber];
        newCardNumber[index] = value;
        if (value.length === 4 && index < 3) {
            const nextInput = document.getElementById(`card-input-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
        setCardNumber(newCardNumber);
    };


    return (
        <div className="card-number-input">
            <label>{label}{ isRequired && <span className='text-danger'>*</span>} </label>
            <div className="card-number-fields d-flex justify-content-between">
                {cardNumber.map((num, index) => (
                    <input
                        key={index}
                        type="text"
                        id={`card-input-${index}`}
                        maxLength="4"
                        value={num}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        className="form-control"
                        placeholder="xxxx"
                    />
                ))}
            </div>
        </div>
    );
};

CardInputNumber.propTypes = {
    label: PropTypes.string.isRequired,
};

export default CardInputNumber;
