import React from 'react'

const BookIcon = ({color}) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.666992 15.2503V2.75033C0.666992 2.19779 0.908435 1.66789 1.33821 1.27719C1.76798 0.886486 2.35087 0.666992 2.95866 0.666992H14.417C14.6601 0.666992 14.8933 0.75479 15.0652 0.91107C15.2371 1.06735 15.3337 1.27931 15.3337 1.50033V16.5003C15.3337 16.7213 15.2371 16.9333 15.0652 17.0896C14.8933 17.2459 14.6601 17.3337 14.417 17.3337H2.95866C2.35087 17.3337 1.76798 17.1142 1.33821 16.7235C0.908435 16.3328 0.666992 15.8029 0.666992 15.2503ZM0.666992 15.2503C0.666992 14.6978 0.908435 14.1679 1.33821 13.7772C1.76798 13.3865 2.35087 13.167 2.95866 13.167H15.3337M4.33366 8.16699H11.667M4.33366 4.83366H9.83366" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default BookIcon