import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import CreateAccount from './CreateAccount';
import AllListing from './AllListing';


const TellerAccountMain = () => {
    const [activeCurrent, setActiveCurrent] = useState('create');

    const handleTabChange = (tab) => {
      setActiveCurrent(tab);
    };

    const renderContent = () => {
        switch (activeCurrent) {
            case 'create':
                return <CreateAccount activeCurrent={activeCurrent} setActiveCurrent={setActiveCurrent} />;
            case 'all':
                return <AllListing activeCurrent={activeCurrent} setActiveCurrent={setActiveCurrent} />;
            default:
                return <CreateAccount activeCurrent={activeCurrent} setActiveCurrent={setActiveCurrent} />;
        }
    };

    return (
      <Container className='recharge-account myaccount-main'>
        <Row>
          <Col md={12} className='px-0'>
            <div>
              <div className='tabs d-flex align-items-center px-0'>
                  <button 
                          onClick={(e) => { e.stopPropagation(); handleTabChange('create') }}
                          className={`tab p-0 pb-3 mr-4 ${activeCurrent === 'create' ? 'active' : ''}`}>
                      Create Account
                  </button>
                  <button 
                          onClick={(e) => { e.stopPropagation(); handleTabChange('all') }}
                          className={`tab p-0 pb-3 ${activeCurrent === 'all' ? 'active' : ''}`}>
                      All Listing
                  </button>
              </div>
              <div className='tab-content'>
                { renderContent() }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
};

export default TellerAccountMain;
