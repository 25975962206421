import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./language/en/en.json";
import es from "./language/es/es.json";
import fr from "./language/fr/fr.json";
import pt from "./language/pt/pt.json";
import ar from "./language/ar/ar.json";
import zh from "./language/zh/zh.json";
import hi from "./language/hn/hn.json";

// const selectedLang = JSON.parse(localStorage.getItem("lang")).code || "en";
i18n.use(initReactI18next).init({
  debug: false,
  // lng: selectedLang,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en,
    es,
    fr,
    pt,
    ar,
    zh,
    hi,
  },
});

export const lngs = [
  { code: "en", native: "English", flag: "🇬🇧" },
  { code: "es", native: "Español", flag: "🇪🇸" },
  { code: "fr", native: "Français", flag: "🇫🇷" },
  { code: "pt", native: "Português", flag: "🇵🇹" },
  { code: "ar", native: "العربية", flag: "🇸🇦" },
  { code: "zh", native: "中文", flag: "🇨🇳" },
  { code: "hi", native: "हिन्दी", flag: "🇮🇳" },
];

export default i18n;
