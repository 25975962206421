import dashboardService from "../../../services/client/dashboard.service";
import { AGENCY_DASHBOARD_DATA, CLIENT_DASHBOARD_DATA, NOTIFICATION_LIST } from "../../types";


// ? =================== NORMAL DASHBOARD API ====================== 
export const GetDashboard = (payload) => async (dispatch) => {
  try {
    const res = await dashboardService.GetDashboard(payload);
    dispatch({
      type: CLIENT_DASHBOARD_DATA,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// ? ================== AGENCY DASHBOARD API ====================== 

export const GetAgencyDashboard = (payload) => async (dispatch) => {
  try {
    const res = await dashboardService.GetAgencyDashboard(payload);
    dispatch({
      type: AGENCY_DASHBOARD_DATA,
      payload: res.data,
    })
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};



// ? ================== Notification ==================


export const GetNotification = (type, ntfType) => async (dispatch) => {
  try {
    const res = await dashboardService.getNotification(type,ntfType);
    dispatch({
      type: NOTIFICATION_LIST,
      payload: res.message,
    });

    return Promise.resolve(res.message);
  } catch (err) {
    return Promise.reject(err);
  }
};