import React, { useEffect, useState } from 'react'
import { LuWallet2, } from "react-icons/lu";
import { GoBell } from "react-icons/go";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { clearSessionStorageAll } from '../../../helpers/apihelper';
import { useDispatch, useSelector } from 'react-redux';
import { useClientType } from '../../../ContextApi/ClientTypeContext';
import { GetDashboard } from '../../../redux/actions/client/dashboard';


const TopBar = ({ title, ...args }) => {
  const navigate = useNavigate()
  const { clearClientType, clientType } = useClientType();
  const [isNotification, setIsNotification] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useDispatch()
  const handleLogout = () => {
    navigate('/client/signin')
    clearSessionStorageAll()
  }
  useEffect(() => {
    if (clientType !== 'agency') {
      dispatch(GetDashboard('past-12'))
    }
  }, [dispatch])
  const data = useSelector((state) => state?.dashboard?.dashboard?.data?.user)
  return (
    <div className='topbar d-flex justify-content-between pt-4'>
      <p className='left mb-0'>{title}</p>
      <div className='right d-flex justify-content-end align-items-center'>
        <div className='wallet'><LuWallet2 /></div>
        <div className={`${isNotification ? 'notified' : 'no-notified'} ml-3`}><GoBell /></div>
        <div className='user d-flex align-items-center'>
          <Dropdown direction="down" style={{ boxShadow: "none" }} isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle style={{ boxShadow: "none", border: "none", background: "transparent" }} className='d-flex align-items-baseline' caret> <div className='user-pic ml-4'><img src={data?.profileImage} alt='profileImage' /></div> <p className='mb-0 pl-2'>{data?.firstName === undefined ? data?.name : data?.firstName} </p></DropdownToggle>
            <DropdownMenu {...args}>
              <DropdownItem onClick={() => navigate('/client/profile')}>Profile</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default TopBar