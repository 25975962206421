import React, {useState} from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdKeyboardArrowDown } from 'react-icons/md'; 



const DatePickerMMYY = ({label, isRequired = false}) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const formatDate = (date) => {
        if (!date) return '';
        return `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)}`;
    };


  return (
    <div className="date-picker">
        <label>{label}{isRequired && <span className='text-danger'>*</span> }</label>
        <div className="date-input">
            <DatePicker
                selected={selectedDate}
                onChange={(date) => { setSelectedDate(date); }}
                dateFormat="MM/yy"
                showMonthYearPicker
                placeholderText="mm/yy"
                customInput={
                    <input value={formatDate(selectedDate)} readOnly />
                }
            />

            <MdKeyboardArrowDown className="down-arrow" />
        </div>
    </div>
  )
}

export default DatePickerMMYY