import React, {useState} from 'react'
import InputField from '../../../components/Buttons/InputField'
import DropdownCustom from '../../../components/Buttons/DropdownCustom'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import { Col, Row } from 'reactstrap'


const PersonalDetailsUpdate = ({setPersonalUpdate}) => {
    const [formData, setFormData] = useState({
        id: '',
        name: '',
        gender: '',
        phone: '',
        email: '',
        city: '',
        country: '',
        clientType: '',
        address: '',
    })

    const options = ['Normal', 'Associate', 'Agency'];

    const handleSelect = (option) => {
        setFormData(formData.clientType, option)
        console.log('Selected:', option);
    };

    const handleSubmit = () => {
        setPersonalUpdate(false);
    }

    const handleCancel = () => {
        setPersonalUpdate(false);
    }


  return (
    <div className='profile-form'>
        <Col md={12} className='form-inputs'>
            <Row>
                <Col md={6}>
                    <InputField
                        name="name"
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        requi={false}
                        value={formData.name}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6}>
                    <InputField
                        name="id"
                        type="text"
                        label="ID"
                        placeholder="Enter ID"
                        requi={false}
                        value={formData.id}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <InputField
                        name="gender"
                        type="text"
                        label="Gender"
                        placeholder="Enter Gender"
                        requi={false}
                        value={formData.gender}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <DropdownCustom 
                        name="clienttype"
                        title="Client Type"
                        options={options}
                        onSelect={handleSelect}
                        defaultOption='Choose Client Type'
                        value={formData.clientType}
                        isRequired={false}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <InputField
                        name="phone"
                        type="text"
                        label="Phone"
                        placeholder="Enter Phone Number"
                        required={false}
                        value={formData.phone}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <InputField
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Enter Email Address"
                        required={false}
                        value={formData.email}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={12} className='mt-4'>
                    <InputField
                        name="address"
                        type="text"
                        label="Address"
                        placeholder="Enter Address"
                        required={false}
                        value={formData.address}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <InputField
                        name="city"
                        type="text"
                        label="City"
                        placeholder="Enter City"
                        imageSrc=""
                        required={false}
                        value={formData.city}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <InputField
                        name="Country"
                        type="text"
                        label="Country"
                        placeholder="Enter Country"
                        imageSrc=""
                        required={false}
                        value={formData.country}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={12} className='d-flex justify-content-end mt-5 mb-4'>
                    <ButtonComponent className='w-100' onClick={handleCancel} btnType='bordered'>
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent className='w-100 ml-2' onClick={handleSubmit}>
                        Save
                    </ButtonComponent>
                </Col>
            </Row>
        </Col>
    </div>
  )
}

export default PersonalDetailsUpdate