export const tableData = [
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: null, sender: 'H.Adams', time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Waiting', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Rejected', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
    {TransactionId: '2245548', date: '02-08-24', amount: '+$120.00', type: 'Received', receiver: 'H.Adams', sender: null, time: '00:13', status: 'Successful', action: ''},
  ]