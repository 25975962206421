import React from "react";
import Sidebar from "../features/client/Dashboard/Sidebar";
import { Col, Container, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import TopBar from "../features/client/Dashboard/TopBar";
const ClientLayout = (props) => {
  const location = useLocation();

  const pathToTitle = {
    "/client/dashboard": "Dashboard",
    "/client/add-money": "Add Money",
    "/client/profile": "Profile Management",
    "/client/money-transfer": "Money Transfer",
    "/client/transaction-history": "Transaction History",
    "/client/geolocation": "Geo Location",
    "/client/message": "Message (Chat)",
  };

  const activeTitle = pathToTitle[location.pathname] || "";

  return (
    <Container fluid className="normal-dashboard">
      <Row>
        <Col className="dashboard-sidebar px-0">
          <Sidebar />
        </Col>
        <Col className="dashboard-main-content">
          <Row className="px-0 mx-0 p-0 m-0">
            <Col md={12} className="px-0">
              <TopBar title={activeTitle} />
            </Col>

            <Col md={12} className="px-0">
              {props.children}
            </Col>
            <Col md={12} className="px-0">
              <p className="copyright-footer d-flex align-items-center">
                Copyright © 2024 Dualmoney.com. All Rights Reserved |
                <Link to="">Terms and Condition</Link> |
                <Link to="">Privacy Policy</Link> |<Link to="">About Us</Link>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientLayout;
