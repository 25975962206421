import React from 'react'

const BookPerson = ({color}) => {
  return (
  <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3737 11.4587C11.3737 10.6962 11.0708 9.96489 10.5316 9.42573C9.99246 8.88656 9.2612 8.58366 8.4987 8.58366M8.4987 8.58366C7.7362 8.58366 7.00493 8.88656 6.46577 9.42573C5.9266 9.96489 5.6237 10.6962 5.6237 11.4587M8.4987 8.58366C9.55724 8.58366 10.4154 7.72554 10.4154 6.66699C10.4154 5.60845 9.55724 4.75033 8.4987 4.75033C7.44015 4.75033 6.58203 5.60845 6.58203 6.66699C6.58203 7.72554 7.44015 8.58366 8.4987 8.58366ZM0.832031 17.6878V3.31283C0.832031 2.67741 1.08445 2.06802 1.53375 1.61872C1.98306 1.16941 2.59245 0.916992 3.22786 0.916992H15.207C15.4612 0.916992 15.705 1.01796 15.8847 1.19768C16.0644 1.3774 16.1654 1.62116 16.1654 1.87533V19.1253C16.1654 19.3795 16.0644 19.6232 15.8847 19.803C15.705 19.9827 15.4612 20.0837 15.207 20.0837H3.22786C2.59245 20.0837 1.98306 19.8312 1.53375 19.3819C1.08445 18.9326 0.832031 18.3232 0.832031 17.6878ZM0.832031 17.6878C0.832031 17.0524 1.08445 16.443 1.53375 15.9937C1.98306 15.5444 2.59245 15.292 3.22786 15.292H16.1654" stroke={color} stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  )
}

export default BookPerson