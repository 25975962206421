export const getStorage = (sKey) => {
  if (localStorage.getItem("remember_me") === "true") {
    return localStorage.getItem(sKey);
  } else {
    return sessionStorage.getItem(sKey);
  }
};

export const deleteStorage = (sKey) => {
  if (localStorage.getItem("remember_me") === "true") {
    return localStorage.clear();
  } else {
    return sessionStorage.clear();
  }
};
export const setLocaleStorage = (sKey, data) => localStorage.setItem(sKey, data);

export const removeStorage = (sKey) => localStorage.setItem(sKey);

export const clearLocalStorage = (sKey) => localStorage.removeItem(sKey);

export const setSessionStorage = (sKey, data) => sessionStorage.setItem(sKey, data);

export const clearSessionStorage = (sKey) => sessionStorage.removeItem(sKey);

export const clearSessionStorageAll = () => sessionStorage.clear();


export const clearLocalStorageAll = () => localStorage.clear();