import React, {useState} from 'react';
import ButtonStatus from '../ButtonStatus';
import Pagination from './Pagination';


const RechargeTransactionTable = ({tableData}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;


    const handlePageChange = (page) => {
      setCurrentPage(page);
    };

  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = tableData?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


  
  return (
    <div className="container mt-4 px-0">
      <table className="table table-bordered-bottom">
        <thead>
          <tr>
            <th>Transaction Id</th>
            <th>Date</th>
            <th>Amount</th>
            <th className='text-center'>Remarks</th>
            <th colSpan={2}>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-data'>
          {
            currentItems.map( (Item, index) => {
              const {TransactionId, date, amount, type, status, action, remarks} = Item;
              return(
                <tr key={index}>
                    <td className='pl-5'>{TransactionId}</td>
                    <td className='pr-3'>{date}</td>
                    <td className={status === 'Successful' ? 'green-color pr-3' : 'grey-color pr-3'}>{amount}</td>
                    <td className='text-center' align='left'>
                      {
                        status === 'Successful' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : (
                          <ButtonStatus
                            img='../assets/circle-red.svg'
                            content='Rejected'
                            className=''
                            status='Rejected'
                          />
                        )
                      }
                    </td>
                    <td className='text-left' align='left' colSpan={2}>
                        {
                            status !== 'Successful' && <p className='mb-0 faded'>Amount not received due to network issue</p>
                        }
                    </td>
                    <td className='action'>
                      {
                        status === 'Successful' ? ( 
                          <>
                          <button className="">
                            <img src='../assets/eye-orange.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-orange.svg' />
                          </button>
                          </>
                        ) : (
                          <>
                          <button className="">
                            <img src='../assets/eye-grey.svg' />
                          </button>
                          <button className="">
                            <img src='../assets/download-grey.svg' />
                          </button>
                          </>
                        )
                      }
                    </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className='pagination'>
        {
          totalPages > 1 && <Pagination 
              totalPages={totalPages} 
              currentPage={currentPage} 
              onPageChange={handlePageChange} 
          />
        }
      </div>
    </div>
  );
};

export default RechargeTransactionTable;
