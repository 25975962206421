import React, {useState} from 'react'
import InputField from '../../../components/Buttons/InputField'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import { Col, Row } from 'reactstrap'


const OcupationInforUpdate = ({setOccupationUpdate}) => {
    const [formData, setFormData] = useState({
        companyName: '',
        since: '',
        dac: '',
    })

    const handleSubmit = () => {
        setOccupationUpdate(false);
    }

    const handleCancel = () => {
        setOccupationUpdate(false);
    }


  return (
    <div className='profile-form px-0 w-100'>
        <Col md={12} className='form-inputs px-0'>
            <Row>
                <Col md={12}>
                    <InputField
                        name="companyName"
                        type="text"
                        label="Company name"
                        placeholder="Enter Company Name"
                        requi={false}
                        value={formData.companyName}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <InputField
                        name="since"
                        type="text"
                        label="Since"
                        placeholder="Enter Start Year"
                        requi={false}
                        value={formData.since}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={6} className='mt-4'>
                    <InputField
                        name="dac"
                        type="text"
                        label="Daily cash capacity"
                        placeholder="Enter Daily cash capacity"
                        requi={false}
                        value={formData.dac}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                <Col md={12} className='d-flex justify-content-end mt-5 mb-4'>
                    <ButtonComponent className='w-100' onClick={handleCancel} btnType='bordered'>
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent className='w-100 ml-2' onClick={handleSubmit}>
                        Save
                    </ButtonComponent>
                </Col>
            </Row>
        </Col>
    </div>
  )
}

export default OcupationInforUpdate