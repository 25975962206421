import React from 'react'

const TellerIcon = ({color, className}) => {
  return (
    <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M0.236019 24C-0.062931 23.7843 -0.0197268 23.4872 0.0534428 23.1976C0.226263 22.5145 0.657615 22.0445 1.34402 21.8193C1.51196 21.7636 1.57049 21.6972 1.57049 21.5208C1.56585 14.5058 1.56585 9.49058 1.57049 2.47512C1.57049 2.29605 1.50429 2.23704 1.33984 2.17938C0.568418 1.90806 0.114766 1.37221 0.0158132 0.573165C-0.0364509 0.136339 0.0987384 0 0.546815 0H17.4567C17.9047 0 18.0413 0.135661 17.9884 0.572487C17.8908 1.37085 17.442 1.91145 16.6643 2.17735C16.4776 2.24111 16.4351 2.32454 16.4351 2.50022C16.4393 9.49849 16.4393 14.4968 16.4351 21.495C16.4351 21.6904 16.5006 21.7609 16.6866 21.8274C17.4365 22.094 17.8713 22.623 17.9884 23.397C18.026 23.6425 17.9772 23.8447 17.7703 24H0.236019ZM17.1257 23.2288C16.9424 22.7234 16.5403 22.4772 15.9152 22.4765H3.73353C3.63816 22.4836 3.54226 22.4798 3.44782 22.465C3.36026 22.4472 3.28175 22.4004 3.2256 22.3326C3.16946 22.2648 3.13914 22.1802 3.1398 22.0931C3.14046 22.006 3.17205 21.9219 3.22921 21.8549C3.28637 21.7879 3.36558 21.7422 3.45339 21.7257C3.54802 21.7127 3.64376 21.7093 3.7391 21.7155H15.6358V7.86917H2.35027V13.4487C2.35027 16.9266 2.35027 18.4045 2.35027 21.8823C2.35027 22.3571 2.29243 22.4128 1.81927 22.4833C1.36771 22.5511 1.04507 22.7852 0.86249 23.2261L17.1257 23.2288ZM15.6448 2.30487H2.367V7.07284H15.6448V2.30487ZM0.871549 0.766482C1.06249 1.2786 1.46318 1.52279 2.08756 1.52279H15.9103C15.9884 1.52279 16.0671 1.52279 16.1452 1.51736C16.6163 1.47666 17.0197 1.16464 17.1124 0.766482H0.871549Z" fill={color}/>
        <path d="M8.9892 16.2809C7.22383 16.2809 5.45847 16.2809 3.69311 16.2809C3.24991 16.2809 3.13075 16.1697 3.13075 15.7464C3.13075 12.5484 3.13075 13.3503 3.13075 10.1518C3.13075 9.72722 3.24921 9.61733 3.69241 9.61665H14.3097C14.7529 9.61665 14.872 9.7279 14.8727 10.1512C14.8727 13.3491 14.8727 12.5473 14.8727 15.7457C14.8727 16.169 14.7522 16.2809 14.3104 16.2809C12.5366 16.2818 10.7629 16.2818 8.9892 16.2809ZM12.5167 10.3994H5.49726V15.4988H12.5167V10.3994ZM3.93771 10.3879V15.5043H4.67916V10.3879H3.93771ZM14.07 15.5104V10.3947H13.3299V15.5104H14.07Z" fill={color}/>
        <path d="M9.00244 19.3272C8.49861 19.3272 7.99479 19.3272 7.49096 19.3272C6.78226 19.3224 6.26241 18.8334 6.26659 18.1788C6.27077 17.5242 6.78923 17.0454 7.50351 17.0433C8.50279 17.0406 9.50162 17.0406 10.5 17.0433C11.2149 17.0433 11.7334 17.5242 11.7376 18.1788C11.7418 18.8334 11.2184 19.3272 10.4881 19.3272C9.99336 19.3272 9.4979 19.3272 9.00244 19.3272ZM9.00244 17.8037C8.50767 17.8037 8.01291 17.8037 7.51884 17.8037C7.2171 17.8037 7.04498 17.9543 7.05194 18.1951C7.05891 18.4244 7.22755 18.5634 7.51674 18.5641C8.50535 18.5641 9.49465 18.5641 10.4846 18.5641C10.785 18.5641 10.9585 18.4128 10.9515 18.172C10.9446 17.9434 10.7759 17.8057 10.4867 17.8044C9.99267 17.8024 9.4972 17.8037 9.00244 17.8037Z" fill={color}/>
        <path d="M3.13911 4.50305C3.13926 4.27805 3.20783 4.05812 3.33619 3.87099C3.46454 3.68386 3.64694 3.53791 3.86039 3.45153C4.07383 3.36515 4.30877 3.34221 4.53559 3.3856C4.76241 3.42899 4.97096 3.53677 5.13494 3.69535C5.29893 3.85394 5.41101 4.05622 5.45707 4.27672C5.50313 4.49721 5.48109 4.72604 5.39374 4.93436C5.30639 5.14268 5.15764 5.32117 4.96624 5.44733C4.77483 5.57349 4.54934 5.64167 4.31819 5.64328C4.16369 5.64435 4.0105 5.61565 3.86745 5.55882C3.7244 5.50199 3.59432 5.41816 3.48471 5.31217C3.3751 5.20617 3.28814 5.0801 3.22882 4.94124C3.16951 4.80237 3.13902 4.65344 3.13911 4.50305ZM4.69449 4.50305C4.69447 4.42849 4.67162 4.35563 4.62886 4.29377C4.5861 4.23191 4.52538 4.18385 4.45443 4.15574C4.38349 4.12763 4.30554 4.12073 4.23056 4.13593C4.15557 4.15114 4.08693 4.18775 4.03342 4.24109C3.9799 4.29444 3.94393 4.36209 3.9301 4.43543C3.91627 4.50876 3.92521 4.58444 3.95577 4.65281C3.98633 4.72118 4.03713 4.77913 4.10168 4.81927C4.16623 4.8594 4.24161 4.87991 4.31819 4.87815C4.41823 4.87519 4.51322 4.83472 4.58334 4.76521C4.65346 4.69569 4.69328 4.6025 4.69449 4.50509V4.50305Z" fill={color}/>
        <path d="M8.61011 4.49966C8.60997 4.72472 8.54136 4.9447 8.41294 5.13186C8.28453 5.31902 8.10205 5.46497 7.88852 5.55132C7.675 5.63766 7.43998 5.66052 7.21313 5.61702C6.98627 5.57352 6.77772 5.46561 6.61379 5.30689C6.44986 5.14817 6.33788 4.94576 6.29198 4.72517C6.24608 4.50459 6.26831 4.27572 6.35588 4.06742C6.44344 3.85912 6.59241 3.68072 6.784 3.55472C6.97559 3.42873 7.20121 3.36077 7.43243 3.35943C7.58684 3.35845 7.73992 3.38722 7.88285 3.4441C8.02578 3.50098 8.15573 3.58483 8.26521 3.69083C8.37469 3.79682 8.46153 3.92287 8.52072 4.06169C8.57992 4.2005 8.6103 4.34936 8.61011 4.49966ZM7.82406 4.48881C7.82065 4.41557 7.79522 4.34491 7.75094 4.28561C7.70665 4.22631 7.64545 4.18097 7.57493 4.15521C7.50441 4.12945 7.42768 4.12441 7.35424 4.14072C7.28081 4.15702 7.21391 4.19396 7.16184 4.24693C7.10977 4.29991 7.07483 4.3666 7.06134 4.43873C7.04786 4.51086 7.05643 4.58526 7.08598 4.65269C7.11554 4.72013 7.16479 4.77763 7.22761 4.81807C7.29044 4.85851 7.36408 4.88011 7.4394 4.88019C7.49139 4.88004 7.54281 4.8697 7.5906 4.84977C7.63839 4.82985 7.68156 4.80075 7.71753 4.76421C7.7535 4.72768 7.78154 4.68446 7.79996 4.63714C7.81839 4.58982 7.82612 4.53937 7.82406 4.48881Z" fill={color}/>
        <path d="M10.5495 5.64328C10.3175 5.63977 10.0918 5.56941 9.90088 5.4411C9.70998 5.31278 9.56243 5.13227 9.47688 4.92236C9.39133 4.71246 9.37162 4.48258 9.42024 4.26177C9.46885 4.04096 9.58362 3.83914 9.75004 3.6818C9.91645 3.52446 10.127 3.41866 10.3552 3.37777C10.5834 3.33688 10.8189 3.36274 11.0319 3.45208C11.245 3.54142 11.4261 3.69023 11.5523 3.8797C11.6785 4.06918 11.7442 4.29082 11.7411 4.51662C11.7393 4.66688 11.707 4.81531 11.646 4.95337C11.5851 5.09144 11.4967 5.21641 11.3859 5.32112C11.2752 5.42582 11.1443 5.50818 11.0007 5.56347C10.8572 5.61876 10.7038 5.64588 10.5495 5.64328ZM10.5599 4.12252C10.4821 4.12362 10.4064 4.14739 10.3425 4.19077C10.2787 4.23414 10.2297 4.29514 10.2018 4.36588C10.174 4.43663 10.1685 4.51388 10.1862 4.58767C10.2038 4.66147 10.2438 4.72842 10.3009 4.77989C10.358 4.83137 10.4297 4.865 10.5066 4.87646C10.5836 4.88792 10.6623 4.87668 10.7326 4.84418C10.8029 4.81169 10.8616 4.75943 10.9011 4.69416C10.9407 4.62888 10.9592 4.55357 10.9543 4.47795C10.9488 4.38073 10.9047 4.2894 10.8314 4.22312C10.7581 4.15685 10.6613 4.12079 10.5613 4.12252H10.5599Z" fill={color}/>
        <path d="M14.8727 4.49898C14.8727 4.72416 14.8042 4.94429 14.6758 5.13158C14.5473 5.31888 14.3648 5.46493 14.1512 5.55132C13.9375 5.63771 13.7024 5.66055 13.4754 5.61697C13.2485 5.57338 13.0398 5.46532 12.8759 5.30643C12.712 5.14753 12.6001 4.94493 12.5544 4.72419C12.5087 4.50345 12.5312 4.27448 12.619 4.06617C12.7069 3.85787 12.8562 3.67957 13.0481 3.55378C13.2399 3.428 13.4658 3.36037 13.6971 3.35943C13.8513 3.35881 14.0041 3.38782 14.1467 3.44481C14.2893 3.5018 14.4189 3.58564 14.5281 3.69151C14.6373 3.79739 14.724 3.92323 14.7832 4.06179C14.8423 4.20036 14.8727 4.34893 14.8727 4.49898ZM14.0867 4.4922C14.084 4.41893 14.0592 4.34805 14.0154 4.28836C13.9717 4.22866 13.9109 4.18279 13.8406 4.15641C13.7703 4.13004 13.6936 4.12434 13.6201 4.14001C13.5465 4.15568 13.4792 4.19203 13.4267 4.24456C13.3741 4.29709 13.3386 4.36349 13.3244 4.4355C13.3103 4.50752 13.3182 4.58199 13.3472 4.64968C13.3761 4.71737 13.4248 4.7753 13.4873 4.81628C13.5498 4.85725 13.6232 4.87947 13.6985 4.88019C13.7504 4.88021 13.8017 4.8701 13.8495 4.85048C13.8973 4.83086 13.9406 4.80212 13.9768 4.76595C14.0129 4.72978 14.0413 4.68693 14.0602 4.63991C14.0791 4.59289 14.0881 4.54266 14.0867 4.4922Z" fill={color}/>
    </svg>
  )
}

export default TellerIcon