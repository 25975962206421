import React from 'react'

const AccountTypeIcon = ({color}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.15785 11.1055H4.3684C3.47504 11.1055 2.61828 11.4604 1.98658 12.0921C1.35488 12.7237 1 13.5805 1 14.4739V16.1581" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16.4761 12.4746C16.8116 12.1391 17.0001 11.6842 17.0001 11.2098C17.0001 10.7354 16.8116 10.2804 16.4761 9.94493C16.1407 9.60948 15.6857 9.42102 15.2113 9.42102C14.7369 9.42102 14.2819 9.60948 13.9465 9.94493L10.5697 13.3234C10.3694 13.5235 10.2229 13.7709 10.1436 14.0426L9.43872 16.4594C9.41758 16.5319 9.41632 16.6087 9.43505 16.6818C9.45378 16.7549 9.49182 16.8217 9.5452 16.875C9.59857 16.9284 9.66531 16.9665 9.73843 16.9852C9.81155 17.0039 9.88836 17.0027 9.96082 16.9815L12.3776 16.2767C12.6494 16.1973 12.8967 16.0508 13.0968 15.8506L16.4761 12.4746Z" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.89464 7.7368C8.75496 7.7368 10.263 6.22871 10.263 4.3684C10.263 2.50808 8.75496 1 6.89464 1C5.03433 1 3.52625 2.50808 3.52625 4.3684C3.52625 6.22871 5.03433 7.7368 6.89464 7.7368Z" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default AccountTypeIcon