import React from 'react'
import TellerAllListingTable from './TellerAllListingTable'
import { tableData } from '../TransactionTableData'
import { dataTable } from './dataTable'

const AllListing = () => {
  return (
    <div>
        <TellerAllListingTable tableData={tableData} dataTable={dataTable} />
    </div>
  )
}

export default AllListing