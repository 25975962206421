import React from 'react'

const Tooltip = ({children}) => {
  return (
    <div className='tooltip'>
        {children}
    </div>
  )
}

export default Tooltip