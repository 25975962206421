import React, {useState} from 'react';
import ButtonStatus from '../ButtonStatus';
import Pagination from '../../Dashboard/Recharge/Pagination';
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import { CiSearch } from 'react-icons/ci';
import { Col, Row } from 'reactstrap';
import DropdownCustom from '../../../components/Buttons/DropdownCustom';
import { useClientType } from '../../../ContextApi/ClientTypeContext';
import FilterPopup from './FilterPopup';


const MoneyTransferTable = ({tableData, isHistory, activeTab, openFilter, setOpenFilter, handleApply}) => {
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const {clientType} = useClientType();
  const itemsPerPage = 8;
  const [optionData, setOptionData] = useState();
  // const [openFilter, setOpenFilter] = useState(false);

  console.log('The client thype is - ' + clientType);

  const handleCloseFilter = () => {
    setOpenFilter(prev => !prev);
  }
  
  const handleChange = (e) => {
    setSearch(e.target.value);
  }

  const options = ['Latest', 'Low to High', 'High to Low'];
  const filterOption = ['Status-all', 'Status'];
  const typeOption = ['All', 'Received', 'Sent'];

  const handleSelect = (e) => {
      setOptionData(e.target.value)
  };

  const handleFilterOpen = () => {
    setOpenFilter(prev => !prev);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const filteredData = tableData.filter(item => {
    if (filter === 'All') return true;
    if (filter === 'Recharge') return item.type === 'Recharge';
    if (filter === 'Sent') {
      return item.type === 'Sent' || item.type === 'Sent (online)';
    }
    return filter === 'Received' ? item.type === 'Received' : false;
  });
  

  const totalPages = (clientType === 'associate' || clientType === 'agency') ? Math.ceil(filteredData.length / itemsPerPage) : Math.ceil(tableData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = (clientType === 'associate' || clientType === 'agency') ? filteredData.slice(startIndex, startIndex + itemsPerPage) : tableData.slice(startIndex, startIndex + itemsPerPage);

  
  return (
    <div className="container mt-4 px-0 transfer-table">
      <Row className='mx-0 mr-0 ml-0 mb-3'>
          <Col md={12} className='px-0'>   
          {
            activeTab !== 'history' && <div className='dashboard-table-title'>
            <p className='mb-0'>Recent Transaction</p>
        </div>
          }     
          </Col>
          {
            (clientType === 'associate' || clientType === 'agency') && isHistory === true && (
              <>
                  <Col md={4} className='pl-0 filter-btn-group d-flex alitn-items-end'>
                    <button className={`btn btn-border ${filter === 'All' ? 'active' : ''}`} onClick={() => handleFilterChange('All')}>All</button>
                    <button className={`btn btn-border ${filter === 'Sent' ? 'active' : ''}`} onClick={() => handleFilterChange('Sent')}>Sent</button>
                    <button className={`btn btn-border ${filter === 'Received' ? 'active' : ''}`} onClick={() => handleFilterChange('Received')}>Received</button>
                    <button className={`btn btn-border ${filter === 'Recharge' ? 'active' : ''}`} onClick={() => handleFilterChange('Recharge')}>Recharge</button>
                </Col>
              </>
            )
          }
          <Col md={(clientType === 'associate' || clientType === 'agency') && isHistory === true ? 3 : 4} className='mt-3 px-0'>
              <div className='search-group d-flex align-items-center'>
                  <CiSearch />
                  <input className='p-2' placeholder='Search' type='text' name='search' value={search} onChange={handleChange}/>
              </div>
          </Col>
          <Col className={`${(clientType === 'associate' || clientType === 'agency') && isHistory === true ? 'd-flex justify-content-end align-items-end col-md-5' : 'd-flex justify-content-end align-items-end col-md-8'}`}>
            <div  className={`${(clientType === 'associate' || clientType === 'agency') && isHistory === true ? 'border-sorting ' : 'px-0 d-flex justify-content-end mr-2'}`}>
                      <div className='sort d-flex align-items-center'>
                          <img src='../assets/sort.svg'alt='img' />
                          <label htmlFor='sort' className='mb-0 pl-2'>Sort By:</label>
                          <DropdownCustom 
                              className='dashboard-drop dark-text pl-2'
                              name="sort"
                              title=""div
                              isRequired = {false}
                              options={options}
                              onSelect={handleSelect}
                              defaultOption='Latest'
                              value={optionData}
                          />
                      </div>
            </div>
            <div className={`${(clientType === 'associate' || clientType === 'agency') && isHistory === true ? 'border-sorting ' : 'px-0 d-flex justify-content-end'}`}>
                <div className='sort filter d-flex align-items-center' onClick={(clientType === 'associate' || clientType === 'agency') && handleFilterOpen}>
                    <img src='../assets/filter.svg'alt='img' />
                    <label htmlFor='sort' className='mb-0 pl-2'>Filter By:</label>
                    <DropdownCustom 
                        className='dashboard-drop dark-text pl-2'
                        name="sort"
                        title=""
                        isRequired = {false}
                        options={filterOption}
                        onSelect={handleSelect}
                        defaultOption={(clientType === 'associate' || clientType === 'agency') && isHistory === true ? <span className='counter'>1</span> : 'Status-all'}
                        value={optionData}
                    />
                </div>
                { openFilter === true && (clientType === 'associate' || clientType === 'agency') && isHistory === true && 
                  <FilterPopup onClick={handleCloseFilter} openFilter={openFilter} setOpenFilter={setOpenFilter} handleApply={handleApply} /> 
                }
            </div>
            {
              (clientType !== 'associate' || clientType !== 'agency') && clientType === 'normal' && (
                <>
                  <div  className='px-0 d-flex justify-content-end'>
                    <div className='sort filter d-flex align-items-center'>
                        <label htmlFor='sort' className='mb-0 pl-2'>Type:</label>
                        <DropdownCustom 
                            className='dashboard-drop dark-text pl-2'
                            name="sort"
                            title=""
                            isRequired = {false}
                            options={typeOption}
                            onSelect={handleSelect}
                            defaultOption='All'
                            value={optionData}
                        />
                    </div>
                  </div>
                </>
              )
            }
          </Col>
      </Row>
      <div className='table-responsive'>
      <table className="table table-bordered-bottom">
        <thead>
          <tr>
            <th>Type</th>
            <th>{ (clientType === 'associate' || clientType === 'agency') && isHistory === true ? <>Id</> : <>Transaction Id</> }</th>
            <th>Date</th>
            <th>Amount</th>
            {
              (clientType === 'associate' || clientType === 'agency') && isHistory === true ? (
                <>
                  <th>{filter === 'Received' ? <>Location</> : <>Sender</>}</th>
                  <th>Sender Agency</th>
                  <th>Receiver</th>
                  {filter !== 'Received' && <th>Receiver Agency</th>}
                  <th className='text-center'>Status</th>
                </>
              ) : (clientType === 'associate' || clientType === 'agency') ? (
                <>
                  <th>Receiver</th>
                  <th>Sender</th>
                  <th>Time</th>
                  <th className='text-center'>Status</th>
                  <th>Actions</th>
                </>
              ) : (
                <>
                  <th>Payment Method</th>
                  <th>Activity</th>
                  <th className='text-center'>Status</th>
                </>
              )
            }
          </tr>
        </thead>
        <tbody className='table-data'>
        {
          currentItems.map( (Item, index) => {
            const {TransactionId, date, amount, type, status, paymentMethod, activity, receiver, sender, receiverAgency, senderAgency, time} = Item;
            return(
              <tr key={index}>
                {
                  (clientType === 'associate' || clientType === 'agency') ? (
                    <>
                      <td className=''>{['Sent', 'Sent (online)'].includes(type) ? (
                          <>
                            {type}
                          </>
                      ) : type === 'Recharge' ? (
                          <>
                            Recharge
                          </>
                      ) : (
                          <>
                            Received
                          </>
                      )}</td>
                    </>
                  ) : (
                    <>
                      <td className=''>{type === 'Sent' ? (
                        <>
                          <BsArrowUp className='red' /> Sent
                        </>
                        ) : (
                          <>
                            <BsArrowDown className='green' /> Received
                          </>
                        )}
                      </td>
                    </>
                  )
                }
                <td className=''>{TransactionId}</td>
                <td className='pr-3'>{date}</td>
                <td className={type === 'Sent' ? 'red-color pr-3' : 'green-color pr-3'}>{amount}</td>
                {
                  (clientType === 'associate' || clientType === 'agency') && isHistory === true ? (<>
                    <td className={`${sender === null && 'text-center'}`}>{sender !== null ? sender : '-'}</td>
                    <td className={`${senderAgency === null && 'text-center'}`}>{senderAgency !== null ? senderAgency : '-'}</td>
                    <td className={`${receiver === null && 'text-center'}`}>{receiver !== null ? receiver : '-'}</td>
                    {
                      filter !== 'Received' && <td className={`${receiverAgency === null && 'text-center'}`}>{receiverAgency !== null ? receiverAgency : '-'}</td>
                    }
                    <td className='' align=''>
                      {
                        status === 'Successful' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : status === 'Rejected' ? (
                            <ButtonStatus
                              img='../assets/circle-red.svg'
                              content='Rejected'
                              className=''
                              status='Rejected'
                            />
                          ) : status === 'Pending' ? (
                            <ButtonStatus
                              img='../assets/circle-olive.svg'
                              content='Pending'
                              className=''
                              status='Pending'
                            />
                          ) : (
                          <ButtonStatus
                            img='../assets/circle-orange.svg'
                            content='Incomplete'
                            className=''
                            status='Incomplete'
                          />
                        )
                      }
                    </td>
                  </>) : (clientType === 'associate' || clientType === 'agency') ?  (<>
                    <td className={`${!receiver && 'text-center'}`}>{receiver ? receiver : '-'}</td>
                    <td className={`${!sender && 'text-center'}`}>{sender ? sender : '-'}</td>
                    <td className=''>{time}</td>
                    <td className='text-center' align='left'>
                      {
                        status === 'Successful' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : status === 'Failed' ? (
                            <ButtonStatus
                              img='../assets/circle-red.svg'
                              content='Failed'
                              className=''
                              status='Failed'
                            />
                          ) : (
                          <ButtonStatus
                            img='../assets/circle-orange.svg'
                            content='Incomplete'
                            className=''
                            status='Incomplete'
                          />
                        )
                      }
                    </td>
                    <td className='action'>
                        {
                          status === 'Successful' ? ( 
                            <>
                            <button className="">
                              <img src='../assets/eye-orange.svg'  alt='img'/>
                            </button>
                            <button className="">
                              <img src='../assets/download-orange.svg' alt='img' />
                            </button>
                            </>
                          ) : (
                            <>
                            <button className="">
                              <img src='../assets/eye-grey.svg' alt='img' />
                            </button>
                            <button className="">
                              <img src='../assets/download-grey.svg' alt='img' />
                            </button>
                            </>
                          )
                        }
                    </td>
                  </>) : (<>
                    <td>{paymentMethod}</td>
                    <td>{type === 'Sent' ? <>Sending to <span className='orange'>{activity}</span></> 
                        : <>Received money from <span className='orange'>{activity}</span></>}
                    </td>
                    <td className='' align=''>
                      {
                        status === 'Successful' ? (
                          <ButtonStatus
                            img='../assets/circle-green.svg'
                            content='Successful'
                            className=''
                            status='Successful'
                          />
                        ) : status === 'Rejected' ? (
                            <ButtonStatus
                              img='../assets/circle-red.svg'
                              content='Rejected'
                              className=''
                              status='Rejected'
                            />
                          ) : status === 'Pending' ? (
                            <ButtonStatus
                              img='../assets/circle-olive.svg'
                              content='Pending'
                              className=''
                              status='Pending'
                            />
                          ) : (
                          <ButtonStatus
                            img='../assets/circle-orange.svg'
                            content='Incomplete'
                            className=''
                            status='Incomplete'
                          />
                        )
                      }
                    </td>
                  </>)
                }
              </tr>
            )
          })
        }
        </tbody>
      </table></div>
      {
        totalPages > 1 && 
          <div className='pagination'>
            <Pagination 
                totalPages={totalPages} 
                currentPage={currentPage} 
                onPageChange={handlePageChange} 
                className='mt-2'
            />
          </div>
      }
    </div>
  );
};

export default MoneyTransferTable;
