import axiosInstanceAgency from "../../api/agency";
import axiosInstanceClient from "../../api/client";
import { baseURL } from "../../config/filepath";

const GetDashboard = (payload) => {
  const data = { chartDuration: payload };
  return axiosInstanceClient
    .post(baseURL + "client/get-dashboard-data", data)
    .then((response) => {
      return response;
    });
};

const Gettransaction = (payload) => {
  return axiosInstanceClient
    .post(baseURL + "client/get-transactions-for-dashboard", payload)
    .then((response) => {
      return response;
    });
};

const GetAgencyDashboard = (payload) => {
  const data = { chartDuration: payload };
  return axiosInstanceAgency
    .post(baseURL + "agency/get-dashboard-data", data)
    .then((response) => {
      return response;
    });
};
const getTransferAgencyTransaction = (payload) => {
  return axiosInstanceAgency
    .post(baseURL + `agency/get-transactions-for-dashboard`, payload)
    .then((response) => {
      return response.data;
    });
};
// 
export default {
  GetDashboard,
  Gettransaction,
  GetAgencyDashboard,
  getTransferAgencyTransaction,
};
