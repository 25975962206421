import React, {useState} from 'react'
import { Col, Row } from 'reactstrap'
import InputField from '../../../components/Buttons/InputField'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import { CiSearch } from 'react-icons/ci';
import DropdownCustom from '../../../components/Buttons/DropdownCustom';


const RegisteredUserForm = ({step, setStep, isOpen, setIsOpen}) => {
    const [formData, setFormData] = useState({
        name: '',
        currency: '',
        amount: '', 
        reason: '',
    })
    const [search, setSearch] = useState();

    const currency = ['INR', 'USD', 'ASD', 'YEN'];

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSelect = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const nextStep = () => {
        setStep(step + 1);
    }
    const prevStep = () => {
        if(step === 0 || step === 1){
            return setStep(1);
        }
        setStep(step - 1);
        console.log(step);
    }

    const handleSubmit = () => {
        setIsOpen(prevState => !prevState);
    }

    const renderContent = () => {
        {
            switch(step){
                case 1:
                return(
                    <>
                        <Col md={12} className='form-inputs mt-2'>
                            <Row>
                                <Col md={12}>
                                    <div className='search-group w-100'>
                                        <label htmlFor='textfield mb-0'>Recipient's email address/full name
                                            <span className='text-danger'>*</span>
                                        </label>
                                        <div className='d-flex align-items-center  align-items-center w-100'>
                                            <CiSearch />
                                            <input 
                                                name='textfield'
                                                className='py-2 px-1' 
                                                placeholder='Enter email/full name' 
                                                type='text' 
                                                value={search} 
                                                onChange={handleFormChange}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <div className='d-flex justify-content-end w-100 mt-4'>
                            <Col md={5} className='d-flex'>
                                <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                    Cancel
                                </ButtonComponent>
                                <ButtonComponent className='w-100' onClick={nextStep}>
                                    Next
                                    <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                </ButtonComponent>
                            </Col>
                        </div>
                    </>
                )
                case 2:
                return(
                    <>
                        <Col md={12} className='form-inputs mt-0'>
                            <Row>
                                <Col md={6}>
                                    <DropdownCustom 
                                        name="currency"
                                        title="Enter Currency"
                                        options={currency}
                                        onSelect={handleSelect}
                                        defaultOption='Select Currency'
                                        value={formData.gender}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputField
                                        name="amount"
                                        type="text"
                                        label="Enter Amount"
                                        placeholder="Enter Amount"
                                        required
                                        value={formData.amount}
                                        onChange={handleFormChange}
                                    />
                                </Col>
                                <Col md={12} className='mt-3'>
                                    <InputField
                                        name="reason"
                                        type="text"
                                        label="Reason for sending"
                                        placeholder="Type Reason"
                                        required
                                        value={formData.reason}
                                        onChange={handleFormChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <div className='d-flex justify-content-end w-100 mt-5'>
                            <Col md={5} className='d-flex'>
                                <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                    <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                    Back
                                </ButtonComponent>
                                <ButtonComponent className='w-100' onClick={nextStep}>
                                    Next
                                    <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                </ButtonComponent>
                            </Col>
                        </div>
                    </>
                )
                case 3:
                return(
                    <>
                        <Col md={12} className='form-inputs preview-wrapper mt-0'>
                            <div>
                                <p className='title-top'>Preview</p>
                                <div className='d-flex'>
                                    <div className='item'>
                                        <p className='title mb-0'>Recipient type</p>
                                        <p className='description mb-0 colored'>Registered</p>
                                    </div>
                                    <div className='item'>
                                        <p className='title mb-0'>Recipient details</p>
                                        <p className='description text mb-0'>Samuel Bennett</p>
                                        <p className='mail-id mb-0'>samuel@gmail.com</p>
                                    </div>
                                    <div className='item'>
                                        <p className='title mb-0'>Amount</p>
                                        <p className='description text mb-0'>$100.00</p>
                                    </div>
                                    <div className='item'>
                                        <p className='title mb-0'>Reason for sending</p>
                                        <p className='description text mb-0'>
                                            Reimbursement for travel expenses incurred during the conference.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className='d-flex justify-content-end w-100 mt-5'>
                            <Col md={5} className='d-flex'>
                                <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                    <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                    Back
                                </ButtonComponent>
                                <ButtonComponent className='w-100' onClick={handleSubmit}>
                                    Send Money
                                    <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                </ButtonComponent>
                            </Col>
                        </div>
                    </>
                )
                default:
                return null
            }
        }
    }


  return (
    <div className='w-100 registered-user register-form-mt mt-2'>
        {renderContent()}
    </div>
  )
}

export default RegisteredUserForm