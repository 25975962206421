import React, { useMemo, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Collapse, NavbarBrand, Navbar, Nav } from "reactstrap";
import {
  LuBarChart,
  LuBarChartHorizontalBig,
  LuBuilding,
  LuLayoutDashboard,
  LuListChecks,
  LuMap,
  LuSettings,
  LuUserCircle,
  LuUsers2,
  LuWallet,
} from "react-icons/lu";
import { LuBadgeDollarSign } from "react-icons/lu";
import Logo from "../../assets/images/logo.webp";
import { useTranslation } from "react-i18next";
const AdminSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = useState();

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const { t } = useTranslation();

  const adminRoute = useMemo(
    () => [
      {
        title: t("dashboard"),
        icon: <LuLayoutDashboard />,
        path: "/admin/dashboard",
      },
      {
        title: t("clientManagement"),
        icon: <LuBadgeDollarSign />,
        path: "/admin/clients/list",
      },
      {
        title: t("adminManagement"),
        icon: <LuUserCircle />,
        path: "/admin/admins/list",
      },
      {
        title: t("permissionManagement"),
        icon: <LuSettings />,
        path: "/admin/permission-management",
      },
      {
        title: t("roleManagement"),
        icon: <LuUsers2 />,
        path: "/admin/role-management",
      },
      {
        title: t("commissionManagement"),
        icon: <LuBadgeDollarSign />,
        path: "/admin/commission/default-management",
      },
      {
        title: t("walletManagement"),
        icon: <LuWallet />,
        path: "/admin/recharge-management",
      },
      {
        title: t("reportManagement"),
        icon: <LuBarChartHorizontalBig />,
        path: "/admin/report-management",
      },
      {
        title: t("transactionReport"),
        icon: <LuListChecks />,
        path: "/admin/transaction-report-management",
      },
      {
        title: t("locationManagement"),
        icon: <LuMap />,
        path: "/admin/geolocation-management",
      },
    ],
    [t]
  );

  const handleRoute = (link) => {
    navigate(link);
  };
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light p-0"
      expand="md"
      id="sidenav-main"
    >
      <>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        <NavbarBrand tag={Link} to="/" className="p-0 border-bottom">
          <img
            alt="Dual-Money"
            className="navbar-brand-img mb-2"
            style={{ minHeight: "70px" }}
            height={80}
            width={80}
            src={Logo}
          />
        </NavbarBrand>

        {/* Collapse */}
        <Collapse navbar={true} isOpen={collapseOpen} className="pt-0">
          {adminRoute.map((items, i) => {
            return (
              <Nav
                navbar={true}
                key={i}
                className={`d-flex align-items-baseline fontsize-navbar ${location.pathname.includes(items.path.toString()) && "bg-green-color"}`}
                onClick={() => handleRoute(items.path, null)}
                role="button"
              >
                <div className="list-box d-flex justify-content-between align-items-center m-0 p-0">
                  <div className="d-flex align-items-center">
                    {items.icon}
                    <p className="m-0 p-0 pl-2">{items.title}</p>
                  </div>
                </div>
              </Nav>
            );
          })}
        </Collapse>
      </>
    </Navbar>
  );
};

// AdminSidebar.propTypes = {
//   routes: PropTypes.arrayOf(PropTypes.object),
//   logo: PropTypes.shape({
//     innerLink: PropTypes.string,
//     outterLink: PropTypes.string,
//     imgSrc: PropTypes.string.isRequired,
//     imgAlt: PropTypes.string.isRequired,
//   }),
// };

export default AdminSidebar;
