import React from 'react'

const MessageIcon = ({color, className}) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M5.66797 8.0002H5.6763M9.0013 8.0002H9.00964M12.3346 8.0002H12.343M5.58464 14.6669C7.17512 15.4827 9.00471 15.7037 10.7437 15.29C12.4827 14.8763 14.0168 13.8551 15.0694 12.4104C16.1221 10.9656 16.6242 9.19246 16.4851 7.41034C16.3461 5.62821 15.5751 3.95434 14.3111 2.69036C13.0472 1.42638 11.3733 0.655411 9.59117 0.516382C7.80904 0.377353 6.03586 0.879407 4.59115 1.93207C3.14644 2.98474 2.12521 4.51879 1.71149 6.2578C1.29776 7.9968 1.51875 9.82639 2.33464 11.4169L0.667969 16.3335L5.58464 14.6669Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default MessageIcon