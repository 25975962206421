import React, { useState } from 'react';


const DayCheckbox = ({ label, className, checked = false, onChange, disabled = false, value }) => {

    return (
        <div className={`checkbox-container ${checked ? 'checked' : ''}`} onClick={!disabled ? onChange : undefined}>
            <span className="checkbox-text">{label}</span>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className={className}
                disabled={disabled}
                value={value}
            />
        </div>
    );
};

export default DayCheckbox;
