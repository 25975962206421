import React from 'react'

const PreviewFocus = ({color}) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4.77778V2.88889C1 2.38792 1.19901 1.90748 1.55324 1.55324C1.90748 1.19901 2.38792 1 2.88889 1H4.77778M14.2222 1H16.1111C16.6121 1 17.0925 1.19901 17.4468 1.55324C17.801 1.90748 18 2.38792 18 2.88889V4.77778M18 14.2222V16.1111C18 16.6121 17.801 17.0925 17.4468 17.4468C17.0925 17.801 16.6121 18 16.1111 18H14.2222M4.77778 18H2.88889C2.38792 18 1.90748 17.801 1.55324 17.4468C1.19901 17.0925 1 16.6121 1 16.1111V14.2222M13.2778 13.2778L11.4833 11.4833M12.3333 9.5C12.3333 11.0648 11.0648 12.3333 9.5 12.3333C7.93519 12.3333 6.66667 11.0648 6.66667 9.5C6.66667 7.93519 7.93519 6.66667 9.5 6.66667C11.0648 6.66667 12.3333 7.93519 12.3333 9.5Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default PreviewFocus