import React, {useState} from 'react'
import InputField from '../../../components/Buttons/InputField'
import DropdownCustom from '../../../components/Buttons/DropdownCustom'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit' 
import { Col, Row } from 'reactstrap'
import { useClientType } from '../../../ContextApi/ClientTypeContext'


const ProfileUpdate = ({setUpdate}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        gender: '',
        phone: '',
        email: '',
        city: '',
        country: '',
        clientType: '',
        address: '',
    })
    const { clientType } = useClientType();

    const options = ['Normal', 'Associate', 'Agency'];

    const handleSelect = (option) => {
        setFormData(formData.clientType, option)
        console.log('Selected:', option);
    };

    const handleNumberInput = (option) => {
        console.log('Selected:', option);
    }

    const handleSubmit = () => {
        setUpdate(false);
    }

    const handleCancel = () => {
        setUpdate(false);
    }


  return (
    <div className='profile-form'>
        <Col md={12} className='form-inputs'>
            <Row>
                <Col md={clientType === 'option3' ? 12 : 6}>
                    <InputField
                        name="name"
                        type="text"
                        label="Name"
                        placeholder="Enter Name"
                        requi={false}
                        value={formData.name}
                        onChange={(e) => setFormData(e.target.value)}
                    />
                </Col>
                {clientType === 'option3' ?  (
                    <>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="city"
                                type="text"
                                label="City"
                                placeholder="Enter City"
                                imageSrc=""
                                required={false}
                                value={formData.city}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="Country"
                                type="text"
                                label="Country"
                                placeholder="Enter Country"
                                imageSrc=""
                                required={false}
                                value={formData.country}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="phone"
                                type="text"
                                label="Phone"
                                placeholder="Enter Phone Number"
                                required={false}
                                value={formData.phone}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="email"
                                type="email"
                                label="Email"
                                placeholder="Enter Email Address"
                                required={false}
                                value={formData.email}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                    </>
                ) : (
                    <>
                        <Col md={6}>
                            <InputField
                                name="surname"
                                type="text"
                                label="Surname"
                                placeholder="Enter Surname"
                                requi={false}
                                value={formData.surname}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="gender"
                                type="text"
                                label="Gender"
                                placeholder="Enter Gender"
                                requi={false}
                                value={formData.gender}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <DropdownCustom 
                                name="clienttype"
                                title="Client Type"
                                options={options}
                                onSelect={handleSelect}
                                defaultOption='Choose Client Type'
                                value={formData.clientType}
                                isRequired={false}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="phone"
                                type="text"
                                label="Phone"
                                placeholder="Enter Phone Number"
                                required={false}
                                value={formData.phone}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="email"
                                type="email"
                                label="Email"
                                placeholder="Enter Email Address"
                                required={false}
                                value={formData.email}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={12} className='mt-4'>
                            <InputField
                                name="address"
                                type="text"
                                label="Address"
                                placeholder="Enter Address"
                                required={false}
                                value={formData.address}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="city"
                                type="text"
                                label="City"
                                placeholder="Enter City"
                                imageSrc=""
                                required={false}
                                value={formData.city}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className='mt-4'>
                            <InputField
                                name="Country"
                                type="text"
                                label="Country"
                                placeholder="Enter Country"
                                imageSrc=""
                                required={false}
                                value={formData.country}
                                onChange={(e) => setFormData(e.target.value)}
                            />
                        </Col>
                    </>
                )
                }
                <Col md={12} className='d-flex justify-content-end mt-5 mb-4'>
                    <ButtonComponent className='w-100' onClick={handleCancel} btnType='bordered'>
                        Cancel
                    </ButtonComponent>
                    <ButtonComponent className='w-100 ml-2' onClick={handleSubmit}>
                        Save
                    </ButtonComponent>
                </Col>
            </Row>
        </Col>
    </div>
  )
}

export default ProfileUpdate