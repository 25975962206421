
import { AGENCY_TELLER_LIST, AGENCY_TELLER_DETAILS } from "../../types/index";

const initialState = { tellerList: {}, tellerDetails: {} };
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AGENCY_TELLER_LIST:
      return {
        ...state,
        tellerList: payload,
      };
    case AGENCY_TELLER_DETAILS:
      return {
        ...state,
        tellerDetails: payload,
      };

    default:
      return state;
  }
}
