import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

const Signature = ({signatureFile}) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null); // State to store the image

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    // Set up canvas dimensions (you can customize these)
    canvas.width = 400;
    canvas.height = 200;
    context.lineWidth = 1;
    context.lineCap = 'round';
    context.strokeStyle = '#393939';
  }, []);

  const startDrawing = (event) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.beginPath();
    context.moveTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    setIsDrawing(true);
  };

  const draw = (event) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.lineTo(event.nativeEvent.offsetX, event.nativeEvent.offsetY);
    context.stroke();
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
    setSignatureImage(null); // Clear the signature image
    setSaveBtn(false)
  };
  const[saveBtn , setSaveBtn] = useState(false)
  const saveSignature = () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png'); // Get canvas content as Base64 image (PNG format)
    if(image) {
      setSaveBtn(true)
      toast.success('Signature added successfully..')
    }else {
      toast.warning('Please added you signature !')
    }
    setSignatureImage(image); // Store image in state
    signatureFile(image)
  };

  return (
    <div className="container p-0">
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
        // style={{ border: '1px solid #000' }}
      ></canvas>
     <div className="mt-3" style={{position:"absolute", right:"0", zIndex:"99"}}>
        {!saveBtn && (<Button size="sm" onClick={saveSignature} className="btn btn-success"><i className='fa fa-check'></i></Button>)}
        {saveBtn && (<Button size="sm" onClick={clearCanvas} className="btn btn-danger ml-2"><i className='fa fa-trash'></i></Button>)}
      </div>

      {/* Display the signature image if saved */}
      {/* {signatureImage && (
        <div className="mt-3">
          <h5>Saved Signature Image:</h5>
          <img src={signatureImage} alt="Signature" />
        </div>
      )} */}
    </div>
  );
};

export default Signature;
