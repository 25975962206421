import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { LuLayoutDashboard, LuBadgeDollarSign,  LuWallet2, LuMapPin, LuHeartHandshake } from "react-icons/lu";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbAddressBook } from "react-icons/tb";
import DashboardHome from './DashboardHome';
import ReachargeAccount from './Recharge/RechargeAccount';
import ProfileManagement from './ProfileManagement/ProfileManagement';
import Transaction from './Transaction/Transaction';
import MapView from './MapView/MapView';
import InviteUser from './InviteUser/InviteUser';
import Message from './Message/Message';
import MyAccount from '../MyAccount/MyAccount';
import TellerAccountMain from './TellerAccount/TellerAccountMain';
import MessageIcon from '../../components/svg/MessageIcon';
import MyAccountIcon from '../../components/svg/MyAccountIcon';
import TellerIcon from '../../components/svg/TellerIcon';


const Sidebar = ({ onComponentChange, onTitleChange, activeTab, setActiveTab, clientType}) => {
    const [activeLink, setActiveLink] = useState(0);
    const [activeSubLink, setActiveSubLink] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isActiveList, setActiveList] = useState(false);

    const diList = [
        {icon: <LuLayoutDashboard />, text: 'Dashboard', dropdown: false, component: <DashboardHome  />},
        ...(clientType !== 'option3' ? [{icon: <LuBadgeDollarSign />, text: 'Recharge Account', dropdown: false, component: <ReachargeAccount />}] : []),
        ...(clientType !== 'option3' ? [{icon: <TbAddressBook />, text: 'Profile Management', dropdown: false, component: <ProfileManagement />}] : []),
        {icon: <LuWallet2 />, text: 'Transaction', dropdown: true, component: null },
        {icon: <LuMapPin />, text: 'Map View', dropdown: false, component: <MapView />},
    ]

    if (clientType === 'option3') {
        diList.splice(1, 0, { icon: <MyAccountIcon color={activeLink === 1 ? '#fff' : '#EED7B9'} />, text: 'My Account', dropdown: false, component: <MyAccount /> });
    } 
    if (clientType === 'option3') {
        diList.splice(3, 0, {icon: <TellerIcon color={activeLink === 3 ? '#fff' : '#EED7B9'} />, text: 'Teller Account', dropdown: false, component: <TellerAccountMain /> });
    }
    if (clientType === 'option3') {
        diList.splice(4, 0, {icon: <TbAddressBook />, text: 'Profile Management', dropdown: false, component: <ProfileManagement /> });
    }

    if (clientType === 'option2') {
        diList.push({ icon: <MessageIcon color={activeLink === diList.length ? '#fff' : '#EED7B9'} />, text: 'Message', dropdown: false, component: <Message /> });
    } else if (clientType === 'option1') {
        diList.push({ icon: <LuHeartHandshake />, text: 'Invite User', dropdown: false, component: <InviteUser /> });
    } 
    
    const diSubMenuList = [
        {text: 'Money Transfer',  value: 'transfer'},
        {text: 'Transaction History', value: 'history'},
    ]

    const handleItemClick = (component, index, title) => {
        onComponentChange(component);
        setActiveLink(index);
        onTitleChange(title);
        if (title === 'Transaction') {
            setActiveList(true);
        } else {
            setActiveList(false);
        }
    };

    const handleSubItemClick = ( index, value, title) => {
        setActiveSubLink(index);
        onTitleChange(title);
        setActiveTab(value);
        handleItemClick(<Transaction activeTab={value} setActiveTab={setActiveTab} />, 3, 'Transaction'); 
        setActiveList(true);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setActiveLink(3);
    };

  return (
    <>
      <div className='logo d-flex justify-content-center'>
          <Link to='/html/dashboard' className='p-0 m-0'>
              <img src='../assets/logo-dashboard.webp' />
          </Link>
      </div>
      <div className='dashboard-items pt-4'>
          <ul className='m-0 p-0'>
          {
              diList.map((item, ind) => {
                  return(
                      <li 
                        key={ind} 
                        className={`${(activeLink === ind || (item.text === 'Transaction' && isActiveList)) ? 'active' : ''} ${item.dropdown ? 'h-auto' : ''} m-0 p-3 px-3`}
                        onClick={() =>  handleItemClick(item.component, ind, item.text)}
                      >
                        <div className='list-box d-flex justify-content-between align-items-center m-0 p-0'>
                            <div className='d-flex align-items-center'>
                                {item.icon}
                                <p className='m-0 p-0 pl-3'>{item.text}</p>
                            </div>
                            {item.dropdown ? <MdKeyboardArrowDown className='drop-icon' /> : ''}
                            </div>
                            <ul className='sub-menu p-0'>
                                { 
                                    item.dropdown  && diSubMenuList.map( (subItem, indSub) => {
                                        return(
                                            <li
                                            key={indSub} 
                                            className={`${activeSubLink === indSub ? 'active' : ''} d-flex m-0 px-0 d-flex justify-content-between align-items-center px-3`}
                                            onClick={(e) => { e.stopPropagation(); handleSubItemClick( indSub, subItem.value, subItem.text) }}
                                            >
                                                <p className='m-0 p-0 pl-3'>{subItem.text}</p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                      </li>
                  )
              })
          }
          </ul>
      </div>
    </>
  )
}

export default Sidebar