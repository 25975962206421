import React, {useState} from 'react'
import MoneyTransferTable from './MoneyTransferTable'
import { transactionData } from './TransactionData';


const TransactionHistory = ({isHistory, activeTab, setActiveTab, openFilter, setOpenFilter, handleApply}) => {
  return (
    <div className='dashboard-table'>
        <MoneyTransferTable 
          tableData={transactionData} 
          isHistory={isHistory} 
          activeTab={activeTab} 
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          handleApply={handleApply}
        />
    </div>
  )
}

export default TransactionHistory