import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CiCalendarDate } from 'react-icons/ci';

const DatePickerCustom = ({ 
  name,
  title,
  required = false,
  value,
  onChange,
  onFocus,
  placeholderText,
  Icon,
 }) => {
  const [startDate, setStartDate] = useState(null); 
  const [open, setOpen] = useState(false);

  const toggleDatePicker = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    onChange(date)
    setOpen(false);
  };


  const handleOutsideClick = (event) => {
    if (!event.target.closest('.dob-picker-container')) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="dob-picker-container">
      <label className='dob-picker-label w-100'>
          {title}
          {required && <span className="required-asterisk text-danger">*</span>}
        </label>
      <div className="dob-picker-input-wrapper w-100">
        <DatePicker
          name={name}
          selected={startDate}
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy"
          placeholderText={placeholderText ? placeholderText : "Choose DOB"}
          className="dob-picker-input w-100" 
          onClick={toggleDatePicker}
          value={value}
          onFocus={onFocus}
        />

        {Icon ? (
          <Icon className="dob-picker-icon" onClick={toggleDatePicker} />
        ) : (
          <CiCalendarDate className="dob-picker-icon" onClick={toggleDatePicker} />
        )}
        {/* { Icon ? < Icon className="dob-picker-icon" /> : (<CiCalendarDate className="dob-picker-icon"  onClick={toggleDatePicker} /> ) } */}
        
      </div>
    </div>
  );
};

export default DatePickerCustom;
