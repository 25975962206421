import React from 'react'

const PasswordIcon = ({color}) => {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 7C9.72386 7 9.5 7.22386 9.5 7.5C9.5 7.77614 9.72386 8 10 8H10.01C10.2861 8 10.51 7.77614 10.51 7.5C10.51 7.22386 10.2861 7 10.01 7H10ZM15 7C14.7239 7 14.5 7.22386 14.5 7.5C14.5 7.77614 14.7239 8 15 8H15.01C15.2861 8 15.51 7.77614 15.51 7.5C15.51 7.22386 15.2861 7 15.01 7H15ZM5 7C4.72386 7 4.5 7.22386 4.5 7.5C4.5 7.77614 4.72386 8 5 8H5.01C5.28614 8 5.51 7.77614 5.51 7.5C5.51 7.22386 5.28614 7 5.01 7H5ZM2 0.5H18C18.7301 0.5 19.5 1.28555 19.5 2.5V12.5C19.5 13.7145 18.7301 14.5 18 14.5H2C1.26992 14.5 0.5 13.7145 0.5 12.5V2.5C0.5 1.28555 1.26992 0.5 2 0.5Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default PasswordIcon