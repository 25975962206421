import React, { useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LuWallet2 } from "react-icons/lu";
import { GoBell } from "react-icons/go";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import team4 from "../../assets/img/theme/team-4-800x800.jpg";
import { deleteStorage, getStorage } from "../../helpers/apihelper";
import i18n, { lngs } from "../../i18n/i18n";
import { BsChevronDown } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const AdminNavbar = ({ name }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const adminname = JSON.parse(getStorage("admindetails"))?.username || "";
  const [selectedLang, setSelectedLang] = useState(
    JSON.parse(localStorage.getItem("lang")) || {
      code: "en",
      native: "English",
      flag: "🇬🇧",
    }
  );
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const signOut = async () => {
    await deleteStorage();
    navigate("/admin/signin");
  };

  // Path to title mapping
  const pathToTitle = useMemo(
    () => ({
      "/admin/dashboard": t("dashboard"),
      "/admin/clients/list": t("clientManagement"),
      "/admin/clients/create": t("clientManagement"),
      "/admin/admins/list": t("adminManagement"),
      "/admin/admins/create": t("adminManagement"),
      "/admin/permission-management": t("permissionManagement"),
      "/admin/role-management": t("roleManagement"),
      "/admin/commission/default-management": t("commissionManagement"),
      "/admin/commission/agency-management": t("agencyManagement"),
      "/admin/recharge-management": t("rechargeManagement"),
      "/admin/new-recharge": t("newRecharge"),
      "/admin/geolocation-management": t("geolocationManagement"),
      "/admin/report-management": t("reportManagement"),
      "/admin/transaction-report-management": t("transactionReport"),
    }),
    [t]
  );

  const activeTitle = pathToTitle[location.pathname] || "";

  const handleChange = (lang) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang.code);
    localStorage.setItem("lang", JSON.stringify(lang));
    setIsOpen(false);
  };

  const loadLanguage = () => {
    const savedLang = selectedLang || {
      code: "en",
      native: "English",
      flag: "🇬🇧",
    };
    i18n.changeLanguage(savedLang.code);
  };
  return (
    <>
      <Navbar
        className="navbar-top navbar-light position-sticky"
        id="navbar-main"
      >
        <Container fluid className="p-0">
          <span className="page-title">{activeTitle}</span>
          {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center mr-3">
              <div className="relative inline-block text-left mx-3">
                {/* Dropdown Button */}
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="flex items-center px-4 py-2 border rounded-lg shadow-md bg-white hover:bg-gray-100"
                >
                  <span className="mr-2">{selectedLang.flag}</span>
                  <span>{selectedLang.native}</span>
                  <BsChevronDown className="ml-2 w-4 h-4" />
                </button>

                {/* Dropdown Menu */}
                {isOpen && (
                  <ul className="position-absolute custom_dropdown mt-2 w-44 bg-white border rounded-lg shadow-lg p-0 z-50">
                    {lngs.map((lang) => (
                      <li
                        key={lang.code}
                        className="flex items-center w-full px-4 py-2 hover:bg-gray-100 text-left list-style-none"
                        onClick={() => handleChange(lang)}
                      >
                        <span className="mr-2">{lang.flag}</span>
                        <span>{lang.native}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* <div className="wallet">
                <LuWallet2 />
              </div>
              <div className="notified ml-3">
                <GoBell />
              </div> */}
            </div>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={team4} />
                    </span>
                    <Media className="ml- 2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {adminname}
                      </span>
                      <p className="mb-0 pl-1">Admin</p>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" end>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">{t("hello")}!</h6>
                  </DropdownItem>
                  <DropdownItem onClick={() => navigate("/admin/profile")}>
                    <i className="ni ni-single-02" />
                    <span>{t("profile")}</span>
                  </DropdownItem>
                  <DropdownItem divider />

                  <DropdownItem onClick={signOut}>
                    <i className="ni ni-user-run" />
                    <span>{t("logout")}</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
