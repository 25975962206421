import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  LuLayoutDashboard,
  LuBadgeDollarSign,
  LuUserCircle,
  LuMapPin,
  LuUserPlus,
  LuBuilding,
} from "react-icons/lu";
import { TbAddressBook } from "react-icons/tb";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useClientType } from "../../ContextApi/ClientTypeContext";
import MessageIcon from "../svg/MessageIcon";
import Logo from "../../assets/images/logo.webp";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const { clientType } = useClientType();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(
    (state) => state?.auth?.agency?.user?.data?.data?.user
  );

  const associateRoute = [
    {
      title: t("dashboard"),
      icon: <LuLayoutDashboard />,
      path: "/client/dashboard",
    },
    {
      title: t("add_money"),
      icon: <LuBadgeDollarSign />,
      path: "/client/add-money",
    },
    {
      title: t("profile_management"),
      icon: <LuUserCircle />,
      path: "/client/profile",
    },
    {
      title: t("transaction"),
      icon: <TbAddressBook />,
      path: "/client/transaction",
      child: [
        {
          title: t("money_transfer"),
          path: "/client/money-transfer",
        },
        {
          title: t("search_transaction"),
          path: "/client/transaction-withdrwal",
        },
        {
          title: t("transaction_history"),
          path: "/client/transaction-history",
        },
      ],
    },
    {
      title: t("map_view"),
      icon: <LuMapPin />,
      path: "/client/geolocation",
    },
    {
      title: t("message"),
      icon: <MessageIcon />,
      path: "/client/message",
    },
  ];

  const agencyRoute = [
    {
      title: t("dashboard"),
      icon: <LuLayoutDashboard />,
      path: "/agency/dashboard",
    },
    {
      title: t("add_money"),
      icon: <LuBadgeDollarSign />,
      path: "/agency/add-money",
    },
    {
      title: t("transaction"),
      icon: <TbAddressBook />,
      path: "/agency/transaction",
      child: [
        {
          title: t("money_transfer"),
          path: "/agency/money-transfer",
        },
        {
          title: t("search_transaction"),
          path: "/agency/transaction-withdrwal",
        },
        {
          title: t("transaction_history"),
          path: "/agency/transaction-history",
        },
      ],
    },
    {
      title: t("teller_account"),
      icon: <LuBuilding />,
      path: "/agency/teller",
    },
    {
      title: t("profile_management"),
      icon: <LuUserCircle />,
      path: "/agency/profile",
    },
    {
      title: t("map_view"),
      icon: <LuMapPin />,
      path: "/agency/geolocation",
    },
  ];

  const normalRoute = [
    {
      title: t("dashboard"),
      icon: <LuLayoutDashboard />,
      path: "/client/dashboard",
    },
    {
      title: t("add_money"),
      icon: <LuBadgeDollarSign />,
      path: "/client/add-money",
    },
    {
      title:  t("profile_management"),
      icon: <LuUserCircle />,
      path: "/client/profile",
    },
    {
      title: t("transaction"),
      icon: <TbAddressBook />,
      path: "/client/transaction",
      child: [
        {
          title: t("money_transfer"),
          path: "/client/money-transfer",
        },
        {
          title: t("transaction_history"),
          path: "/client/transaction-history",
        },
      ],
    },
    {
      title: t("map_view"),
      icon: <LuMapPin />,
      path: "/client/geolocation",
    },
    {
      title:t("invite_user"),
      icon: <LuUserPlus />,
      path: "/client/invite",
    },
  ];

  const handleRoute = (link) => {
    navigate(link);
  };

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <>
      <div className="logo d-flex justify-content-center border-bottom">
        <Link to="/client/dashboard" className="p-0 m-0">
          <img
            alt="Dual-Money"
            className="navbar-brand-img mb-2"
            style={{ minHeight: "70px" }}
            height={80}
            width={200}
            src={Logo}
          />
        </Link>
      </div>

      <div className="dashboard-items pt-4">
        <ul className="m-0 p-0">
          {clientType === "normal" &&
            normalRoute.map((items, i) => {
              const hasChildren = items.child && items.child.length > 0;
              const isActive = location.pathname.includes(items.path);
              const isOpen = openDropdown === i;

              return (
                <li
                  key={i}
                  className={`m-0 p-3 px-3 h-auto ${
                    (isActive && "active") || (isOpen && "active")
                  }`}
                  onClick={() =>
                    hasChildren
                      ? handleDropdownToggle(i)
                      : handleRoute(items.path, null)
                  }
                  role="button"
                >
                  <div className="list-box d-flex justify-content-between align-items-center m-0 p-0">
                    <div className="d-flex align-items-center">
                      {items.icon}
                      <p className="m-0 p-0 pl-3">{items.title}</p>
                    </div>
                    {hasChildren &&
                      (isOpen ? (
                        <MdKeyboardArrowUp className="drop-icon" />
                      ) : (
                        <MdKeyboardArrowDown className="drop-icon" />
                      ))}
                  </div>

                  {/* Render child dropdown items below the parent */}
                  {hasChildren && isOpen && (
                    <ul className="sub-menu p-0">
                      {items.child.map((childItem, j) => (
                        <li
                          key={j}
                          // onClick={() => handleRoute(childItem.path)}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRoute(childItem.path);
                          }}
                          className="d-flex m-0 px-0 d-flex justify-content-between align-items-center px-3"
                        >
                          <p className="m-0 p-0 pl-3">{childItem.title}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          {clientType === "associate" &&
            associateRoute.map((items, i) => {
              const hasChildren = items.child && items.child.length > 0;
              const isActive = location.pathname.includes(items.path);
              const isOpen = openDropdown === i;

              return (
                <li
                  key={i}
                  className={`m-0 p-3 px-3 h-auto ${
                    (isActive && "active") || (isOpen && "active")
                  }`}
                  onClick={() =>
                    hasChildren ? handleDropdownToggle(i) : navigate(items.path)
                  }
                  role="button"
                >
                  <div className="list-box d-flex justify-content-between align-items-center m-0 p-0">
                    <div className="d-flex align-items-center">
                      {items.icon}
                      <p className="m-0 p-0 pl-3">{items.title}</p>
                    </div>
                    {hasChildren &&
                      (isOpen ? (
                        <MdKeyboardArrowUp className="drop-icon" />
                      ) : (
                        <MdKeyboardArrowDown className="drop-icon" />
                      ))}
                  </div>

                  {/* Render child dropdown items below the parent */}
                  {hasChildren && isOpen && (
                    <ul className="sub-menu p-0">
                      {items.child.map((childItem, j) => {
                        const isActiveChild = location.pathname.includes(
                          childItem.path
                        );
                        return (
                          <li
                            key={j}
                            onClick={() => handleRoute(childItem.path)}
                            className={`d-flex m-0 px-0 d-flex  ${
                              isActiveChild && "active"
                            } justify-content-between align-items-center px-3`}
                          >
                            <p className="m-0 p-0 pl-3">{childItem.title}</p>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          {clientType === "agency" &&
            agencyRoute.map((items, i) => {
              const hasChildren = items.child && items.child.length > 0;
              const isActive = location.pathname.includes(items.path);
              const isOpen = openDropdown === i;

              return (
                <>
                  {user?.userTypes === "teller" &&
                  items.title === "Teller Account" ? (
                    <></>
                  ) : (
                    <li
                      key={i}
                      className={`m-0 p-3 px-3 h-auto ${
                        (isActive && "active") || (isOpen && "active")
                      }`}
                      onClick={() =>
                        hasChildren
                          ? handleDropdownToggle(i)
                          : navigate(items.path)
                      }
                      role="button"
                    >
                      <div className="list-box d-flex justify-content-between align-items-center m-0 p-0">
                        <div className="d-flex align-items-center">
                          {items.icon}
                          <p className="m-0 p-0 pl-3">{items.title}</p>
                        </div>
                        {hasChildren &&
                          (isOpen ? (
                            <MdKeyboardArrowUp className="drop-icon" />
                          ) : (
                            <MdKeyboardArrowDown className="drop-icon" />
                          ))}
                      </div>

                      {/* Render child dropdown items below the parent */}
                      {hasChildren && isOpen && (
                        <ul className="sub-menu p-0">
                          {items.child.map((childItem, j) => (
                            <li
                              key={j}
                              onClick={() => handleRoute(childItem.path)}
                              className="d-flex m-0 px-0 d-flex justify-content-between align-items-center px-3"
                            >
                              <p className="m-0 p-0 pl-3">{childItem.title}</p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )}
                </>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
