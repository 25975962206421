import React from 'react'

const FileIcons = ({color, className}) => {
  return (
    <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M11.623 1.5V7.875H17.998M13.748 13.1875H5.24805M13.748 17.4375H5.24805M7.37305 8.9375H5.24805M12.1543 1.5H3.12305C2.55946 1.5 2.01896 1.72388 1.62044 2.1224C1.22193 2.52091 0.998047 3.06141 0.998047 3.625V20.625C0.998047 21.1886 1.22193 21.7291 1.62044 22.1276C2.01896 22.5261 2.55946 22.75 3.12305 22.75H15.873C16.4366 22.75 16.9771 22.5261 17.3756 22.1276C17.7742 21.7291 17.998 21.1886 17.998 20.625V7.34375L12.1543 1.5Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default FileIcons