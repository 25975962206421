import React from 'react'
import { FaRegEdit } from "react-icons/fa";


const UpdateBtn = ({children, onClick, className}) => {
  return (
    <div className={`'d-flex justify-content-end pb-0 pr-0' ${className} `} >
        <button className='update d-flex align-items-center' onClick={onClick}>
            <FaRegEdit />
            <p className='mb-0 pl-2'>Update</p>
        </button>
    </div>
  )
}

export default UpdateBtn