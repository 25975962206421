import React, {useState} from 'react'
import { Col, Row } from 'reactstrap'
import InputField from '../../../../components/Buttons/InputField'
import ButtonComponent from '../../../../components/Buttons/ButtonSubmit'
import DropdownCustom from '../../../../components/Buttons/DropdownCustom';
import NumberDropdown from '../../../../components/Buttons/NumberDropdown';
import RadioButton from '../../../../components/Buttons/RadioButton';
import { CiSearch } from 'react-icons/ci';


const UnregisteredForm = ({step, setStep, isOpen, setIsOpen, senderType, selectedOpt, receiverType, receiverOpt, receiverTransfer}) => {
    const [selectedOption, setSelectedOption] = useState('agency');
    const [formData, setFormData] = useState({
      fullname: '',
      currency: '',
      amount: '', 
      reason: '',
      gender: '',
      mobile: '',
      email: '',
      address: '',
      city: '',
      country: '',
      countryCode: '+1',
      zip: '',
      clientType: '',
      idnumber: '',
      idnumberanother: '',
      otp: '',
      search: '',
    })

    const currency = ['INR', 'USD', 'ASD', 'YEN'];
    const gender = ['Male', 'Female', 'Other'];
    const city = ['Delhi', 'USA', 'Mumbai'];
    const country = ['India', 'USA', 'UAE'];

    const handleNumberInput = (code) => {
      setFormData((prevData) => ({
        ...prevData,
        countryCode: code,
      }));
    };
    
    const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
    };

    const handleSelect = (name, value) => {
      setFormData((prevData) => ({
          ...prevData,
          [name]: value,
      }));
    };

    const nextStep = () => {
        setStep(step + 1);
    }

    const prevStep = () => {
        if(step === 0 || step === 1){
            return setStep(1);
        }
        setStep(step - 1);
    }

    const handleSubmit = () => {
        setIsOpen(prevState => !prevState);
    }

    const handleAgent = (event) => {
      const value = event.target.value;
      setSelectedOption(value);
      setFormData(prevData => ({ ...prevData, clientType: value }));
    };

    const handleOTP = () => {
        // handle OTP here..
    }
    
    const renderContent = () => {
        {
            switch(step){
                case 1:
                return(
                    <>
                    {   
                        // If sender type is registered and reciever type is registered 
                        // and money transfer option is Online(for Receiver) or Offline Cash(for reciever)
                        (senderType === 'senderRegistered' || selectedOpt === 'senderRegistered' )
                        && (receiverType === 'recieverRegistered' || receiverOpt === 'recieverRegistered') 
                        && (receiverTransfer === 'Online Transfer' ||  receiverTransfer === 'Offline (in cash)') ? (<>
                            <Col md={12} className='recipient-details px-0 mt-3 mb-3'>
                                <p className='mb-2'>Recipient Details</p>
                            </Col>
                            <Col md={12} className='form-inputs mt-2'>
                                <Row>
                                    <Col md={12}>
                                    <div className='search-group'>
                                        <label htmlFor='search' className='mb-0'>Search<span className='text-danger'>*</span></label>
                                        <div className='d-flex align-items-center'>
                                            <CiSearch />
                                            <input className='p-2' placeholder='Search Sender' type='text' name='search' value={formData.search} onChange={handleFormChange}/>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} className='preview-wrapper-associate px-0'>
                                <div className='section d-flex green-box flex-wrap'>
                                    <div className='item'>
                                        <p className='title mb-0'>Recipient name</p>
                                        <p className='description text mb-0'>Samuel Bennett</p>
                                    </div>
                                    <div className='item'>
                                        <p className='title mb-0'>Gender</p>
                                        <p className='description text mb-0'>Male</p>
                                    </div>
                                    <div className='item'>
                                        <p className='title mb-0'>Phone</p>
                                        <p className='description text mb-0'>+1 702-414-1000</p>
                                    </div>
                                    <div className='item'>
                                        <p className='title mb-0'>Email</p>
                                        <p className='description mb-0'>samuel@gmail.com</p>
                                    </div>
                                    <div className='item'>
                                        <p className='title mb-0'>Address</p>
                                        <p className='description text mb-0'>
                                        145 Lavaca St, Austin, TX 786221, United States
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <div className='d-flex justify-content-end w-100 mt-4'>
                                <Col md={5} className='d-flex'>
                                    <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                        Cancel
                                    </ButtonComponent>
                                    <ButtonComponent className='w-100' onClick={nextStep}>
                                        Next
                                        <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                    </ButtonComponent>
                                </Col>
                            </div>
                        </>) : (<>
                            {/* // If not above then this code  */}
                            <Col md={12} className='recipient-details px-0 mt-3 mb-3'>
                                <p className='mb-2'>Recipient Details</p>
                            </Col>
                            <Col md={12} className='form-inputs mt-2'>
                            <Row>
                                <Col md={6}>
                                <InputField
                                    name="fullname"
                                    type="text"
                                    label="Full Name"
                                    placeholder="Enter name"
                                    required
                                    value={formData.fullname}
                                    onChange={handleFormChange}
                                />
                                </Col>
                                <Col md={6}>
                                <DropdownCustom 
                                    name="gender"
                                    title="Gender"
                                    options={gender}
                                    onSelect={handleSelect}
                                    defaultOption='Choose Gender'
                                    value={formData.gender}
                                />
                                </Col>
                                <Col md={6} className='mt-3'>
                                <NumberDropdown
                                    name="mobile"
                                    title='Mobile Number'
                                    options={['+91', '+92', '+1']}
                                    onSelect={handleNumberInput}
                                    defaultOption={formData.countryCode}
                                    type='number'
                                    placeholder='Enter Mobile Number'
                                    required={true}
                                    onChange={handleFormChange}
                                    value={formData.mobile}
                                />
                                </Col>
                                <Col md={6} className='mt-3'>
                                <InputField
                                    name="email"
                                    type="email"
                                    label="Email"
                                    placeholder="Enter Email Address"
                                    required
                                    value={formData.email}
                                    onChange={handleFormChange}
                                />
                                </Col>
                                <Col md={12} className='mt-3'>
                                <InputField
                                        name="address"
                                        type="text"
                                        label="Address"
                                        placeholder="Enter Address"
                                        required
                                        value={formData.address}
                                        onChange={handleFormChange}
                                    />
                                </Col>
                                <Col md={4} className='mt-4'> 
                                <DropdownCustom 
                                    name="city"
                                    title="City"
                                    options={city}
                                    onSelect={handleSelect}
                                    defaultOption='Select City'
                                    value={formData.city}
                                />
                                </Col>
                                <Col md={4} className='mt-4'> 
                                <DropdownCustom 
                                    name="country"
                                    title="Country"
                                    options={country}
                                    onSelect={handleSelect}
                                    defaultOption='Select Country'
                                    value={formData.country}
                                />
                                </Col>
                                <Col md={4} className='mt-4'>
                                <InputField
                                    name="zip"
                                    type="text"
                                    label="Zip Code"
                                    placeholder="Enter Zip Code"
                                    required
                                    value={formData.zip}
                                    onChange={handleFormChange}
                                />
                                </Col>
                            </Row>
                            </Col>
                            <div className='d-flex justify-content-end w-100 mt-4'>
                                <Col md={5} className='d-flex'>
                                    <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                        Cancel
                                    </ButtonComponent>
                                    <ButtonComponent className='w-100' onClick={nextStep}>
                                        Next
                                        <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                    </ButtonComponent>
                                </Col>
                            </div>
                        </>)
                    }
                    </>
                )
                case 2:
                return(
                    <>
                        {
                            // If sender type is registered and reciever type is un-registered 
                            (senderType === 'senderRegistered' || selectedOpt === 'senderRegistered' )
                            && receiverType === 'recieverUnregistered' ? (
                            <>
                                <Col md={12} className='recipient-details px-0 mt-0 mb-3'>
                                    <p className='mb-2'>Sender Details</p>
                                </Col>
                                <Col md={12} className='form-inputs mt-2'>
                                    <Row>
                                        <Col md={12}>
                                        <div className='search-group'>
                                            <label htmlFor='search' className='mb-0'>Search<span className='text-danger'>*</span></label>
                                            <div className='d-flex align-items-center'>
                                                <CiSearch />
                                                <input className='p-2' placeholder='Search Sender' type='text' name='search' value={formData.search} onChange={handleFormChange}/>
                                            </div>
                                        </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} className='preview-wrapper-associate px-0'>
                                    <div className='section d-flex green-box flex-wrap'>
                                        <div className='item'>
                                            <p className='title mb-0'>Recipient name</p>
                                            <p className='description text mb-0'>Lawrence Parke</p>
                                        </div>
                                        <div className='item'>
                                            <p className='title mb-0'>Gender</p>
                                            <p className='description text mb-0'>Male</p>
                                        </div>
                                        <div className='item'>
                                            <p className='title mb-0'>Phone</p>
                                            <p className='description text mb-0'>+1 702-414-1000</p>
                                        </div>
                                        <div className='item'>
                                            <p className='title mb-0'>Email</p>
                                            <p className='description mb-0'>samuel&gmail.com</p>
                                        </div>
                                        <div className='item'>
                                            <p className='title mb-0'>Address</p>
                                            <p className='description text mb-0'>
                                            145 Lavaca St, Austin, TX 786221, United States
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <div className='d-flex justify-content-end w-100 mt-4'>
                                    <Col md={5} className='d-flex'>
                                        <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                            <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                            Back    
                                        </ButtonComponent>
                                        <ButtonComponent className='w-100' onClick={nextStep}>
                                            Next
                                            <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                        </ButtonComponent>
                                    </Col>
                                </div>
                            </>) : 
                                // If sender type is registered and reciever type is registered 
                                // and money transfer option is Online(for Receiver) or Offline Cash(for reciever)
                                (senderType === 'senderRegistered' || selectedOpt === 'senderRegistered' )
                                && (receiverType === 'recieverRegistered' || receiverOpt === 'recieverRegistered') 
                                && (receiverTransfer === 'Online Transfer' ||  receiverTransfer === 'Offline (in cash)') ? (
                                <>
                                    <Col md={12} className='recipient-details px-0 mt-0 mb-3'>
                                        <p className='mb-2'>Sender Details</p>
                                    </Col>
                                    <Col md={12} className='form-inputs mt-2'>
                                        <Row>
                                            <Col md={12}>
                                            <div className='search-group'>
                                                <label htmlFor='search' className='mb-0'>Search<span className='text-danger'>*</span></label>
                                                <div className='d-flex align-items-center'>
                                                    <CiSearch />
                                                    <input className='p-2' placeholder='Search Sender' type='text' name='search' value={formData.search} onChange={handleFormChange}/>
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} className='preview-wrapper-associate px-0'>
                                        <div className='section d-flex green-box flex-wrap'>
                                            <div className='item'>
                                                <p className='title mb-0'>Sender name</p>
                                                <p className='description text mb-0'>Lawrence Parke</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Gender</p>
                                                <p className='description text mb-0'>Male</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Phone</p>
                                                <p className='description text mb-0'>+1 702-414-1000</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Email</p>
                                                <p className='description mb-0'>parker@gmail.com</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Address</p>
                                                <p className='description text mb-0'>
                                                145 Lavaca St, Austin, TX 786221, United States
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <div className='d-flex justify-content-end w-100 mt-4'>
                                        <Col md={5} className='d-flex'>
                                            <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                                <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                                Back    
                                            </ButtonComponent>
                                            <ButtonComponent className='w-100' onClick={nextStep}>
                                                Next
                                                <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                            </ButtonComponent>
                                        </Col>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* // If not above then this code  */}
                                    <Col md={12} className='recipient-details px-0 mt-3 mb-3'>
                                        <p className='mb-2'>Sender Details</p>
                                    </Col>
                                    <Col md={12} className='form-inputs mt-2'>
                                    <Row>
                                        <Col md={6}>
                                        <InputField
                                            name="fullname"
                                            type="text"
                                            label="Full Name"
                                            placeholder="Enter name"
                                            required
                                            value={formData.fullname}
                                            onChange={handleFormChange}
                                        />
                                        </Col>
                                        <Col md={6}>
                                        <DropdownCustom 
                                            name="gender"
                                            title="Gender"
                                            options={gender}
                                            onSelect={handleSelect}
                                            defaultOption='Choose Gender'
                                            value={formData.gender}
                                        />
                                        </Col>
                                        <Col md={6} className='mt-3'>
                                        <NumberDropdown
                                            name="mobile"
                                            title='Mobile Number'
                                            options={['+91', '+92', '+1']}
                                            onSelect={handleNumberInput}
                                            defaultOption={formData.countryCode}
                                            type='number'
                                            placeholder='Enter Mobile Number'
                                            required={true}
                                            onChange={handleFormChange}
                                            value={formData.mobile}
                                        />
                                        </Col>
                                        <Col md={6} className='mt-3'>
                                        <InputField
                                            name="email"
                                            type="email"
                                            label="Email"
                                            placeholder="Enter Email Address"
                                            required
                                            value={formData.email}
                                            onChange={handleFormChange}
                                        />
                                        </Col>
                                        <Col md={12} className='mt-3'>
                                        <InputField
                                                name="address"
                                                type="text"
                                                label="Address"
                                                placeholder="Enter Address"
                                                required
                                                value={formData.address}
                                                onChange={handleFormChange}
                                            />
                                        </Col>
                                        <Col md={4} className='mt-4'> 
                                        <DropdownCustom 
                                            name="city"
                                            title="City"
                                            options={city}
                                            onSelect={handleSelect}
                                            defaultOption='Select City'
                                            value={formData.city}
                                        />
                                        </Col>
                                        <Col md={4} className='mt-4'> 
                                        <DropdownCustom 
                                            name="country"
                                            title="Country"
                                            options={country}
                                            onSelect={handleSelect}
                                            defaultOption='Select Country'
                                            value={formData.country}
                                        />
                                        </Col>
                                        <Col md={4} className='mt-4'>
                                        <InputField
                                            name="zip"
                                            type="text"
                                            label="Zip Code"
                                            placeholder="Enter Zip Code"
                                            required
                                            value={formData.zip}
                                            onChange={handleFormChange}
                                        />
                                        </Col>
                                    </Row>
                                    </Col>
                                    <Col md={12} className='recipient-details px-0 mt-3 mb-3'>
                                        <p className='mb-2'>Document Verification & OTP (for recipient)</p>
                                    </Col>
                                    <Col md={12} className='form-inputs mt-2'>
                                    <Row>
                                        <Col md={6}>
                                        <InputField
                                            name="idnumber"
                                            type="text"
                                            label="Id number"
                                            placeholder="Enter Id number"
                                            required
                                            value={formData.idnumber}
                                            onChange={handleFormChange}
                                        />
                                        </Col>
                                        <Col md={6}>
                                        <InputField
                                            name="idnumberanother"
                                            type="text"
                                            label="Id number (another)"
                                            placeholder="Enter another Id number"
                                            required
                                            value={formData.idnumberanother}
                                            onChange={handleFormChange}
                                        />
                                        </Col>
                                        <Col md={4} className='mt-3 f-w-270'>
                                        <InputField
                                            name="otp"
                                            type="text"
                                            label="OTP* (sent on recipient mobile)"
                                            placeholder="Enter OTP"
                                            required
                                            value={formData.otp}
                                            onChange={handleFormChange}
                                        />
                                        </Col>
                                        <Col md={2} className='mt-3'>
                                            <button onClick={handleOTP} className='btn-otp'>Resend OTP</button>
                                        </Col>
                                    </Row>
                                    </Col>
                                    <div className='d-flex justify-content-end w-100 mt-4'>
                                        <Col md={5} className='d-flex'>
                                            <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                                <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                                Back    
                                            </ButtonComponent>
                                            <ButtonComponent className='w-100' onClick={nextStep}>
                                                Next
                                                <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                            </ButtonComponent>
                                        </Col>
                                    </div>
                                </>
                            )
                        }
                    </>
                )
                case 3:
                return(
                    <>
                    {
                        // If sender type is registered and reciever type is registered 
                        // and money transfer option is Online(for Receiver) or Offline Cash(for reciever)
                        (senderType === 'senderRegistered' || selectedOpt === 'senderRegistered' )
                        && (receiverType === 'recieverRegistered' || receiverOpt === 'recieverRegistered') 
                        && receiverTransfer === 'Online Transfer' ? (
                            <>
                                <Col md={12} className='recipient-details px-0 mt-0 mb-3'>
                                    <p className='mb-2'>Transaction Details</p>
                                </Col>
                                <Col md={12} className='form-inputs mt-0'>
                                    <Row>
                                        <Col md={6} className='mt-3'> 
                                            <DropdownCustom 
                                                name="currency"
                                                title="Currency"
                                                options={currency}
                                                onSelect={handleSelect}
                                                defaultOption='Select Currency'
                                                value={formData.currency}
                                            />
                                        </Col>
                                        <Col md={6} className='mt-3'>
                                            <InputField
                                                name="amount"
                                                type="text"
                                                label="Amount"
                                                placeholder="Enter Amount"
                                                required
                                                value={formData.amount}
                                                onChange={handleFormChange}
                                            />
                                        </Col>
                                        <Col md={12} className='mt-4'>
                                            <InputField
                                                name="reason"
                                                type="text"
                                                label="Reason for Sending"
                                                placeholder="Please describe your reason"
                                                required
                                                value={formData.reason}
                                                onChange={handleFormChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <div className='d-flex justify-content-end w-100 mt-4'>
                                    <Col md={5} className='d-flex'>
                                        <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                            <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                            Back
                                        </ButtonComponent>
                                        <ButtonComponent className='w-100' onClick={nextStep}>
                                            Next
                                            <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                        </ButtonComponent>
                                    </Col>
                                </div>
                            </>
                        ) : (
                            <>
                                <Col md={12} className='recipient-details px-0 mt-0 mb-3'>
                                    <p className='mb-2'>Agent Details (Cash pickup point)</p>
                                </Col>
                                <Col md={6} className='px-0'>
                                    <div className='radio-btn-wrap'>
                                        <p className='mb-2 font-small'>Agent type<span className='text-danger'>*</span></p>
                                        <div className='mb-0 d-flex align-items-center flex-wrap mt-3'>
                                            <div className='mr-2 mr-10 mb-2'>
                                                <RadioButton
                                                    id="agency"
                                                    name="clientType"
                                                    checked={selectedOption === 'agency'}
                                                    onChange={handleAgent}
                                                    label="Agency"
                                                    value="agency"
                                                />
                                            </div>
                                            <div className='mr-10 mb-2'>
                                                <RadioButton
                                                    id="associate"
                                                    name="clientType"
                                                    checked={selectedOption === 'associate'}
                                                    onChange={handleAgent}
                                                    label="Associate Client"
                                                    value="associate"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12} className='form-inputs mt-2'>
                                    <Row>
                                        <Col md={4} className='mt-4'> 
                                            <DropdownCustom 
                                            name="city"
                                            title="City"
                                            options={city}
                                            onSelect={handleSelect}
                                            defaultOption='Select City'
                                            value={formData.city}
                                            />
                                        </Col>
                                        <Col md={4} className='mt-4'> 
                                            <DropdownCustom 
                                            name="country"
                                            title="Country"
                                            options={country}
                                            onSelect={handleSelect}
                                            defaultOption='Select Country'
                                            value={formData.country}
                                            />
                                        </Col>
                                        <Col md={4} className='mt-4'>
                                            <InputField
                                                name="zip"
                                                type="text"
                                                label="Zip Code"
                                                placeholder="Enter Zip Code"
                                                required
                                                value={formData.zip}
                                                onChange={handleFormChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <div className='d-flex justify-content-end w-100 mt-4'>
                                    <Col md={5} className='d-flex'>
                                        <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                            <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                            Back   
                                        </ButtonComponent>
                                        <ButtonComponent className='w-100' onClick={nextStep}>
                                            Next
                                            <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                        </ButtonComponent>
                                    </Col>
                                </div>
                            </>
                        )
                    }
                    </>
                )
                case 4:
                return(
                    <>
                    {
                        // If sender type is registered and reciever type is registered 
                        // and money transfer option is Online(for Receiver) or Offline Cash(for reciever)
                        (senderType === 'senderRegistered' || selectedOpt === 'senderRegistered' )
                        && (receiverType === 'recieverRegistered' || receiverOpt === 'recieverRegistered') 
                        && receiverTransfer === 'Online Transfer' ? (
                            <>
                                <Col md={12} className='form-inputs  associate-preview mt-0'>
                                    <div className='d-block'>
                                        <p className='title-top'>Preview (Sender Details)</p>
                                        <div className='section d-flex pt-2 flex-wrap'>
                                            <div className='item detail'>
                                                <p className='title mb-0'>Sender type</p>
                                                <p className='description mb-0 colored'>Registered</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Sender name</p>
                                                <p className='description text mb-0'>Lawrence Parke</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Gender</p>
                                                <p className='description text mb-0'>Male</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Phone</p>
                                                <p className='description text mb-0'>+1 702-414-1000</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Email</p>
                                                <p className='mail-id mb-0'>lawrence&gmail.com</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Reason for sending</p>
                                                <p className='description text mb-0'>
                                                    400 Lavaca St, Austin, TX 78701, United States
                                                </p>
                                            </div>
                                        </div>

                                        <p className='title-top mt-4'>Preview (Recipient Details)</p>
                                        <div className='section d-flex pt-2 flex-wrap'>
                                            <div className='item'>
                                                <p className='title mb-0'>Recipient type</p>
                                                <p className='description mb-0 colored'>Registered</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Recipient name</p>
                                                <p className='description text mb-0'>Samuel Bennett</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Gender</p>
                                                <p className='description text mb-0'>Male</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Phone</p>
                                                <p className='description text mb-0'>+1 702-414-1000</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Email</p>
                                                <p className='mail-id mb-0'>samuel&gmail.com</p>
                                            </div>
                                            <div className='item'>
                                                <p className='title mb-0'>Reason for sending</p>
                                                <p className='description text mb-0'>
                                                    400 Lavaca St, Austin, TX 78701, United States
                                                </p>
                                            </div>
                                        </div>

                                        <p className='title-top mb-0 mt-4'>Money Details</p>
                                        <div className='section d-flex  pt-2 flex-wrap'>
                                        <div className='item'>
                                            <p className='title mb-0'>Amount</p>
                                            <p className='description text mb-0'>$100.00</p>
                                        </div>
                                        <div className='item'>
                                            <p className='title mb-0'>Reason for Sending</p>
                                            <p className='description text mb-0'>Reimbursement for travel expenses incurred during the conference.</p>
                                        </div>
                                        </div>
                                    </div>
                                </Col>
                                <div className='d-flex justify-content-end w-100 mt-5'>
                                    <Col md={5} className='d-flex'>
                                        <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                            <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                            Back
                                        </ButtonComponent>
                                        <ButtonComponent className='w-100' onClick={handleSubmit}>
                                            Send Money
                                            <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                        </ButtonComponent>
                                    </Col>
                                </div>
                            </>
                        ) : (
                            <>
                                <Col md={12} className='recipient-details px-0 mt-0 mb-3'>
                                    <p className='mb-2'>Transaction Details</p>
                                </Col>
                                <Col md={12} className='form-inputs mt-0'>
                                    <Row>
                                        <Col md={6} className='mt-3'> 
                                            <DropdownCustom 
                                                name="currency"
                                                title="Currency"
                                                options={currency}
                                                onSelect={handleSelect}
                                                defaultOption='Select Currency'
                                                value={formData.currency}
                                            />
                                        </Col>
                                        <Col md={6} className='mt-3'>
                                            <InputField
                                                name="amount"
                                                type="text"
                                                label="Amount"
                                                placeholder="Enter Amount"
                                                required
                                                value={formData.amount}
                                                onChange={handleFormChange}
                                            />
                                        </Col>
                                        <Col md={12} className='mt-4'>
                                            <InputField
                                                name="reason"
                                                type="text"
                                                label="Reason for Sending"
                                                placeholder="Please describe your reason"
                                                required
                                                value={formData.reason}
                                                onChange={handleFormChange}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <div className='d-flex justify-content-end w-100 mt-4'>
                                    <Col md={5} className='d-flex'>
                                        <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                            <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                            Back
                                        </ButtonComponent>
                                        <ButtonComponent className='w-100' onClick={nextStep}>
                                            Next
                                            <i className="ml-3 fa fa-regular fa-angle-right"></i>
                                        </ButtonComponent>
                                    </Col>
                                </div>
                            </>
                        )
                    }
                    </>
                )
                case 5:
                return(
                  <>
                    <Col md={12} className='form-inputs  associate-preview mt-0'>
                        <div className='d-block'>
                            <p className='title-top'>Preview (Sender Details)</p>
                            <div className='section d-flex pt-2 flex-wrap'>
                                <div className='item detail'>
                                    <p className='title mb-0'>Sender type</p>
                                    <p className='description mb-0 colored'>Unregistered</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Recipient name</p>
                                    <p className='description text mb-0'>Lawrence Parke</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Gender</p>
                                    <p className='description text mb-0'>Male</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Phone</p>
                                    <p className='description text mb-0'>+1 702-414-1000</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Email</p>
                                    <p className='mail-id mb-0'>lawrence&gmail.com</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Reason for sending</p>
                                    <p className='description text mb-0'>
                                        400 Lavaca St, Austin, TX 78701, United States
                                    </p>
                                </div>
                            </div>

                            <p className='title-top mt-4'>Preview (Recipient Details)</p>
                            <div className='section d-flex pt-2 flex-wrap'>
                                <div className='item'>
                                    <p className='title mb-0'>Recipient type</p>
                                    <p className='description mb-0 colored'>Unregistered</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Recipient name</p>
                                    <p className='description text mb-0'>Samuel Bennett</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Gender</p>
                                    <p className='description text mb-0'>Male</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Phone</p>
                                    <p className='description text mb-0'>+1 702-414-1000</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Email</p>
                                    <p className='mail-id mb-0'>samuel&gmail.com</p>
                                </div>
                                <div className='item'>
                                    <p className='title mb-0'>Reason for sending</p>
                                    <p className='description text mb-0'>
                                        400 Lavaca St, Austin, TX 78701, United States
                                    </p>
                                </div>
                            </div>

                            <p className='title-top mb-0 mt-4'>Cash Pickup Details</p>
                            <div className='section d-flex  pt-2 flex-wrap'>
                              <div className='item'>
                                  <p className='title mb-0'>Agent city</p>
                                  <p className='description text mb-0'>Austin</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Agent country</p>
                                <p className='description text mb-0'>United States</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Zip Code</p>
                                <p className='description text mb-0'>TX 78701</p>
                              </div>
                            </div>

                            <p className='title-top mb-0 mt-4'>Money Details</p>
                            <div className='section d-flex  pt-2 flex-wrap'>
                              <div className='item'>
                                  <p className='title mb-0'>Amount</p>
                                  <p className='description text mb-0'>$100.00</p>
                              </div>
                              <div className='item'>
                                <p className='title mb-0'>Reason for Sending</p>
                                <p className='description text mb-0'>Reimbursement for travel expenses incurred during the conference.</p>
                              </div>
                            </div>
                        </div>
                    </Col>
                    <div className='d-flex justify-content-end w-100 mt-5'>
                        <Col md={5} className='d-flex'>
                            <ButtonComponent className='w-100' onClick={prevStep} btnType='bordered'>
                                <i className="mr-3 fa fa-regular fa-angle-left"></i>
                                Back
                            </ButtonComponent>
                            <ButtonComponent className='w-100' onClick={handleSubmit}>
                                Send Money
                                <i className="ml-3 fa fa-regular fa-angle-right"></i>
                            </ButtonComponent>
                        </Col>
                    </div>
                  </>
                )
                default:
                return null
            }
        }
    }


    return (
    <div className='w-100 registered-user unregistered-form-preview register-form-mt'>
        {renderContent()}
    </div>
    )
}

export default UnregisteredForm