import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        {/* <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{" "}
            <a
              className="font-weight-bold ml-1"
              href="/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Dual Money
            </a>
          </div>
        </Col> */}
        <Col md={12} className="">
          <p className="copyright-footer d-flex align-items-center">
            Copyright © {new Date().getFullYear()}
            <Link
              className="font-weight-bold ml-2"
              to="/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Dualmoney.com
            </Link>
            . All Rights Reserved |
            <Link to="/" className="ml-2 mr-2">
              Terms and Condition
            </Link>{" "}
            |
            <Link to="/" className="ml-2 mr-2">
              Privacy Policy
            </Link>{" "}
            |
            <Link to="/" className="ml-2 mr-2">
              About Us
            </Link>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
