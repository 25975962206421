/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import axiosInstanceClient from "../../api/client";
import { baseURL } from "../../config/filepath";
import axiosInstance from "../../api/index";

const Clientlogin = (payload) => {
  return axios.post(baseURL + "client/loginUser", payload).then((response) => {
    return response;
  });
};

export const ClientRegister = (payload) => {
  return axios
    .post(baseURL + "client/registerUser", payload)
    .then((response) => {
      return response;
    });
};

const ClientProfileUpdate = (file) => {
  const formData = new FormData();
  formData.append("file", file);

  return axiosInstanceClient
    .post(baseURL + "file-upload/profile-upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Set the Content-Type to multipart/form-data
      },
    })
    .then((response) => {
      return response;
    });
};

export const FileUploads = (file, type) => {
  if (type === "digital-signature") {
    return axios
      .post(baseURL + `file-upload/${type}`, { imageBase64: file })
      .then((response) => {
        return response;
      });
  } else {
    const formData = new FormData();
    formData.append("file", file);

    return axios
      .post(baseURL + `file-upload/${type}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response;
      });
  }
};

export const getCountry = () => {
  return axios.get(baseURL + `country-code`).then((response) => {
    return response;
  });
};
export const getStateByCountry = (countryId) => {
  return axios
    .get(baseURL + `state-by-country/${countryId}`)
    .then((response) => {
      return response;
    });
};
export const getCityByState = (stateId) => {
  return axios.get(baseURL + `city-by-state/${stateId}`).then((response) => {
    return response;
  });
};

const AgencyLogin = (payload) => {
  return axios.post(baseURL + "agency/loginUser", payload).then((response) => {
    return response;
  });
};

const payAbleAmount = (payload) => {
  return axiosInstance
    .post(baseURL + "get-payable-amount", payload)
    .then((response) => {
      return response;
    });
};

const logOut = (payload) => {
  return axiosInstance
    .post(baseURL + "client/logout", payload)
    .then((response) => {
      return response;
    });
};

const getCurrencyList = (payload) => {
  return axiosInstance
    .get(baseURL + "get-currency", payload)
    .then((response) => {
      return response;
    });
};

export default {
  Clientlogin,
  AgencyLogin,
  ClientProfileUpdate,
  payAbleAmount,
  logOut,
  getCurrencyList,
};
