import React, {useState} from 'react'
import { Col, Container, Row } from 'reactstrap'
import DashboardCard from './DashboardCard';
import LineGraph from './LineGraph';
import DropdownCustom from '../../components/Buttons/DropdownCustom';
import DashboardTable from './DashboardTable';
import Userbar from './Userbar';
import { useClientType } from '../../ContextApi/ClientTypeContext';
import TellerAccount from './TellerAccount/TellerAccount';

const DashboardHome = () => {
    const {clientType} = useClientType();
    const [optionData, setOptionData] = useState();
    const options = ['past 12 months', 'past 6 months', 'past 1 months'];

    const handleSelect = (e) => {
        setOptionData(e.target.value)
    };

  return (
    <Container className='pt-3 pb-3'>
        <Row>
            <Col md={12} className='px-0'>
                <Userbar />
            </Col>
            <Col md={4} className='pt-4 px-0' >
                <DashboardCard
                    className='shade-green mb-4 p-3 px-4'
                    title='My Wallet'
                    content='$120.00'
                />
                <DashboardCard
                    className='shade-blue p-3 px-4'
                    title='Fund Transferred'
                    content='$250.00'
                />
            </Col>
            <Col md={8} className='pt-4 px-3 chart-graph'>
                <div className='chart-inner pl-3 pr-0'>
                    <div className='wrap d-flex justify-content-between px-4 pb-3'>
                        <div className='left'>
                            <p className='title mb-0'>My Total Recharge</p>
                            <p className='ammount mb-0'>$800.00</p>
                        </div>
                        <div className='right'>
                            <DropdownCustom 
                                className='dashboard-drop'
                                name="datetime"
                                title=""
                                isRequired = {false}
                                options={options}
                                onSelect={handleSelect}
                                defaultOption='Past 12 months'
                                value={optionData}
                            />
                        </div>
                    </div>
                    <LineGraph />
                </div>
            </Col>
            <Col md={12}>
                <DashboardTable />
                {
                    clientType === 'option3' && <TellerAccount />
                }
            </Col>
        </Row>
    </Container>
  )
}

export default DashboardHome