import React, { useMemo } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { Col, Container, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import TopBar from "../features/client/Dashboard/TopBar";
import { useTranslation } from "react-i18next";
const ClientLayout = (props) => {
  const location = useLocation();
  const { t } = useTranslation();

  const pathToTitle = useMemo(
    () => ({
      "/client/dashboard": t("dashboard"),
      "/client/add-money": t("add_money"),
      "/client/profile": t("profile_management"),
      "/client/money-transfer": t("money_transfer"),
      "/client/transaction-history": t("transaction_history"),
      "/client/geolocation": t("geo_location"),
      "/client/message": t("message_chat"),
      "/client/transaction-withdrwal": t("search_transaction"),
      "/client/transaction-details/:id": t("transaction_details"),
      "/client/invite": t("invitation_management"),

      "/agency/dashboard": t("dashboard"),
      "/agency/add-money": t("add_money"),
      "/agency/profile": t("profile_management"),
      "/agency/money-transfer": t("money_transfer"),
      "/agency/transaction-history": t("transaction_history"),
      "/agency/teller": t("tellerAccounts"),
      "/agency/geolocation": t("geo_location"),
      "/agency/message": t("message_chat"),
      "/agency/transaction-withdrwal": t("search_transaction"),
      "/agency/transaction-details/:id": t("transaction_details"),
    }),
    [t] // Dependency array ensures memoization updates when `t` changes
  );

  const activeTitle = pathToTitle[location.pathname] || "";

  return (
    <Container fluid className="normal-dashboard">
      <Row>
        <Col sm={12} className="dashboard-sidebar px-0">
          <Sidebar />
        </Col>
        <Col sm={12} className="dashboard-main-content">
          <Row className="px-0 mx-0 p-0 m-0">
            <Col md={12} className="px-0">
              <TopBar title={activeTitle} />
            </Col>
            <Col md={12} className="px-0">
              {props.children}
            </Col>
            <Col md={12} className="px-0">
              <p className="copyright-footer d-flex align-items-center">
                {t("copyright")} © {new Date().getFullYear()}{" "}
                {t("allRightsReserved")} |
                <Link to="">{t("termsAndConditions")}</Link> |
                <Link to="">{t("privacyPolicy")}</Link>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ClientLayout;
