import React, {useEffect, useState} from 'react'
import { LuLayoutDashboard, LuBadgeDollarSign, } from "react-icons/lu";
import { TbAddressBook } from "react-icons/tb";
import Accordion from './Accordion';
import Password from './Password';
import { MdKeyboardArrowDown } from 'react-icons/md';
import AccountType from './AccountType';
import BookIcon from '../../../components/svg/BookIcon';
import AccountTypeIcon from '../../../components/svg/AccountTypeIcon';
import PasswordIcon from '../../../components/svg/PasswordIcon';
import { useClientType } from '../../../ContextApi/ClientTypeContext';
import AvailabilityClockIcon from '../../../components/svg/AvailabilityClockIcon';
import MyAvailability from './MyAvailability';

const profileSBList = [
    {Icon: BookIcon, text: 'General Information', dropdown: false, component: <Accordion />},
    {Icon: PasswordIcon, text: 'Password', dropdown: false, component: <Password /> },
    {Icon: AccountTypeIcon, text: 'Account Type', dropdown: false, component: <AccountType />},
]

const SidebarProfile = ({ onComponentChange }) => {
    const [activeLink, setActiveLink] = useState(0);
    const { clientType } = useClientType();
    const [list, setList] = useState(profileSBList);
    
    const handleItemClick = (component, index) => {
        onComponentChange(component);
        setActiveLink(index);
    };

    useEffect( () => {
      const updatedList = [...list];
      if (clientType === 'option2' || clientType === 'option3') {
        updatedList.push({ Icon: AvailabilityClockIcon, text: 'My Availability', dropdown: false, component: <MyAvailability />},);
      }
      setList(updatedList)
    },[clientType])

  return (
    <>
      <div className='sidebar-items'>
          <ul className='m-0 p-0'>
          {
              list.map((item, ind) => {
                const {Icon,text, component } = item;
                  return(
                      <li 
                        key={ind} 
                        className={`${activeLink === ind ? 'active' : ''} d-flex m-0 d-flex justify-content-between align-items-center`}
                        onClick={() => handleItemClick(component, ind)}
                      >
                          <div className='d-flex align-items-center'>
                              <Icon color={activeLink === ind ? '#393939' : '#CC9752'} />
                              <p className='m-0 p-0 pl-3'>{text}</p>
                          </div>
                      </li>
                  )
              })
          }
          </ul>
      </div>
    </>
  )
}

export default SidebarProfile