
import React, { Suspense } from 'react'
import { getStorage } from "../helpers/apihelper";
import { Navigate, Outlet} from 'react-router-dom'
import Loader from '../components/Loader/Loader';
const PublicRoute = ({ component: RouteComponent }) => {
  const token = getStorage("token");
  // if (token === null) {
  //   return <RouteComponent />
  // }
  // else {
  //   return <Navigate to="admin/dashboard" />

  // }

  return token
    ? <Navigate to="/admin/dashboard" replace />
    : ( 
      <Suspense fallback = {
          <div className="loader-container">
              <div className="loader-container-inner">
                  <div className="text-center">
                      <Loader type="ball-pulse-rise"/>
                  </div>
              </div>
          </div>
      }>
        <Outlet /> 
      </Suspense>
    );
}

export default PublicRoute
