export const userData = [
    {id: 1, userImage: '../assets/user-pic-blue.png', userName: 'Lawrence Parker', userMessage: 'Sound great !', messageDate: '10/07/24'},
    {id: 2, userImage: '../assets/user-pic-invite.png', userName: 'Swift Transfer', userMessage: 'Sure, where and when?', messageDate: '10/07/24'},
    {id: 3, userImage: '../assets/user-pic-invite.png', userName: 'Miles Taylor', userMessage: 'Hey! How\'s it going?', messageDate: '10/07/24'},
    {id: 4, userImage: '../assets/user-pic-invite.png', userName: 'Lawrence Parker', userMessage: 'Sound great !', messageDate: '10/07/24'},
    {id: 5, userImage: '../assets/user-pic-invite.png', userName: 'Thomas Albert', userMessage: 'Sure, where and when?', messageDate: '10/07/24'},
    {id: 6, userImage: '../assets/user-pic-invite.png', userName: 'Christina Alison', userMessage: 'Sure, where and when?', messageDate: '10/07/24'},
    {id: 6, userImage: '../assets/user-pic-invite.png', userName: 'Lawrence Parker', userMessage: 'Sure, where and when?', messageDate: '10/07/24'},
    {id: 6, userImage: '../assets/user-pic-invite.png', userName: 'Thomas Albert', userMessage: 'Sure, where and when?', messageDate: '10/07/24'},
    {id: 3, userImage: '../assets/user-pic-invite.png', userName: 'Miles Taylor', userMessage: 'Hey! How\'s it going?', messageDate: '10/07/24'},
]