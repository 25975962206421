import React, { Suspense } from "react";
import { getStorage } from "../helpers/apihelper";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "../components/Loader/Loader";
const PublicRoute = ({ component: RouteComponent }) => {
  const token = getStorage("token");
  return token ? (
    <Navigate to="/" replace />
  ) : (
    <Suspense
      fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="ball-pulse-rise" />
            </div>
          </div>
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
};

export default PublicRoute;
