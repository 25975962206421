import React, { useState } from 'react'
import { Col } from 'reactstrap'
import ButtonComponent from '../../../components/Buttons/ButtonSubmit'
import { IoArrowBackOutline } from "react-icons/io5";
import ButtonStatus from '../ButtonStatus';
import { LuDownload  } from "react-icons/lu";
import Popup from '../../../components/Popup/Popup';
import CheckBox from '../../../components/Buttons/CheckBox';
import { IoCloseOutline } from 'react-icons/io5';
import InputField from '../../../components/Buttons/InputField';
import NextArrow from '../../../components/svg/NextArrow';


const TransactionHistoryDetails = ({setTDetails, handlePopUp, openPopup, handlePopupSubmit, Successful, setSuccessful}) => {
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        idnumber: '',
        securitycode: '',
        otp: '',
    })

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    }

  return (
    <div className='tDetails'>
        <div className='top-bar d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
                <IoArrowBackOutline className='back' onClick={() => setTDetails(prev => !prev) } /> 
                <p className='mb-0 title'>Transaction Details</p>
            </div>
            <div className='d-flex align-items-center'>
                <ButtonStatus
                    img='../assets/circle-olive.svg'
                    content='Pending'
                    className=''
                    status='Pending'
                />
                <LuDownload className='download'  />
            </div>
        </div>
        <Col md={12} className='form-inputs m-0'>
            <div className='d-block tDetails-preview'>
                <div className='section d-flex pt-4 flex-wrap'>
                    <div className='item detail'>
                        <p className='title mb-0'>Id</p>
                        <p className='description mb-0 colored'>2245786</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Date</p>
                        <p className='description text mb-0'>20-07-24</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Agency Name</p>
                        <p className='description text mb-0'>Pinnacle Partners</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Phone</p>
                        <p className='description text mb-0'>+1 702-414-1000</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Email</p>
                        <p className='mail-id mb-0'>agency@gmail.com</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Address</p>
                        <p className='description text mb-0'>
                            145 Lavaca St, Austin, TX 786221, United States
                        </p>
                    </div>
                </div>

                <p className='title-top mt-0'>Sender details</p>
                <div className='section d-flex pt-0 flex-wrap'>
                    <div className='item w-100'>
                        <p className='title mb-0'>Sender type</p>
                        <p className='description mb-0 colored'>Unregistered</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Recipient name</p>
                        <p className='description mb-0'>Lawrence Parke</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Gender</p>
                        <p className='description text mb-0'>Male</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Phone</p>
                        <p className='description text mb-0'>+1 702-414-1000</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Email</p>
                        <p className='mail-id mb-0'>parker@gmail.com</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Address</p>
                        <p className='description text mb-0'>
                            145 Lavaca St, Austin, TX 786221, United States
                        </p>
                    </div>
                </div>

                <p className='title-top mt-0'>Recipient details</p>

                <div className='section d-flex pt-0 flex-wrap'>
                    <div className='item w-100'>
                        <p className='title mb-0'>Recipient type</p>
                        <p className='description mb-0 colored'>Unregistered</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Recipient name</p>
                        <p className='description mb-0'>Samuel Bennett</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Gender</p>
                        <p className='description text mb-0'>Male</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Phone</p>
                        <p className='description text mb-0'>+1 702-414-1000</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Email</p>
                        <p className='mail-id mb-0'>samuel@gmail.com</p>
                    </div>
                    <div className='item'>
                        <p className='title mb-0'>Address</p>
                        <p className='description text mb-0'>
                            400 Lavaca St, Austin, TX 78701, United States
                        </p>
                    </div>
                </div>

                <p className='title-top mt-0'>Money Details</p>
                <div className='section d-flex  pt-0 flex-wrap'>
                    <div className='item'>
                        <p className='title mb-0'>Amount</p>
                        <p className='description text mb-0'>$100.00</p>
                    </div>
                    <div className='item'>
                    <p className='title mb-0'>Reason for Sending</p>
                    <p className='description text mb-0'>Reimbursement for travel expenses incurred during the conference.</p>
                    </div>
                </div>
            </div>
        </Col>
        <div className='d-flex justify-content-between w-100 mt-0 tDetails-btn-group'>
            <ButtonComponent className='' onClick={() => setTDetails(prev => !prev) } btnType='bordered'>
                <i className="mr-3 fa fa-regular fa-angle-left"></i>
                Back
            </ButtonComponent>
            <ButtonComponent className='' onClick={handlePopUp} >
                Pay Recepient
                <i className="ml-3 fa fa-regular fa-angle-right"></i>
            </ButtonComponent>
        </div>
        {
            openPopup && 
                <div className='mx-0 tDetails-popup popup-container d-flex justify-content-center align-items-center px-3 px-md-0'>
                    <div className='popupWrapper'>
                        <Popup onClick={handlePopUp} className='justify-content-start'>
                            <div className='close'>
                                <IoCloseOutline onClick={handlePopUp} />
                            </div>
                            <p className='titles p-3 mb-0'>Verify User & Pay Now</p>
                            <div className='form-inputs px-3 pt-2 pb-3'>
                                <div className='mt-2'>
                                    <InputField
                                        name="idnumber"
                                        type="text"
                                        label="Id number"
                                        placeholder="Enter any Id number"
                                        required={true}
                                        value={formData.idnumber}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                </div>
                                <div className='mt-3'>
                                    <InputField
                                        name="securitycode"
                                        type="text"
                                        label="Security code"
                                        placeholder="Enter id"
                                        required={true}
                                        value={formData.securitycode}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                </div>
                                <div className='mt-3'>
                                    <InputField
                                        name="otp"
                                        type="text"
                                        label="OTP(sent on recipient mobile)"
                                        placeholder="Enter OTP"
                                        required={true}
                                        value={formData.otp}
                                        onChange={(e) => setFormData(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='checkbox position-relative mt-0 px-3'>
                                <CheckBox
                                    className="custom-check"
                                    id="terms-checkbox"
                                    label='I agree to the Terms and condition'
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </div>
                            <div className='d-flex justify-content-between pop-btn-wrap p-3 mt-5'>
                                <ButtonComponent className='' onClick={handlePopUp} btnType='bordered'>
                                    Cancel
                                </ButtonComponent>
                                <ButtonComponent className='' onClick={handlePopupSubmit} >
                                    Verify & Pay Now
                                </ButtonComponent>
                            </div>
                        </Popup>
                    </div>
                </div>
        }
        {
            Successful && 
                <div className='row mx-0 successful popup-container d-flex justify-content-center align-items-center px-3 px-md-0'>
                    <Col md={12} className='popupWrapper'>
                        <Popup onClick={() => setSuccessful(prev => !prev)} className='justify-content-start mt-popup'>
                            <div className='close'>
                                <IoCloseOutline onClick={() => setSuccessful(prev => !prev)} />
                            </div>
                            <div className='icon d-flex justify-content-center align-items-center p-2'>
                                <img src='../assets/check-mark-second.png' alt='' />
                            </div>
                            <h2 className='mt-4'>Payment Successful !</h2>
                            <p className='light-text'>Thank you for your payment. An automated receipt will be sent to your registered email.</p>
                            <div className='d-flex justify-content-center btn-wrap'>
                                <ButtonComponent className='mt-2 btn-mt' onClick={setTDetails} >
                                    Continue
                                    <NextArrow color='#fff' className='ml-2' />
                                </ButtonComponent>
                            </div>
                        </Popup>
                    </Col>
                </div>
        }
    </div>
  )
}

export default TransactionHistoryDetails