import React from 'react'

const MyAccountIcon = ({color, className}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M2.48829 6.29054C2.37156 5.76473 2.38948 5.21795 2.5404 4.70091C2.69132 4.18386 2.97034 3.71329 3.3516 3.33283C3.73286 2.95237 4.20401 2.67433 4.72137 2.5245C5.23873 2.37466 5.78554 2.35789 6.31111 2.47572C6.60039 2.0233 6.99891 1.65098 7.46992 1.39308C7.94093 1.13518 8.46929 1 9.00629 1C9.54328 1 10.0716 1.13518 10.5427 1.39308C11.0137 1.65098 11.4122 2.0233 11.7015 2.47572C12.2278 2.35737 12.7756 2.37408 13.2938 2.52427C13.8119 2.67447 14.2837 2.95328 14.6652 3.33478C15.0467 3.71627 15.3255 4.18805 15.4757 4.70623C15.6259 5.22442 15.6426 5.77217 15.5243 6.29854C15.9767 6.58782 16.349 6.98633 16.6069 7.45735C16.8648 7.92836 17 8.45672 17 8.99371C17 9.53071 16.8648 10.0591 16.6069 10.5301C16.349 11.0011 15.9767 11.3996 15.5243 11.6889C15.6421 12.2145 15.6253 12.7613 15.4755 13.2786C15.3257 13.796 15.0476 14.2671 14.6672 14.6484C14.2867 15.0297 13.8161 15.3087 13.2991 15.4596C12.7821 15.6105 12.2353 15.6284 11.7095 15.5117C11.4206 15.9659 11.0217 16.3398 10.5499 16.5988C10.0781 16.8579 9.54854 16.9937 9.01028 16.9937C8.47203 16.9937 7.94247 16.8579 7.47066 16.5988C6.99884 16.3398 6.60002 15.9659 6.31111 15.5117C5.78554 15.6295 5.23873 15.6128 4.72137 15.4629C4.20401 15.3131 3.73286 15.0351 3.3516 14.6546C2.97034 14.2741 2.69132 13.8036 2.5404 13.2865C2.38948 12.7695 2.37156 12.2227 2.48829 11.6969C2.0324 11.4084 1.65688 11.0092 1.39666 10.5366C1.13645 10.064 1 9.53323 1 8.99371C1 8.45419 1.13645 7.92344 1.39666 7.45082C1.65688 6.97819 2.0324 6.57906 2.48829 6.29054Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.9154 12.75V11.9167C11.9154 11.4746 11.7398 11.0507 11.4272 10.7382C11.1146 10.4256 10.6907 10.25 10.2487 10.25H7.7487C7.30667 10.25 6.88275 10.4256 6.57019 10.7382C6.25763 11.0507 6.08203 11.4746 6.08203 11.9167V12.75M10.6654 6.91667C10.6654 7.83714 9.91917 8.58333 8.9987 8.58333C8.07822 8.58333 7.33203 7.83714 7.33203 6.91667C7.33203 5.99619 8.07822 5.25 8.9987 5.25C9.91917 5.25 10.6654 5.99619 10.6654 6.91667Z" stroke={color} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default MyAccountIcon